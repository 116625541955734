import React, { useEffect, useState } from 'react'
import { ObjectValues } from '../../login';
import apiRequest from '../../../request/apiRequest/apiRequest';
import { allApis } from '../../../request/config';
import { showSnack } from '../../../store/slices/constants';
import CustomDialog from '../../../Components/CustomDialog';
import { Box, Divider, Stack } from '@mui/material';
import { subHeading } from '../../../Components/CustomTexts';
import CustomTextField from '../../../Components/CustomTextfield';
import { useAppDispatch } from '../../../hooks/storeHooks';
import { fetchTagsData } from '../../../store/slices/exams/thunks/fetchContentData';

function QuestionTag({value}:any) {  
    const [newTag,setNewTag]=useState<ObjectValues>({tag:"",loading:false})
    const dispatch = useAppDispatch();
const addQuestionTag=async()=>{
  if(newTag?.tag){
  const response: any = await apiRequest({
    api:allApis.questionTags.create,
     payload:{name:newTag.tag??""
          },
    method:"POST",
  });
  if (!response || (response && response.error)) {}else{
    await dispatch(
      showSnack({
        message: "New tag created!",
        severity: "success",
        open: true,
      })
    );
    await  dispatch(fetchTagsData()); 
    await setNewTag({})
  }}
  // dispatch(fetchTagsData());
}
useEffect(()=>{
    if(value){
        setNewTag({open:true,tag:value})
    }
},[value])
  return (
    <>
    {newTag.open&& <CustomDialog
      {...{
      
        title:"Add Question Tag",
    actions:[{
      sx: { width: "130px", padding: "6px" },
      content: "Save",
      disabled: newTag.loading??!newTag.tag,
      variant: "contained",
      onClick: () => {
        addQuestionTag()
      },
    }],
    content:  <Stack spacing="10px" width="400px">
    <Box sx={{ color: "#A09F9F" }}>
    {subHeading({ text: "Create new question tag and add it in the question", weight: 400,size:"0.7rem", })}
   
    </Box> 
  
    <Divider />
    {subHeading({ text: "Tag Name", weight: 500 })}
     <CustomTextField
      placeholder={"Enter Tag Name"}
      id={"Tag Name"}
      variant="outlined"
      error={false}
      value={newTag?.tag??""}
      disabled={newTag.loading ?? false}
      // multiline={true}
      onChange={(e) => {
        setNewTag({tag:e.target.value,open:true})
      }}
      onKeyPress={async (ev: any) => {
        if (ev.key === "Enter") {
          ev.preventDefault();
          addQuestionTag()
        }
      }}
      sx={{
        width: "100%",
        "& .MuiInputBase-root": { padding: "0" },
        "& .MuiInputBase-input": { padding: "10px 12px" },
      }}
      inputProps={{
        "data-testid": `tag-input`,
      }}
    />
  </Stack>}}
      open={newTag.open}
      onClose={() => {
        setNewTag({});
      }}/>}</>
  )
}

export default QuestionTag