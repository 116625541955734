import { Npcbg, edit, email, eyeOff, eyeOpen, npclogo } from "../../../../../assets/svgs";
export const currentVersiom = "0.0.1";



export const appName = "NetPractice";
export const defaultTexts = {
  comingSoon: "Yet to come!",
};
export const logoutContents={
    confirmation:{
      header:"Logout Confirmation!",
      content:"Are you sure you want to sign out? This action will delete all cache data.",
      confirmButton:"Logout",
      cancelButton:"No"
    }
  };
export const LoginContents = {
    heading: `Login to ${appName}`,
    email: {
      label: "Email",
      placeholder: "Enter Email Id",
      icon: email,
    },
    password: {
      label: "Password",
      placeholder: "Enter Password",
      icon: { open: eyeOpen, close: eyeOff },
    },
    otp: {
      label: "Enter OTP",
    },
    buttons: {
      resend: "Resend Code",
      forgotpassword: "Forgot Password ?",
      sendOtp: "Send OTP",
      submit: "Login",
    },
    optSentMessage: "OTP has been sent to",
    otpSentSuccess: "OTP sent again Successfully!",
    loginSuccess: "Login Successfully!",
    successMessage: "OTP sent to your email id.",
    logo: npclogo,
    edit: edit,
    background: Npcbg,
  };
export const allStaticText ={appName,defaultTexts,logoutContents,LoginContents}
   