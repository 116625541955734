import React, { ReactElement } from "react";
import { Box, Stack } from "@mui/material";
import { columnCenter } from "../../GlobalStyles";
import { heading, paragraphs } from "../CustomTexts";
import CustomContainedButton, { CustomContainedButtonProps } from "../CustomContainedButton";
export interface EmptyScreenProps {
  readonly icon?: ReactElement;
  readonly headingText?: string | ReactElement;
  readonly message?: string | ReactElement;
  readonly buttonProps?: CustomContainedButtonProps;
  readonly help?: ReactElement;
}
function EmptyScreen(props: EmptyScreenProps) {
  const {  icon, buttonProps,headingText, message, help } = props;
  return (
    <Stack  direction="column"height="100%"width="100%" sx={columnCenter} spacing="20px" >
      {icon && <Box>{icon}</Box>}
      {headingText && <Box >{heading({text:headingText})}</Box>}
      {message && <Box >{paragraphs({text:message})}</Box>}
      {help && <Box >{help}</Box>}
      {buttonProps && (
        <Box >
          <CustomContainedButton {...buttonProps} />
        </Box>
      )}
    </Stack>
  );
}

export default EmptyScreen;
