import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks/storeHooks";
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import CustomCard from "../../../Components/CustomCard";
import {
  headerContent,
  iconText,
  paragraphs,
  subHeading,
} from "../../../Components/CustomTexts";
import { ObjectValues } from "../../login";
import globalColors from "../../../globalColors";
import CustemListItem, {
  CustomListItem,
} from "../../../Components/CustemListItem";
import { Addicon, HelpIcon, moreRound } from "../../../assets/svgs";
import {
  addQuestion,
  playList,
  smallHelpIcon,
} from "../../../assets/svgs/HelpIcon";
import {
  displayColumn,
  columnCenter,
  rowVerticalCenter,
  rowCenter,
} from "../../../GlobalStyles";
import CustomHoverPoper from "../../../Components/CustomHoverPoper";
import { moreIcon } from "../../../assets/svgs/moreIcon";
import { star, starBorder } from "../../../assets/svgs/star";
import CustomIconButton from "../../../Components/CustomIconButton";

export interface itemPropNew {
  list: ObjectValues[];
  primary: string;
  selected_row: ObjectValues | null;
  title: string;
  onClick(val: any,s?:any): void;
  fixlist?:any;
}
function NewQuestionsCard({
  item,
  setUpdates,
  selected,setCreateQuestion
}: {
  item: { white_list: itemPropNew; free: itemPropNew; title: string,titl2?:string };
  selected?: string[];
  setUpdates?(id: any): void;
  setCreateQuestion?:any
}) {
  const dispatch = useAppDispatch();
  return (
    <CustomCard
      sx={{
        maxWidth: "300px",
        minWidth: "35%",
        p: "7px",
        boxShadow: "none",
        position: "relative",
        height: "calc(100% - 16px)",
        flex: "8",
        border: `1px solid ${globalColors.primary.borderColor}`,
      }}
      content={
        <Stack spacing={"7px"} height="100%">
          {headerContent({
            size: "0.8rem",
            text: item.title,
            sx: {
              p: "10px",
              color: globalColors.primary.pureWhite,
              textTransform: "capitalize",
              background: globalColors.primary.black,
              borderRadius: "6px",
              alignItems: "center",
              display: "flex",
            },
            icons: [  
              {
              content: iconText({ icon: Addicon }),
              variant: "contained",
              onClick: () => {
               setCreateQuestion({open:true})
              },
              sx: { p: "5px",background:`${globalColors.primary.pureWhite} !important` ,color:"black"},
            },
          ]
          })}
          <Stack flex="8"overflow={"hidden"}>
            <Box
              sx={{
                maxHeight: "50%",
                height: "fit-content",
                minHeight:"183px",
                width: "100%",
                margin: "0 -7px",
                padding: "15px 7px",
                ...displayColumn,
                borderBottom: `1px solid ${globalColors.primary.borderColor}`,
              }}
            >
              <CustomHoverPoper
                content={
                  <Box
                    sx={{
                      fontSize: "1.1rem",
                      lineHeight: "1.3rem",
                      color: "black",
                      gap: "3px",
                      //   textAlign: "center",
                      maxWidth: "300px",
                      flexWrap: "wrap",
                      fontWeight: 500,
                      //   ...rowCenter,
                    }}
                  >
                    After Selecting questions from open questions when user
                    clicks on{playList}button, the questions come under
                    Whitelisted Questions. All the star marked question become
                    priority questions which can be arranged by dragging their
                    position
                  </Box>
                }
                msg={paragraphs({
                  text: "Whitelist Questions",
                  size: "0.7rem",
                  line: "13px",
                  icon: smallHelpIcon,
                })}
              />
              <Stack sx={{ flex: 8,overflowY: "auto",  ...rowVerticalCenter, mt: "10px" }}>
                {item.white_list.list.length > 0 ? (
                  <Stack
                    // spacing={"7px"}
                    sx={{ overflowY: "auto", height: "100%", width: "100%" }}
                  >
                    {item.white_list.list &&
                      item.white_list.list.length > 0 &&
                      item.white_list.list.map((li, i: number) => {
                        return (
                          <>
                            <CustomListItem
                              
                              alignItems="flex-start"
                              sx={{
                                display: "flex",
                                background: "",
                                color: globalColors.primary.black,
                                borderRadius: "7px",
                                transition: "background-color 0.3s ease", // Optional: Add a transition for smoother effect
                                mb: "5px",
                                p: "0",
                              }}
                            ><ListItemIcon
                            sx={{
                              ...{
                                minWidth: 0,
                                m: "0 10px 0 0",
                                justifyContent: "center",
                                color: globalColors.primary.black,
                                cursor:"pointer"
                              },
                            }}  onClick={() => {
                              if (item.white_list.onClick) {
                                item.white_list.onClick(li);
                              }
                            }}
                          >
                            {moreIcon}
                          </ListItemIcon>
                              {/* <ListItemButton
                                sx={{
                                  maxHeight: "40px",
                                  p: "5px",
                                  borderRadius: "0 !important",
                                  background: "transparent !important",
                                  display: "flex",
                                  flexDirection: "row-reverse",
                                }}
                              >  */}
                                <ListItemText
                                  sx={{
                                    ...{ m: "0", p: "0", fontSize: "0.875rem" },
                                  }}
                                  primary={
                                    (
                                      <div
                                      style={{maxHeight:"42px",overflow:"hidden"}}
                                        dangerouslySetInnerHTML={{
                                          __html: li.name ?? li.text ?? "",
                                        }}
                                      />
                                    ) as any
                                  }
                                />
                              <CustomIconButton
                                  onClick={() => {
                                    if (item.white_list.onClick) {
                                      item.white_list.onClick(li,"priority");
                                    }
                                  }}
                                  content={li.priority?star:starBorder}
                                  sx={{minWidth:"fit-content"}}
                                />
                            </CustomListItem>
                            {i + 1 !== item.white_list.list.length && (
                              <Box
                                className="dashed-border"
                                sx={{ m: "7px 0" }}
                              />
                            )}
                          </>
                        );
                      })}
                  </Stack>
                ) : (
                  <Box sx={{ ...columnCenter, gap: "15px", width: "100%" }}>
                    {addQuestion}
                    <Box
                      sx={{
                        fontSize: "0.8rem",
                        lineHeight: "13px",
                        color: "black",
                        gap: "3px",
                        textAlign: "center",
                        maxWidth: "300px",
                        flexWrap: "wrap",
                        ...rowVerticalCenter,
                      }}
                    >
                      To add or remove a question select the {playList} from
                      below
                    </Box>
                  </Box>
                )}
              </Stack>
            </Box>
            <Box
              sx={{
                height: "50%",
                width: "100%",
                padding: "15px 0",
                ...displayColumn,
              }}
            >
              {" "}
              <CustomHoverPoper
                content={
                  <Box
                    sx={{
                      fontSize: "1.1rem",
                      lineHeight: "1.3rem",
                      color: "black",
                      gap: "3px",
                      //   textAlign: "center",
                      maxWidth: "300px",
                      flexWrap: "wrap",
                      fontWeight: 500,
                      //   ...rowCenter,
                    }}
                  >
                    All the selected and added questions come automatically
                    under Open Questions.{" "}
                  </Box>
                }
                msg={paragraphs({
                  text: "Open Questions",
                  size: "0.7rem",
                  line: "13px",
                  icon: smallHelpIcon,
                })}
              />
              <Stack sx={{ overflowY: "auto",  flex: 8, ...rowVerticalCenter, mt: "10px" }}>
                {item.free.list.length > 0||item.free.fixlist.length>0 ? (
                  <Stack
                    // spacing={"7px"}
                    minWidth={"125px"}
                    sx={{ overflowY: "auto", height: "100%", width: "100%" }}
                  >
                    {item.free.list &&
                      item.free.list.length > 0 &&
                      item.free.list.map((li, i: number) => {
                        return (
                          <>
                           

                              <CustomListItem
                              
                              alignItems="flex-start"
                              sx={{
                                display: "flex",
                                background:li.cluster? globalColors.primary.disableBg: "",
                                color: globalColors.primary.black,
                                borderRadius: "7px",
                                transition: "background-color 0.3s ease", // Optional: Add a transition for smoother effect
                                mb: "5px",
                                p: "0",
                              }}
                            >       <ListItemIcon
                                sx={{
                                  ...{
                                    minWidth: 0,
                                    m: "0 10px 0 0",
                                    justifyContent: "center",
                                    color: globalColors.primary.black,
                                    cursor:"pointer"
                                  },
                                }} onClick={() => {
                                  if (item.free.onClick) {
                                    item.free.onClick(li);
                                  }
                                }}
                              >
                                {playList}
                              </ListItemIcon>
                               <ListItemText
                                  sx={{
                                    ...{ m: "0", p: "0", fontSize: "0.875rem" },
                                  }}
                                  primary={
                                    (
                                      <div
                                      style={{maxHeight:"42px",overflow:"hidden"}}
                                      
                                        dangerouslySetInnerHTML={{
                                          __html: li.name ??li.text ??  "",
                                        }}
                                      />
                                    ) as any
                                  }
                                />
                           
                              <CustomIconButton
                                  onClick={() => {
                                    if (item.free.onClick) {
                                      item.free.onClick(li,"priority");
                                    }
                                  }}
                                  content={li.priority?star:starBorder}
                                  sx={{minWidth:"fit-content"}}
                                />
                            </CustomListItem>
                           
                            {(i + 1 === item.free.list.length)&&(item.free.fixlist.length===0)?"":(
                              <Box
                                className="dashed-border"
                                sx={{ m: "7px 0" }}
                              />
                            )}
                          </>
                        );
                      })}
                      {item.free.fixlist.map((li:any, i: number) => {
                        return (
                          <>
                           
                              <CustomListItem
                              
                              alignItems="flex-start"
                              sx={{
                                display: "flex",
                                background: "",
                                color: globalColors.primary.black,
                                borderRadius: "7px",
                                transition: "background-color 0.3s ease", // Optional: Add a transition for smoother effect
                                mb: "5px",
                                p: "0",
                              }}
                            ><ListItemIcon
                            sx={{
                              ...{
                                minWidth: 0,
                                m: "0 10px 0 0",
                                justifyContent: "center",
                                color: globalColors.primary.black,
                                cursor:"pointer"
                              },
                            }} onClick={() => {
                              if (item.free.onClick) {
                                item.free.onClick(li);
                              }
                            }}
                          >
                            {playList}
                          </ListItemIcon>
                            <ListItemText
                                  sx={{
                                    ...{ m: "0", p: "0", fontSize: "0.875rem" },
                                  }}
                                  primary={
                                    (
                                      <div
                                      style={{maxHeight:"42px",overflow:"hidden"}}
                                      
                                        dangerouslySetInnerHTML={{
                                          __html: li.text ??li.name ?? "",
                                        }}
                                      />
                                    ) as any
                                  }
                                /> <CustomIconButton
                                onClick={() => {
                                  if (item.free.onClick) {
                                    item.free.onClick(li,"priority");
                                  }
                                }}
                                content={li.priority?star:starBorder}
                                sx={{minWidth:"fit-content"}}
                              />
                            </CustomListItem>
                           
                            {i+1 === item.free.fixlist.length ?"": (
                              <Box
                                className="dashed-border"
                                sx={{ m: "7px 0" }}
                              />
                            )}
                          </>
                        );
                      })}
                  </Stack>
                ) : (
                  <Box sx={{ ...columnCenter, gap: "15px", width: "100%" }}>
                    {addQuestion}
                    {paragraphs({
                      text: "To add or remove a question select the question and click on Add or Remove depending on the action you want to perform",
                      size: "0.8rem",
                      line: "13px",
                      color: "black",
                      sx: { textAlign: "center", maxWidth: "300px" },
                    })}
                  </Box>
                )}
              </Stack>
            </Box>
          </Stack>
          {/* 
          <Box
            sx={{
              mt: "7px",
              borderBottom: `1px solid ${globalColors.primary.borderColor}`,
            }}
          ></Box>
          <Stack
            spacing={"7px"}
            minWidth={"125px"}
            sx={{ overflowY: "auto", flex: 8 }}
          >
            {item?.list &&
              item?.list.length > 0 &&
              item?.list.map((li, i: number) => {
                return (
                  <>
                    <CustemListItem
                      key={`${li.name}-${i}`}
                      ListItemButtonsx={{ maxHeight: "fit-content" }}
                      keyId={`${li.name}`}
                        isSelected={item.selected_row?item.selected_row.id===li.id: selected?.includes(li.id)}
                      selectedColor={globalColors.primary.lightGrey}
                      onClick={() => {
                        if(setUpdates){setUpdates(li.id)}}}
                      direction="reverse"
                      primary={li.name}
                    />{" "}
                    {i + 1 !== item.list.length && (
                      <Box className="dashed-border" />
                    )}
                  </>
                );
              })}
          </Stack> */}
        </Stack>
      }
    />
  );
}

export default NewQuestionsCard;
