import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { ObjectValues, inputProp } from "../../pages/login";
import CustomTextField from "../CustomTextfield";
import { Chip } from "@mui/material";
const filter = createFilterOptions<ObjectValues>();

export default function CustomAutocomplete({
  list,
  listLabel,
  freeSolo,
  helperText,
  error,
  onChange,
  disabled,
  value
}: inputProp) {
  const label = listLabel ?? "";
  return (
   <> 
   <Autocomplete
   value={value??""}
   disabled={disabled ?? false}
   onChange={(event, newValue:any) => {
     if (onChange) {
       onChange(
         newValue &&  newValue.inputValue
           ? {
               [label]: newValue.inputValue,
             }
           : newValue
       );
     }
    
   }}
   filterOptions={(options, params) => {
     const filtered = filter(options, params);

     const { inputValue } = params;
     // Suggest the creation of a new value
     const isExisting = options.some(
       (option) => inputValue === option[label]
     );
     if (inputValue !== "" && !isExisting && freeSolo) {
       // if(freeSolo.dialog){
       //   setDialog({open:true,value:inputValue})
       // }else{
       filtered.push({
         inputValue,
         [label]: `Add ${inputValue} ${freeSolo.addText??""}`,
       });
     // }
     }

     return filtered;
   }}
   selectOnFocus
   clearOnBlur
   handleHomeEndKeys
   id="free-solo-with-text-demo"
   options={list ?? []}
   getOptionLabel={(option) => {
  
     if (typeof option === "string") {
       return option;
     }
     // Add "xxx" option created dynamically
     if (option.inputValue) {
       return option.inputValue;
     }
     // Regular option
     return option[label];
   }}
   renderTags={(value: readonly string[], getTagProps) =>
    value.map((option: string, index: number) => (
      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
    ))
  }
   renderOption={(props, option) => <li {...props} style={{textTransform:"capitalize"}}>{option[label]}</li>}
   sx={{
     width: "100%",
     padding: "0",
     "& .MuiInputBase-root": { padding: "10px 12px" },
     "& .MuiOutlinedInput-input": {
       padding: "0 !important",
     },
   }}
   freeSolo={freeSolo?true: false}
   renderInput={(params) => (
     <CustomTextField
       {...params}
       helperText={helperText}
       error={error}
       disabled={disabled ?? false}
     />
   )}
 />
</>
  );
}
