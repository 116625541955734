import React, { useCallback } from "react";
import "./CustomTable.css";
import DataGridTable from "./DataGridTable";
import moment from "moment";

interface ColumnConfig {
  name: string;
  header: string;
  defaultWidth?: number;
  defaultVisible?: boolean;
  sortable?: boolean;
  type?: string;
  defaultFlex?: number;
}

interface CustomTableProps {
  data?: any[];
  filterValue?: any[];
  columnsConfig?: ColumnConfig[];
  apiCall?: boolean;
  renderRowDetails?: any;
  loading?: boolean;
  isServerSide?: boolean;
}

const CustomTable: React.FC<CustomTableProps> = ({
  data,
  filterValue,
  columnsConfig,
  apiCall,
  renderRowDetails,
  loading,
  isServerSide
}) => {
  const loadData = useCallback(
    (props: any) => {
      // You can customize this part to load data from an API or other sources.
      // For now, returning the provided data prop.
      return data || [];
    },
    [data]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataSource = useCallback(loadData, [filterValue]);
  return (
    <DataGridTable
      columns={columnsConfig}
      rows={apiCall ? dataSource : data ?? []}
      renderRowDetails={renderRowDetails}
      isServerSide={isServerSide}
    />
  );
};

export default CustomTable;
