export const home=(props:{height?:string})=> (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.height??"16"}
    height={props?.height??"16"}
    viewBox="0 0 16 16"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6.10477 13.8476V11.8031C6.10476 11.2831 6.5287 10.8605 7.05397 10.857H8.97802C9.50579 10.857 9.93363 11.2806 9.93363 11.8031V13.8539C9.93351 14.2954 10.2895 14.6563 10.7353 14.6666H12.018C13.2967 14.6666 14.3333 13.6404 14.3333 12.3745V6.55854C14.3265 6.06053 14.0903 5.59288 13.6919 5.28867L9.30511 1.79018C8.53658 1.18102 7.4441 1.18102 6.67557 1.79018L2.30798 5.29502C1.90813 5.59799 1.67155 6.06642 1.66663 6.56489V12.3745C1.66663 13.6404 2.70321 14.6666 3.9819 14.6666H5.2646C5.72153 14.6666 6.09194 14.2999 6.09194 13.8476" />
  </svg>
);
