import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, {  requestObject } from "../../../../../request/apiRequest/apiRequest";
import { allApis } from "../../../../../request/config";
import { ObjectValues } from "../../../../../pages/login";
export interface userResponse{
}
export const fetchContentData = createAsyncThunk(
  "exams/fetchContentData",
  async () => {
    try {
      const response: userResponse = await apiRequest({api:allApis.content,method:"GET"});
      return response??null;
    } catch (error: any) {
      return null
    }
  }
);export const fetchTagsData = createAsyncThunk(
  "exams/fetchTagsData",
  async () => {
    try {
      const response: ObjectValues[] = await apiRequest({api:allApis.questionTags.list,method:"GET"});
      return response??null;
    } catch (error: any) {
      return null
    }
  }
);
export const fetchClusterQuestions= createAsyncThunk(
  "exams/fetchClusterQuestions",
    async (props: any) => {
      try {
        const newPayload=props
        const response: any = await apiRequest(newPayload ?? {});
        return {[props.id??"cluster"]:response&&response.results.length>0?
        {...response,results:response.results.map((ss: any)=>{return {...ss,cluster:props.id??"cluster"}})}:response};
      } catch (error: any) {
        return null
      }
    }
  );
  export const fetchSinlgQustion = createAsyncThunk(
    "exams/fetchSinlgQustion",
      async (props: any) => {
        try {
          const newPayload=props
          const response: userResponse = await apiRequest(newPayload ?? {});
          return {[props.id??"question"]:response??null};
        } catch (error: any) {
          return null
        }
      }
    ); 
  export const fetchAllClusters= createAsyncThunk(
      "exams/fetchAllClusters",
        async () => {
          try {
            const response: ObjectValues[] = await apiRequest({api:allApis.all_clusters,method:"GET"});
            return response??[]
          } catch (error: any) {
            return null
          }
        }
      );

      export const fetchAllExamsTabData = createAsyncThunk(
        "exams/fetchAllExamsTabData",
        async () => {
          try {
            const response: ObjectValues[] = await apiRequest({api:allApis.examsTab,method:"GET"});
            return response??null;
          } catch (error: any) {
            return null
          }
        }
      );

      export const fetchAllQuestionsTabData = createAsyncThunk(
        "exams/fetchAllQuestionsTabData",
        async (clusterId: string) => {
          try {
            console.log(allApis.questionTab(clusterId))
            const response: any = await apiRequest({api:allApis.questionTab(clusterId),method:"GET"});
            return response??null;
          } catch (error: any) {
            return null
          }
        }
      );

      export const fetchAllMockQuestionsTabData = createAsyncThunk(
        "exams/fetchAllMockQuestionsTabData",
        async (mock_test_section_id: string) => {
          try {
            // console.log("tmkoc req", allApis.questionMockTab(mock_test_section_id))
            const response: any = await apiRequest({api:allApis.questionMockTab(mock_test_section_id),method:"GET"});
             console.log("tmkoc", response)
            return response??null;
          } catch (error: any) {
            return null
          }
        }
      );

      export const getQualityChecks = createAsyncThunk(
        "exams/getQualityChecks",
        async ({ id, type }: { id: string, type?: string }) => {
          // console.log("API URL: ", allApis.qualityCheck(id) + (type ? `?type=${type}` : ""));
          try {
            const response: any = await apiRequest({api:allApis.qualityCheck(id) + (type ? `?check_type=${type}` : ""),method:"GET"});
            return response ?? null;
          } catch (error: any) {
            // console.error("API request failed:", error);
            throw error;
          }
        }
      );

      export const passFailQualityChecks = createAsyncThunk(
        "exams/passFailQualityChecks",
        async ({ id, quality_check_status }: { id: string; quality_check_status: string }) => {
            try {
                const response: any = await apiRequest({
                    api: allApis.passFailQC(id), // Assuming allApis.passFailQC generates the correct API endpoint
                    method: "PATCH",
                    payload: {
                        quality_check_status: quality_check_status // Send the status as part of the payload
                    }
                });
                return response ?? null;
            } catch (error: any) {
                throw error;
            }
        }
    );


    export const getQCPassFailLogs = createAsyncThunk(
      "exams/getQCPassFailLogs",
      async ({ id, type }: { id: string, type: string }) => {
        // console.log("API URL: ", allApis.qualityCheck(id) + (type ? `?type=${type}` : ""));
        try {
          const response: any = await apiRequest({api:allApis.passFailQCLog(id, type),method:"GET"});
          return response ?? null;
        } catch (error: any) {
          // console.error("API request failed:", error);
          throw error;
        }
      }
    );
    