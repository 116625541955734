// AssignDialog.tsx
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Autocomplete, TextField } from '@mui/material';
import { assignStaff, fetchReportedQuestions } from '../../store/slices/contentFeedback/contentFeedbackSlice';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../../hooks/storeHooks';
import { Snackbar, Alert } from '@mui/material';


interface AssignDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (assignee: string) => void;
  selectedStaff: any;
  handleStaffChange: (event: any,newValue: any) => void;
  staffList: any[];
  row: any
}

const AssignDialog: React.FC<AssignDialogProps> = ({ open, row, onClose, onConfirm,staffList, selectedStaff,handleStaffChange }) => {
  const dispatch = useAppDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  
  const handleConfirm = async () => {
    const requiredData = { id: row?.id, selectedStaffId: selectedStaff?.id };

    try {
      await dispatch(assignStaff(requiredData)).unwrap(); // Unwrap the promise to handle errors
      setSnackbarSeverity('success');
      setSnackbarMessage('Staff assigned successfully!');
      dispatch(fetchReportedQuestions("pending"));
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to assign staff.');
    } finally {
      setSnackbarOpen(true); // Show snackbar in both success and error cases
      onConfirm(selectedStaff); // or selectedPerson
      onClose();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Assign Report</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <Autocomplete
        id="staff-autocomplete"
        options={staffList}
        getOptionLabel={(option) => option.full_name}
        style={{ marginTop: '6px' }}
        value={selectedStaff}
        onChange={handleStaffChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Assign To"
            variant="outlined"
            fullWidth
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
      />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleConfirm} color="primary" disabled={!selectedStaff}>Confirm</Button>
      </DialogActions>
    </Dialog>
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AssignDialog;
