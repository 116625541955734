import { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import CustomTextField from "../../../Components/CustomTextfield";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Tabs,
  Tab,
  CircularProgress,
  Typography
} from "@mui/material";
import Divider from '@mui/material/Divider';
import { ObjectValues, inputProp } from "../../login";
import { headerContent, subHeading } from "../../../Components/CustomTexts";
import MultipleAutoComplte from "../../../Components/MultipleAutoComplte";
import CustomCkeditor from "../../../Components/CustomCkeditor";
import globalColors from "../../../globalColors";
import {
  displayColumn,
  displayRow,
  rowCenter,
  rowSB,
} from "../../../GlobalStyles";
import CustomIconButton from "../../../Components/CustomIconButton";
import CustomContainedButton from "../../../Components/CustomContainedButton";
import { useAppDispatch, useAppSelecter } from "../../../hooks/storeHooks";
import { showSnack } from "../../../store/slices/constants";
import CustomDatePicker from "../../../Components/CustomDatePicker";
import moment from "moment";
import { StopScroll } from "../../../store/slices/constants/staticContents";
import CopyComponent from "../../../Components/CopyComponent";
import { Addicon, copyIcon, cross, rightIcons } from "../../../assets/svgs";
import SvgPreview from "../../../Components/SvgPreview";
import { formCompProps } from "../../../Components/FormComponent";
import apiRequest from "../../../request/apiRequest/apiRequest";
import CustomAutocomplete from "../../../Components/CustomAutocomplete";
import { fetchSinlgQustion, getQualityChecks } from "../../../store/slices/exams/thunks/fetchContentData";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { allApis } from "../../../request/config";
import { Paper } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import QuestionQualityCheckBoxes from "./duplicateQualityCheck";
import PreviewComponent from "./previewComponent";
import DuplicateQualityCheckBoxes from "./duplicateQualityCheck";
import ImageQualityCheckBoxes from "./imageQualityCheck";
import GrammarQualityCheckBoxes from "./grammarQualityCheck";
import CorrectnessQualityCheckBoxes from "./correctnessQualityCheck";
import ClusterQualityCheckBoxes from "./clusterQualityCheck";
interface quesformProp extends formCompProps {
  getApi: any;
  fromCluster?: true;
  mockTestSectionID?: number;
  questionStatus?: string;
  language_available?: string;
  selectedQuestionId?: any;
}

const ScrollablePaper = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  top: '30%', // Start the Paper component in the middle of the viewport
  transform: 'translateY(-50%)', // Center the Paper component vertically
  padding: theme.spacing(2),
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>{children}</Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}


function QuestionFormComponentMock(props: quesformProp) {
  const {
    onClear,
    onSubmit,
    api,
    editData,
    header,
    inputFormArray,
    extraFields,
    formatPayload,
    successMessage,
    oldSubmittedData,
    inputSteps,
    customContent,
    getApi, fromCluster,
    mockTestSectionID,
    questionStatus,
    language_available,
    selectedQuestionId
  } = props;
  const dispatch = useAppDispatch();
  const editExamData = useAppSelecter(
    (state: ObjectValues) => state.exams.single_questions
  );
  const getQualityCheckData = useAppSelecter(
    (state: ObjectValues) => state.exams.quality_checks
  );

  const qualityChecksLoading = useAppSelecter(
    (state: ObjectValues) => state.exams.qualityChecks_loading
    );
  const [value, setValue] = useState(0);
  const [prevTab, setPrevTab] = useState(0);
  const [showQualityCheckBoxes, setShowQualityCheckBoxes] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isDuplicacyClicked, setIsDuplicacyClicked] = useState(false);

  // Function to handle button click from the child
  const handleButtonClick = (clicked: any) => {
    setIsDuplicacyClicked(clicked);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPrevTab(value);
    const newData = [...allSubmittedData]
    newData[value] = submittedData;
    setSubmittedData(allSubmittedData[newValue])
    setAllSubmittedData(newData);
    setValue(newValue);

  }

  const mergePaylod = (id: any) => {
    function makePropertiesWritable(obj: {
      [x: string]: any;
      hasOwnProperty: (arg0: string) => any;
    }) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          // If the property is an object, recursively make its properties writable
          if (typeof obj[key] === "object" && obj[key] !== null) {
            if (Array.isArray(obj[key])) {
              obj[key].forEach(
                (item: {
                  [x: string]: any;
                  hasOwnProperty: (arg0: string) => any;
                }) => {
                  makePropertiesWritable(item);
                }
              );
            } else {
              makePropertiesWritable(obj[key]);
            }
          }
          // Make the property writable and configurable if it's not already defined
          if (!Object.getOwnPropertyDescriptor(obj, key)) {
            Object.defineProperty(obj, key, {
              writable: true,
              configurable: true,
            });
          }
        }
      }
    }

    const single_exam =
      editExamData[id] ?? questionGettingEditted?.question?.res ?? undefined;
    let newSubmittedData: any = {
      cluster: single_exam.cluster,
      explanation: single_exam.explanation,
      id: single_exam.id,
      is_active: single_exam.is_active,
      options: single_exam.options,
      status: single_exam.status,
      tag: single_exam.tag,
      translation: single_exam.translation,
    };
    makePropertiesWritable(newSubmittedData);

    allInputs.map((singleInput: inputProp) => {
      const queLanguage = submittedData[`Question LanguageId`];
      switch (singleInput.label) {
        // case "Question Language":
        //   newSubmittedData = {
        //     ...newSubmittedData,
        //     default_language: submittedData.default_languge
        //       ? queLanguage
        //       : single_exam.default_language,
        //   };
        //   break;
        case "Question Tags":
          newSubmittedData = {
            ...newSubmittedData,
            tag: submittedData[singleInput.label],
          };

          break;
        case "Explanation":
          let objs =
            Array.isArray(newSubmittedData.explanation?.translation) &&
              newSubmittedData.explanation?.translation.length > 0
              ? newSubmittedData.explanation?.translation.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData.explanation?.translation;
          newSubmittedData = {
            ...newSubmittedData,
            explanation: {
              ...newSubmittedData.explanation,
              translation: {
                ...objs,
                language: queLanguage,
                text: submittedData[singleInput.label],
              },
            },
          };

          break;
        case "Option A":
          let option: any =
            Array.isArray(newSubmittedData.options[0]?.translation) &&
              newSubmittedData.options[0]?.translation.length > 0
              ? newSubmittedData.options[0]?.translation?.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData.options[0]?.translation;

          const objOpt = {
            is_correct:
              submittedData[`correct_answer`]?.label === singleInput?.label,
            translation: {
              ...option,
              language: queLanguage,
              text: submittedData[singleInput.label],
            },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 0 ? { ...opt, ...objOpt } : opt;
            }),
          };

          break;
        case "Option B":
          let option1: any =
            Array.isArray(newSubmittedData.options[1]?.translation) &&
              newSubmittedData.options[1]?.translation?.length > 0
              ? newSubmittedData.options[1]?.translation?.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData.options[1]?.translation;
          const objOpt1 = {
            is_correct:
              submittedData[`correct_answer`]?.label === singleInput?.label,
            translation: {
              ...option1,
              language: queLanguage,
              text: submittedData[singleInput.label],
            },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 1 ? { ...opt, ...objOpt1 } : opt;
            }),
          };

          break;
        case "Option C":
          let option2: any =
            Array.isArray(newSubmittedData.options[2]?.translation) &&
              newSubmittedData.options[2]?.translation.length > 0
              ? newSubmittedData.options[2]?.translation?.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData.options[2]?.translation;

          const objOpt2 = {
            is_correct:
              submittedData[`correct_answer`]?.label === singleInput?.label,
            translation: {
              ...option2,
              language: queLanguage,
              text: submittedData[singleInput.label],
            },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 2 ? { ...opt, ...objOpt2 } : opt;
            }),
          };

          break;
        case "Option D":
          let option3: any =
            Array.isArray(newSubmittedData.options[3]?.translation) &&
              newSubmittedData.options[3]?.translation.length > 0
              ? newSubmittedData.options[3]?.translation?.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData.options[3]?.translation;
          const objOpt3 = {
            is_correct:
              submittedData[`correct_answer`]?.label === singleInput?.label,
            translation: {
              ...option3,
              language: queLanguage,
              text: submittedData[singleInput?.label],
            },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 3 ? { ...opt, ...objOpt3 } : opt;
            }),
          };
          break;
        case "Question":
          let quest: any =
            Array.isArray(newSubmittedData?.translation) &&
              newSubmittedData?.translation.length > 0
              ? newSubmittedData?.translation?.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData?.translation;
          if (quest) {
            quest = {
              ...quest,
              language: queLanguage,
              text: submittedData[singleInput.label],
            };
            newSubmittedData = { ...newSubmittedData, translation: quest };
          }
          break;
      }

      return singleInput;
    });
    return newSubmittedData;
  };
  const [questionGettingEditted, setQuestionGettingEditted] =
    useState<ObjectValues>({});
  const formatForSingleLanguage = (res: any, language: any) => {
    function makePropertiesWritable(obj: {
      [x: string]: any;
      hasOwnProperty: (arg0: string) => any;
    }) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          // If the property is an object, recursively make its properties writable
          if (typeof obj[key] === "object" && obj[key] !== null) {
            if (Array.isArray(obj[key])) {
              obj[key].forEach(
                (item: {
                  [x: string]: any;
                  hasOwnProperty: (arg0: string) => any;
                }) => {
                  makePropertiesWritable(item);
                }
              );
            } else {
              makePropertiesWritable(obj[key]);
            }
          }
          // Make the property writable and configurable if it's not already defined
          if (!Object.getOwnPropertyDescriptor(obj, key)) {
            Object.defineProperty(obj, key, {
              writable: true,
              configurable: true,
            });
          }
        }
      }
    }

    const single_exam = res;
    let newSubmittedData: any = single_exam;
    makePropertiesWritable(newSubmittedData);

    allInputs.map((singleInput: inputProp) => {
      const queLanguage = language;
      switch (singleInput.label) {
        case "Explanation":
          let objs =
            Array.isArray(newSubmittedData.explanation?.translation) &&
              newSubmittedData.explanation?.translation.length > 0
              ? newSubmittedData.explanation?.translation.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData.explanation?.translation;
          newSubmittedData = {
            ...newSubmittedData,
            explanation: { ...newSubmittedData.explanation, translation: objs },
          };

          break;
        case "Option A":
          let option: any =
            Array.isArray(newSubmittedData.options[0]?.translation) &&
              newSubmittedData.options[0]?.translation.length > 0
              ? newSubmittedData.options[0]?.translation?.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData.options[0]?.translation;

          const objOpt = {
            translation: { ...option },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 0 ? { ...opt, ...objOpt } : opt;
            }),
          };

          break;
        case "Option B":
          let option1: any =
            Array.isArray(newSubmittedData.options[1]?.translation) &&
              newSubmittedData.options[1]?.translation.length > 0
              ? newSubmittedData.options[1]?.translation?.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData.options[1]?.translation;
          const objOpt1 = {
            translation: { ...option1 },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 1 ? { ...opt, ...objOpt1 } : opt;
            }),
          };

          break;
        case "Option C":
          let option2: any =
            Array.isArray(newSubmittedData.options[2]?.translation) &&
              newSubmittedData.options[2]?.translation.length > 0
              ? newSubmittedData.options[2]?.translation?.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData.options[2]?.translation;

          const objOpt2 = {
            translation: { ...option2 },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 2 ? { ...opt, ...objOpt2 } : opt;
            }),
          };

          break;
        case "Option D":
          let option3: any =
            Array.isArray(newSubmittedData.options[3]?.translation) &&
              newSubmittedData.options[3]?.translation.length > 0
              ? newSubmittedData.options[3]?.translation?.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData.options[3]?.translation;
          const objOpt3 = {
            translation: { ...option3 },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 3 ? { ...opt, ...objOpt3 } : opt;
            }),
          };
          break;
        case "Question":
          let quest: any =
            Array.isArray(newSubmittedData?.translation) &&
              newSubmittedData?.translation.length > 0
              ? newSubmittedData?.translation?.find(
                (item: { language: any }) => item.language === queLanguage
              )
              : newSubmittedData?.translation;
          if (quest) {
            quest = { ...quest };
            newSubmittedData = { ...newSubmittedData, translation: quest };
          }
          break;
      }

      return singleInput;
    });
    return newSubmittedData;
  };
  const fomratQuestionData = (id: any, que?: any, language?: any) => {
    const single_exam = que ?? editExamData[id] ?? undefined;
    const current_language =
      single_exam?.translation?.language ??
      single_exam.default_language ??
      language ??
      "";
    const newSubmittedData: any = submittedData;
    allInputs.map((singleInput: inputProp) => {
      if (singleInput.defaultValue) {
        newSubmittedData[singleInput.label] = singleInput.defaultValue.value
          ? singleInput.defaultValue.value
          : "";

        newSubmittedData[`${singleInput.label}Id`] = singleInput.defaultValue.id
          ? singleInput.defaultValue.id
          : "";
      } else if (single_exam) {
        switch (singleInput.label) {
          // case "Question Language":
          //   newSubmittedData[singleInput.label] =
          //     singleInput.list?.find((it) => it.id === current_language)
          //       ?.label ?? "";
          //   newSubmittedData[`${singleInput.label}Id`] = current_language;
          //   newSubmittedData.default_languge =
          //     current_language === single_exam.default_language;
          //   break;
          case "Question Tags":
            newSubmittedData[singleInput.label] = single_exam.tag
              ? singleInput.list?.filter((item) =>
                single_exam.tag
                  .map((ss: any) => ss.id ?? ss)
                  .includes(item.id)
              )
              : [];

            break;
          case "Explanation":
            newSubmittedData[singleInput.label] =
              single_exam.explanation?.translation?.length > 0
                ? single_exam.explanation?.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam.explanation?.translation?.text ?? "";

            break;
          case "Option A":
            newSubmittedData[singleInput.label] =
              single_exam.options[0]?.translation?.length > 0
                ? single_exam.options[0]?.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam.options[0]?.translation?.text ?? "";
            if (single_exam.options[0]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Option B":
            newSubmittedData[singleInput.label] =
              single_exam.options[1]?.translation?.length > 0
                ? single_exam.options[1]?.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam.options[1]?.translation?.text ?? "";
            if (single_exam.options[1]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Option C":
            newSubmittedData[singleInput.label] =
              single_exam.options[2]?.translation?.length > 0
                ? single_exam.options[2]?.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam.options[2]?.translation?.text ?? "";
            if (single_exam.options[2]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Option D":
            newSubmittedData[singleInput.label] =
              single_exam.options[3]?.translation?.length > 0
                ? single_exam.options[3]?.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam.options[3]?.translation?.text ?? "";
            if (single_exam.options[3]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Question":
            newSubmittedData[singleInput.label] =
              Array.isArray(single_exam.translation) &&
                single_exam.translation?.length > 0
                ? single_exam.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam?.translation?.text ?? "";

            break;
        }
      }
      return singleInput;
    });
    if (language) {
      return {
        res: formatForSingleLanguage(single_exam, language),
        form: newSubmittedData,
      };
    } else {
      setSubmittedData(newSubmittedData);
    }
  };
  const getEditSubmittedData = (id: any, que?: any, language?: any) => {
    const single_exam = que;
    const current_language =
      single_exam?.translation?.language ??
      single_exam.default_language ??
      language ??
      "";
    // console.log("submittedData", submittedData);
    const newSubmittedData: any = submittedData;
    allInputs.map((singleInput: inputProp) => {
      if (singleInput.defaultValue) {
        newSubmittedData[singleInput.label] = singleInput.defaultValue.value
          ? singleInput.defaultValue.value
          : "";

        newSubmittedData[`${singleInput.label}Id`] = singleInput.defaultValue.id
          ? singleInput.defaultValue.id
          : "";
      } else if (single_exam) {
        switch (singleInput.label) {
          // case "Question Language":
          //   newSubmittedData[singleInput.label] =
          //     singleInput.list?.find((it) => it.id === current_language)
          //       ?.label ?? "";
          //   newSubmittedData[`${singleInput.label}Id`] = current_language;
          //   newSubmittedData.default_languge =
          //     current_language === single_exam.default_language;
          //   break;
          case "Question Tags":
            newSubmittedData[singleInput.label] = single_exam.tag
              ? singleInput.list?.filter((item) =>
                single_exam.tag
                  .map((ss: any) => ss.id ?? ss)
                  .includes(item.id)
              )
              : [];

            break;
          case "Explanation":
            newSubmittedData[singleInput.label] =
              single_exam.explanation?.translation?.length > 0
                ? single_exam.explanation?.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam.explanation?.translation?.text ?? "";

            break;
          case "Option A":
            newSubmittedData[singleInput.label] =
              single_exam.options[0]?.translation?.length > 0
                ? single_exam.options[0]?.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam.options[0]?.translation?.text ?? "";
            if (single_exam.options[0]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Option B":
            newSubmittedData[singleInput.label] =
              single_exam.options[1]?.translation?.length > 0
                ? single_exam.options[1]?.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam.options[1]?.translation?.text ?? "";
            if (single_exam.options[1]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Option C":
            newSubmittedData[singleInput.label] =
              single_exam.options[2]?.translation?.length > 0
                ? single_exam.options[2]?.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam.options[2]?.translation?.text ?? "";
            if (single_exam.options[2]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Option D":
            newSubmittedData[singleInput.label] =
              single_exam.options[3]?.translation?.length > 0
                ? single_exam.options[3]?.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam.options[3]?.translation?.text ?? "";
            if (single_exam.options[3]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Question":
            newSubmittedData[singleInput.label] =
              Array.isArray(single_exam.translation) &&
                single_exam.translation?.length > 0
                ? single_exam.translation?.find(
                  (item: { language: any }) =>
                    item.language === newSubmittedData[`Question LanguageId`]
                )?.text ?? ""
                : single_exam?.translation?.text ?? "";

            break;
        }
      }
      return singleInput;
    });
    return newSubmittedData;
    // if (language) {
    //   return {
    //     res: formatForSingleLanguage(single_exam, language),
    //     form: newSubmittedData,
    //   };
    // } else {
    //   setSubmittedData(newSubmittedData);
    // }
  };
  const [selectedStep, setStep] = useState<number>(1);
  const [stepResponse, setStepResponse] = useState<ObjectValues>({});
  const firstStep =
    inputSteps && inputSteps.length > 0
      ? inputSteps.find((s) => s.id === selectedStep)
      : undefined;
  const allInputs = firstStep
    ? firstStep.inputFormArray ?? []
    : inputFormArray ?? [];
  const [submittedData, setSubmittedData] = useState<ObjectValues>({});
  const [allSubmittedData, setAllSubmittedData] = useState<ObjectValues[]>(language_available?.split(",")?.map(_ => ({})) || []);
  const [questionArray, setQuestion] = useState<ObjectValues[]>([]);
  // useEffect(() => {
  //   if (oldSubmittedData) {
  //     setSubmittedData(oldSubmittedData);
  //   }
  // }, [oldSubmittedData]);
  const userData = useAppSelecter(
    (state: ObjectValues) => state.userSlice.loggedUser
  );

  const content_list = useAppSelecter(
    (state: ObjectValues) => state.exams.content_list
  );

  function renderTextField({
    label,
    placeholder,
    value,
    error,
    helperText,
    onChange,
    endAdornment,
    multiline,
    disabled,
    type,
    dataType,
    only_number,
  }: inputProp) {
    return (
      <CustomTextField
        placeholder={placeholder}
        disabled={submittedData.loader === true ? true : disabled ?? false}
        id={label}
        onKeyDown={(evt) => {
          if (only_number === true) {
            evt.key === "." && evt.preventDefault();
          }
          if (dataType === "number") {
            evt.key === "e" && evt.preventDefault();

            evt.key === "E" && evt.preventDefault();
            evt.key === "-" && evt.preventDefault();
            evt.keyCode === 38 && evt.preventDefault();
            evt.keyCode === 40 && evt.preventDefault();
          }
        }}
        onFocus={dataType === "number" ? StopScroll : () => { }}
        variant="outlined"
        error={error}
        type={dataType === "number" ? "number" : "text"}
        helperText={helperText}
        value={submittedData[label] ?? ""}
        maxRows={multiline ?? 1}
        minRows={multiline ?? 1}
        multiline={multiline ? true : false}
        onChange={(e) => {
          handleInputChange(label, e.target.value);
        }}
        onKeyPress={async (ev: any) => {
          if (ev.key === "Enter") {
            ev.preventDefault();

            // submit();
          }
        }}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": { padding: "0" },
          "& .MuiInputBase-input": multiline
            ? {
              padding: "10px 12px",
            }
            : {},
        }}
        InputProps={{
          endAdornment,
        }}
        inputProps={{
          // type: details.visible ? "" : type && "password",
          "data-testid": `${label}-input`, // Test ID for testing purposes
        }}
      />
    );
  }
  const fieldCountAndFinalPayload = () => {
    let submitData: ObjectValues = {}; //new FormData();
    const correctData: any = [];
    allInputs
      .filter((sA) => sA.api)
      .map((ss: inputProp) => {
        const { api, label, not_required, not_send_id, fieldType } = ss;
        if (
          // (singleInput.type === "checkBox" &&
          //   (submittedData[singleInput.label] === false ||
          //     submittedData[singleInput.label] === true)) ||
          // (singleInput.type === "toggle" &&
          //   (submittedData[singleInput.label] === false ||
          //     submittedData[singleInput.label] === true)) ||
          not_required === true ||
          submittedData[label]
        ) {
          correctData.push(label);

          // if (freeSolo) {
          //   if (submittedData[label] && submittedData[label].length > 0&&api) {
          //     submitData[api] = submittedData[label];
          //   }
          // }
          if (api) {
            submitData[api] = not_send_id
              ? submittedData[label]
              : submittedData[`${label}Id`]
                ? submittedData[`${label}Id`] === "-"
                  ? null
                  : submittedData[`${label}Id`]
                : fieldType === "date" && submittedData[label]
                  ? moment(new Date(submittedData[label])).format("YYYY-MM-DD")
                  : submittedData[label];
          }
        }
        //   } else {
        //     var errorMsg = this.state.errorMsg;
        //     errorMsg[singleInput.name] = `Wrong ${singleInput.name} !`;
        //     return this.setState({ errorMsg });
        //   }
        // } else {
        //   var errorMsg = this.state.errorMsg;
        //   errorMsg[singleInput.name] = singleInput.validate
        //     ? singleInput.validate(submittedData) === true
        //       ? ""
        //       : "Password do not match !"
        //     : `Invalid ${singleInput.name} !`;
        //   return this.setState({ errorMsg });
        // }
        // }
        // else {
        //   var errorMsg = this.state.errorMsg;
        //   errorMsg[singleInput.name] = `Please ${singleInput.type === "select" ? "select" : "add"} ${
        //     singleInput.name
        //   } !`;
        //   return this.setState({ errorMsg });
        // }
        return ss;
      });
    return { final: submitData, available: correctData };
  };
  const checkAllFields = () => {
    const { available, final } = fieldCountAndFinalPayload();
    return submittedData.correct_answer &&
      submittedData.correct_answer !== null &&
      allInputs.filter((sA) => sA.api).length === available.length
      ? { ...final, correct_answer: submittedData.correct_answer.label }
      : null;
  };
  function renderDropDown({
    label,
    placeholder,
    value,
    error,
    helperText,
    onChange,
    endAdornment,
    listLabel,
    type,
    list,
    disabled,
  }: inputProp) {
    return (
      <CustomTextField
        required={true}
        disabled={submittedData.loader === true ? true : disabled ?? false}
        // error={errorMsg[singleInput.name] ? true : false}
        error={error}
        helperText={helperText}
        autoComplete="off"
        value={submittedData[label] ?? ""}
        sx={{ width: "100%" }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                margin: "1px 0 0 2px",
                borderRadius: "10px", // Customize the border-radius for the dropdown paper
              },
            },
          },
        }}
        // submittedData[singleInput.name]}
        // style={
        //   textFieldStyle
        //     ? textFieldStyle
        //     : textFieldWidth
        //     ? styles.withDivider502
        //     : styles.withDivider50
        // }
        id="outlined-select-currency-native"
        select
        // label={"qq"}
        // singleInput.name.charAt(0).toUpperCase() + singleInput.name.slice(1)}
        // SelectProps={{ disableunderline: "true" }}
        variant="outlined"
        onChange={(e) => {
          // handleInputChange(label, e.target.value);
          if (e.target.value) {
            const id = `${label}Id`;
            // const submitted :any= {};
            // submitted[label] = e.target.value;
            // // var errorMsg = errorMsg;
            // // errorMsg[label] = false;
            // submitted[id] = e.currentTarget.id;
            setSubmittedData((prevDetails) => ({
              ...prevDetails,
              [label]: e.target.value,
              [id]: e.currentTarget?.id ?? "",
              [`${label}-error`]: undefined,
            }));
          }
          //     if (singleInput.onChange) {
          //       singleInput
          //         .onChange(
          //           submittedData,
          //           () => {
          //             this.setState({ loading: true });
          //           },
          //           () => {
          //             this.setState({ loading: false });
          //           }
          //         )
          //         .then((submittedData) => {
          //           this.setState({
          //             submittedData,
          //             errorMsg,
          //           });
          //         });
          //     } else {
          //       this.setState({
          //         submittedData,
          //         errorMsg,
          //       });
          //     }
          //   }
        }}
      >
        {list?.map((item, i) => (
          <MenuItem
            //   disabled={true}
            key={`${item[listLabel ?? "label"]}==${i}`}
            value={item[listLabel ?? "label"]}
            id={item.id ?? item[listLabel ?? "label"]}
            sx={{ textTransform: "capitalize" }}
          >
            {item[listLabel ?? "label"]}
          </MenuItem>
        ))}
      </CustomTextField>
    );
  }
  useEffect(() => {
    if (editData?.status == "ready") {
      setShowQualityCheckBoxes(true)
    }
    if (editData && editExamData[editData.id]) {
      fomratQuestionData(editData.id);

      const tabs =  language_available?.split(',');
      // ['english', 'hindi']
      const all_languages = editData.translation
        ? editData.translation.map((s: { language: any }) => s.language)
        : [];

      //console.log("editData", all_languages)

      if (all_languages.length > 0) {
        const queArr: any = [];
        tabs?.forEach((tab: any) => {
          if (all_languages.includes(tab)) {
            queArr.push(fomratQuestionData(editData.id, null, tab));
          }
          else {
            queArr.push({})
          }
        })
        // all_languages.map((language: any) => {
        //   if (language) {
        //     queArr.push(fomratQuestionData(editData.id, null, language));
        //   }
        //   return language;
        // });

        const defaultLang = queArr.find(
          (ss: {
            res: { default_language: any; translation: { language: any } };
          }) => ss.res?.default_language === ss.res?.translation?.language
        );

        setQuestion(queArr);
        // setQuestionGettingEditted({ question: defaultLang ?? queArr[0] });

        let alldata: any = [];
        queArr.forEach((q: any, i: number) => {
          //console.log(getEditSubmittedData(q.res.id, q.res))
          if (q && q.res) {
            let data = { ...getEditSubmittedData(q.res.id, q.res) }
            alldata.push(data)
          } else {
            alldata.push({})
          }
        })
        // let data = questionArray.map((q:any) => (getEditSubmittedData(q.res.id, q.res)))
        console.log("alldata1", alldata)
        setAllSubmittedData(alldata)
        setSubmittedData(alldata[0])
      }
    }
  }, [editExamData]);

  // useEffect(() => {
  //   console.log("question", questionArray);
  //   let alldata: any = [];
  //   questionArray.forEach((q:any) => {
  //     //console.log(getEditSubmittedData(q.res.id, q.res))
  //     let data = {...getEditSubmittedData(q.res.id, q.res)}
  //     alldata.push(data)
  //   })
  //   // let data = questionArray.map((q:any) => (getEditSubmittedData(q.res.id, q.res)))
  //    //setAllSubmittedData(alldata)
  //    //setSubmittedData(alldata[0])
  // }, [questionArray])

  useEffect(() => {
    if (editData?.id || selectedQuestionId) {
      dispatch(
        fetchSinlgQustion({
          api: getApi(editData?.id),
          method: "GET",
          payload: {},
          id: editData?.id,
        })
      );
    }
    if (!oldSubmittedData) {
      const newSubmittedData: any = submittedData;
      allInputs.map((singleInput: inputProp) => {
        if (singleInput.defaultValue) {
          newSubmittedData[singleInput.label] = singleInput.defaultValue.value
            ? singleInput.defaultValue.value
            : "";

          newSubmittedData[`${singleInput.label}Id`] = singleInput.defaultValue
            .id
            ? singleInput.defaultValue.id
            : "";
        } else if (!newSubmittedData[singleInput.label] && editData) {
          switch (singleInput.label) {
            // case "Question Language":
            //   newSubmittedData[singleInput.label] =
            //     singleInput.list?.find(
            //       (it) => it.id === editData.primary_language
            //     )?.id ?? "";
            //   newSubmittedData[`${singleInput.label}Id`] =
            //     editData.primary_language;
            //   break;
            case "Question Tags":
              newSubmittedData[singleInput.label] = editData.tag
                ? singleInput.list?.filter((item) =>
                  editData.tag.map((ss: any) => ss.id ?? ss).includes(item.id)
                )
                : [];
              break;
            case "Explanation":
              newSubmittedData[singleInput.label] =
                editData.explanation?.text ?? "";
              break;
            case "Option A":
              newSubmittedData[singleInput.label] = singleInput.list?.filter(
                (item) => (editData.tag ? editData.tag.includes(item.id) : [])
              );
              break;
            case "Option B":
              newSubmittedData[singleInput.label] = singleInput.list?.filter(
                (item) => (editData.tag ? editData.tag.includes(item.id) : [])
              );
              break;
            case "Option C":
              newSubmittedData[singleInput.label] = singleInput.list?.filter(
                (item) => (editData.tag ? editData.tag.includes(item.id) : [])
              );
              break;
            case "Option D":
              newSubmittedData[singleInput.label] = singleInput.list?.filter(
                (item) => (editData.tag ? editData.tag.includes(item.id) : [])
              );
              break;
            case "Question":
              newSubmittedData[singleInput.label] =
                editData.translation?.text ?? "";
              // newSubmittedData["correct_answer"]=singleInput

              break;
          }
        }
        return singleInput;
      });
      setSubmittedData(newSubmittedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  useEffect(() => {
    if(editData?.id && editData?.status == "ready") {
     dispatch(getQualityChecks({ id: editData?.id }));
    }
  },[dispatch, editData?.id]);
  const initialValue = (res?: any) => {
    if (!oldSubmittedData) {
      const newSubmittedData = res ? {} : submittedData;
      allInputs.map((singleInput: inputProp) => {
        if (!newSubmittedData[singleInput.label]) {
          if (singleInput.defaultValue) {
            newSubmittedData[singleInput.label] = singleInput.defaultValue.value
              ? singleInput.defaultValue.value
              : "";

            newSubmittedData[`${singleInput.label}Id`] = singleInput
              .defaultValue.id
              ? singleInput.defaultValue.id
              : "";
          } else {
            return (newSubmittedData[singleInput.label] = "");
          }
        }
        return singleInput;
      });
      if (res) {
        const oldVal =
          questionArray.length > 0
            ? questionArray[0]?.res?.tag?.map((ss: { id: any }) => {
              return ss.id ?? ss;
            })
            : res?.tag?.map((ss: { id: any }) => {
              return ss.id ?? ss;
            }) ?? [];
        const tagInput = allInputs?.find(
          (item) => item.label === "Question Tags"
        );
        setSubmittedData({
          ...newSubmittedData,
          ...{
            res: res,
            ["Question Tags"]: oldVal
              ? tagInput?.list?.filter((item) => oldVal.includes(item.id))
              : [],
          },
        });
      } else {
        setSubmittedData({ ...newSubmittedData });
      }
    }
  };
  useEffect(() => {
    initialValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line
  const [fileInput_ref, setFileInputRef] = useState<any>({});
  const handleInputChange = (
    label: string,
    value: string | number | inputProp | null | boolean,
    id?: any
  ) => {
    setSubmittedData((prevDetails) => ({
      ...prevDetails,
      [label]: value,
      // [`${label}Id`]: id,
      // [`${label}-error`]: undefined,
    }));
  };
  const submit = async (type: string) => {
    const submitData: any = checkAllFields();
    let allData: any[] = []
    const newData = [...allSubmittedData]
    newData[value] = submittedData;
    console.log("alldata3", allSubmittedData, newData)
    setAllSubmittedData(newData);
    // allData = newData
    // .filter(data => Object.keys(data).length > 0)
    // .map((data, i) => {
    //   if(formatPayload) {
    //     if(Object.keys(data).length > 0) {
    //       return formatPayload(data, i == 0 ? "english" : "hindi")
    //     } else {
    //       return data
    //     }
    //   }
    // })
    allData = newData.filter(data => Object.keys(data).length > 0).map((data, i) => {
      if (formatPayload) {
        return formatPayload(data, i === 0 ? "english" : "hindi");
      }
      return data;
    });
    //  console.log(allData, allSubmittedData, submittedData)
    const response: any = await apiRequest({
      api: questionStatus == "unpublished" ? `cms/content/mock_test/question/update/${editData?.id}/`
        : editData || questionGettingEditted?.question?.res?.id
          ? `cms/content/mock_test/question/update/${editData?.id}/?status=${type}`
          : api.create(mockTestSectionID, type),
      payload:
        // editData || questionGettingEditted?.question?.res?.id
        //   ? mergePaylod(
        //     editData?.id ?? questionGettingEditted?.question?.res?.id ?? ""
        //   )
        //   :
        formatPayload
          ? allData
          : submitData,
      method:
        editData || questionGettingEditted?.question?.res?.id
          ? "PUT"
          : "POST",
    });
    if (response === undefined || (response && response.error)) {
      setHasError(true);
      await setSubmittedData((p) => {
        return { ...p, loader: false };
      });
    } else {
      // console.log(response)
      const newArr: any = questionArray.map((ss) => {
        return {
          ...ss,
          res: {
            ...ss.res,
            default_language: response.default_language,
            tag: response.tag ?? [],
          },
        };
      });

      if (
        newArr.find(
          (item: { res: { translation: { id: any } } }) =>
            item.res.translation?.id === response.translation?.id
        )
      ) {
        const index = newArr.findIndex(
          (item: { res: { translation: { id: any } } }) =>
            item.res.translation?.id === response.translation?.id
        );

        if (index !== -1) {
          const updatedItem = {
            ...newArr[index],
            res: response,
          }; // Replace the `res` object with the new value
          newArr.splice(index, 1, updatedItem); // Replace the old object with the updated one
        }
      } else {
        newArr.push({ res: response, form: submittedData });
      }
      setQuestion(newArr);
      await dispatch(
        showSnack({
          message: successMessage(response) ?? "",
          severity: "success",
          open: true,
        })
      );
      initialValue(response);
      setQuestionGettingEditted({});
      if (type) {
        if (selectedStep === 0 && firstStep) {
          setStepResponse(response);
          setStep(1);
        } else {
          await onClear([response]);
        }
      }
    }

    // already commeted
    // if (submitData !== null) {
    //   setSubmittedData((pre) => {
    //     return { ...pre, loader: true, clicked_button_type: type };
    //   });
    //   if (extraFields && extraFields.length > 0) {
    //     extraFields.map((sField: { label: string | number; value: any }) => {
    //       submitData[sField.label] = sField.value;
    //       return sField;
    //     });
    //   }
    //   const response: any = await apiRequest({
    //     api:
    //       editData || questionGettingEditted?.question?.res?.id
    //         ? `cms/content/question/update/`
    //         : api.create,
    //     payload:
    //       editData || questionGettingEditted?.question?.res?.id
    //         ? mergePaylod(
    //           editData?.id ?? questionGettingEditted?.question?.res?.id ?? ""
    //         )
    //         : formatPayload
    //           ? formatPayload(
    //             submitData,
    //             questionArray.length > 0
    //               ? questionArray[0].res.id
    //               : submittedData.res ?? null
    //           )
    //           : submitData,
    //     method:
    //       editData || questionGettingEditted?.question?.res?.id
    //         ? "PUT"
    //         : "POST",
    //   });
    //   if (response === undefined || (response && response.error)) {
    //     await setSubmittedData((p) => {
    //       return { ...p, loader: false };
    //     });
    //   } else {
    //     const newArr: any = questionArray.map((ss) => {
    //       return {
    //         ...ss,
    //         res: {
    //           ...ss.res,
    //           default_language: response.default_language,
    //           tag: response.tag ?? [],
    //         },
    //       };
    //     });

    //     if (
    //       newArr.find(
    //         (item: { res: { translation: { id: any } } }) =>
    //           item.res.translation.id === response.translation.id
    //       )
    //     ) {
    //       const index = newArr.findIndex(
    //         (item: { res: { translation: { id: any } } }) =>
    //           item.res.translation.id === response.translation.id
    //       );

    //       if (index !== -1) {
    //         const updatedItem = {
    //           ...newArr[index],
    //           res: response,
    //         }; // Replace the `res` object with the new value
    //         newArr.splice(index, 1, updatedItem); // Replace the old object with the updated one
    //       }
    //     } else {
    //       newArr.push({ res: response, form: submittedData });
    //     }
    //     setQuestion(newArr);
    //     await dispatch(
    //       showSnack({
    //         message: successMessage(response) ?? "",
    //         severity: "success",
    //         open: true,
    //       })
    //     );
    //     initialValue(response);
    //     setQuestionGettingEditted({});
    //     if (type) {
    //       if (selectedStep === 0 && firstStep) {
    //         setStepResponse(response);
    //         setStep(1);
    //       } else {
    //         await onClear([response]);
    //       }
    //     }
    //   }
    // }
  };
  const oneContentWidth = {
    maxWidth: "100%",
    width: "100%",
  };
  const fullWidthSx = { width: "100%" };
  const singleForm: any = (input: inputProp, i: number) => {
    const headingContent = subHeading({
      text: `${input.label} ${input.not_required === true ? "" : "*"}`,
    });
    const sx = input.fullWidth ? fullWidthSx : oneContentWidth;
    switch (input.fieldType) {
      case "image":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            <Box
              sx={{
                border: `1px solid ${globalColors.primary.grey}`, // mostLightGrey, // sets the border color to purple
                borderRadius: "10px",
                padding: "0.69px 0.69px 0.69px 12px",
                color: globalColors.primary.black,
                ...rowSB,
              }}
            >
              {" "}
              {subHeading({
                text:
                  submittedData && submittedData[input?.label] && submittedData[input?.label]?.file
                    ? `<img src="${submittedData[input.label]?.file
                    }" alt="image" style="width: 200px; height:200px; object-fit: contain"/>`
                    : "",
                parentSx: { flex: 8, width: "calc(100% - 160px)" },
                sx: {
                  whiteSpace: "nowrap",
                  width: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                },
              })}{" "}
              <input
                accept="image"
                ref={(ref) => {
                  setFileInputRef(ref);
                }}
                multiple
                id="files"
                style={{
                  visibility: "hidden",
                  width: "0",
                  maxHeight: "11px",
                }}
                type="file"
                onChange={(e: {
                  preventDefault: () => void;
                  stopPropagation: () => void;
                  target: { files: any };
                }) => {
                  e.preventDefault();
                  e.stopPropagation();

                  let fileObj = [];
                  const fileA: any[] = [];
                  fileObj.push(e.target.files);
                  for (let i = 0; i < fileObj[0].length; i++) {
                    fileA.push(fileObj[0][i]);
                  }
                  const fileUpload = async () => {
                    const res = await apiRequest({
                      form: true,
                      api: allApis.file_upload,
                      payload: {
                        parent_id: "",
                        // submittedData["Parent ClusterId"],
                        file: fileA[0],
                        created_by: userData?.id,
                        modified_by: userData?.id,
                        parent_model:
                          // content_list.find(
                          //   (item: { model: string }) =>
                          //     item.model === "question"
                          // )?.id ?? "",
                          ""
                      },
                    });

                    handleInputChange(input.label, res ?? fileA[0]);
                  };
                  fileUpload();
                }}
              />
              <Box sx={{ padding: "0 7px" }}>
                <CustomIconButton
                  content={copyIcon}
                  noHover={true}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `<img src="${submittedData[input.label].file
                      }" alt="image" style="width: 200px; height:200px; object-fit: contain"/>`
                    );

                    copy(
                      `<img src="${submittedData[input.label].file
                      }" alt="image" style="width: 200px; height:200px; object-fit: contain"/>`,
                      {
                        debug: true,
                        onCopy: () => {
                          dispatch(
                            showSnack({
                              message:
                                "Image copied, paste it wherever you want it.",
                              severity: "success",
                              open: true,
                            })
                          );
                        },
                      }
                    );
                  }}
                />
              </Box>
              <label htmlFor="files" className="btn">
                <Box
                  sx={{
                    backgroundColor: globalColors.primary.black, // You can customize the background color here
                    color: globalColors.primary.white,
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "0.875rem !important",
                    borderRadius: "8px",
                    lineHeight: "normal",
                    cursor: "pointer",
                    padding: "9px 10px",
                  }}
                >
                  Choose image
                </Box>
              </label>
            </Box>
          </Stack>
        );

      case "text":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            {renderTextField(input)}
          </Stack>
        );
      case "checkbox":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
              alignItems: "flex-end",
              justifyContent: "center",
              pt: "26px",
            }}
          >
            {/* {headingContent} */}
            {/* <Box sx={{minHeight:"38.86px",alignItems:"center",display:"flex"}}>
             <Checkbox checked={false}onChange={()=>{

          }}/></Box> */}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                margin: "0 10px 0 0 !important",
                alignItems: "center",
              }}
            >
              {subHeading({ text: input.label })}
              <FormControlLabel
                sx={{ margin: "0 0 0 0 !important" }}
                label=""
                control={
                  <Checkbox
                    sx={{
                      margin: "0 !important",
                      "&.MuiButtonBase-root": {
                        padding: "0",
                      },
                    }}
                    checked={submittedData["default_languge"] ? true : false}
                    onChange={(event: any) => {
                      handleInputChange(
                        "default_languge",
                        event.target.checked ? true : false
                      );
                    }}
                  />
                }
              />
            </Box>
          </Stack>
        );

      case "select":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            {renderDropDown(input)}
          </Stack>
        );
      case "date":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            <CustomDatePicker
              {...input}
              value={submittedData[input.label] ?? null}
              disabled={
                submittedData.loader === true ? true : input.disabled ?? false
              }
              onChange={(val: any) => {
                handleInputChange(input.label, val);
              }}
            />
          </Stack>
        );
      case "autocomplete":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            {input.multiple ? (
              <MultipleAutoComplte
                {...input}
                value={submittedData && submittedData[input.label] ? submittedData[input.label] : []}
                onChange={(val: any) => {
                  handleInputChange(input.label, val);
                }}
                disabled={
                  // questionArray.length > 0
                  //   ? true
                  //   :
                  submittedData && submittedData.loader === true ? true : input.disabled ?? false
                }
              />
            ) : (
              <CustomAutocomplete
                {...input}
                list={
                  input.list && input.list.length > 0
                    ? input.list.filter((li) => {
                      return !questionArray?.find(
                        ({ res }) =>
                          (res.translation.language ??
                            res.default_language) === li.id
                      );
                    })
                    : []
                }
                value={
                  input.list?.find(
                    (s) =>
                      s[input.listLabel ?? "label"] ===
                      submittedData[input.label]
                  ) ?? null
                }
                onChange={(val: any) => {
                  handleInputChange(
                    input.label,
                    val ? val[input.listLabel ?? "label"] : null,
                    val ? val.id : undefined
                  );
                }}
                disabled={
                  submittedData.loader === true ? true : input.disabled ?? false
                }
              />
            )}
          </Stack>
        );
      case "cktextEditor":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              marginBottom: "20.91px",
              ...sx,
              position: "relative",
            }}
          >
            {headingContent}
            {input.dataType === "option" && (
              <Box
                sx={{
                  position: "absolute",
                  width: "105px",
                  right: "15px",
                  top: "35px",
                  zIndex: "9",
                  display: "flex",
                  gap: "10px",
                  margin: "0 !important",
                  alignItems: "center",
                }}
              >
                {subHeading({ text: "Is Answer" })}
                <FormControlLabel
                  sx={{ margin: "0 0 0 0 !important" }}
                  label=""
                  control={
                    <Checkbox
                      sx={{
                        margin: "0 !important",
                        "&.MuiButtonBase-root": {
                          padding: "0",
                        },
                      }}
                      checked={submittedData &&
                        submittedData["correct_answer"] &&
                        submittedData["correct_answer"] !== null &&
                        submittedData["correct_answer"].label === input.label
                        ? true
                        : false
                      }
                      onChange={(event: any) => {
                        handleInputChange(
                          "correct_answer",
                          event.target.checked ? input : null
                        );
                      }}
                    />
                  }
                />
              </Box>
            )}
            <Box>
              <CustomCkeditor
                {...input}
                value={submittedData && submittedData[input.label] ? submittedData[input.label] : ""}
                onChange={(val: any) => handleInputChange(input.label, val)}
              />
            </Box>
          </Stack>
        );
      case "extra":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              marginBottom: "20.91px",
              ...sx,
              // height: "100px",
            }}
          ></Stack>
        );
      default:
        return <Grid item xs={singlexs} display="flex" key={"default"}></Grid>;
    }
  };
  const singlexs = 6;
  const allFormComp: any = (list: inputProp[], index: number) => {
    return list?.map((input: inputProp, i: number) => {
      return (
        <Grid
          item
          xs={input.fullWidth ? 12 : singlexs}
          display="flex"
          key={`${input.label}=${i}`}
          sx={
            input.fullWidth ? {} : { maxWidth: "calc(50% - 7.5px) !important" }
          }
          {...input?.grid}
        >

          {
            input.label == "Question Tags" && index > 0 ? <></> : singleForm(showQualityCheckBoxes ? { ...input, disabled: true } : input, i)
          }
        </Grid>
      );
    });
  };

  const insertStringAfterFirst3Chars = (input: string, insertString: string): string => {
    if (input?.length <= 3) {
      // If the input string is 3 characters or less, just append the insertString to the input
      return input + insertString;
    }

    // Insert the string after the first 3 characters
    const firstPart = input?.slice(0, 3);
    const secondPart = input?.slice(3);
    return firstPart + insertString + secondPart;
  }

  const getOptionsText = (data: string, option: string): string => {
    const optionText = insertStringAfterFirst3Chars(data, option);
    return optionText;
  }

  const enableDivider = (index: number): boolean => {
    if (index == 0 && value > 0) {
      const anyValue = submittedData && Object?.values(submittedData)?.some((val) => val !== null && val !== undefined && val !== "");
      return anyValue;
    }
    else if (index == 0 && value == 0) {
      const anyValue = allSubmittedData[1] && Object?.values(allSubmittedData[1])?.some((val) => val !== null && val !== undefined && val !== "");
      return anyValue;
    }
    return false;
  }

  const checkForMissingVal = (object: any): boolean => {
    let notAllFilled = false;
    for (const [key, value] of Object.entries(object).filter(([key, value]) => key!== "loader")) {
      const inputField = allInputs.find((a) => a.label == key)
      if (!inputField?.not_required) {
        if (value == null || value == undefined || value == "") {
          notAllFilled = true;
          break;
        }
      }
      if (key == "correct_answer") {
        if (value == null || value == undefined || value == "") {
          console.log("ans", key, value)
          notAllFilled = true;
          break;
        }
      }
    }
    return notAllFilled || !object.hasOwnProperty("correct_answer");
  }

  const isAllMandatoryFieldsFilled = (): boolean => {
    let isMissing: boolean[] = [false, false];

    allSubmittedData && allSubmittedData?.forEach((a, index) => {
      if (!a || Object.entries(a).length == 0) {
        isMissing[index] = true;
        return true;
      }
      isMissing[index] = checkForMissingVal(a);
    })

    const checkForCurrentTab = submittedData && checkForMissingVal(submittedData);
    if (checkForCurrentTab) {
      isMissing[value] = true;
    } else {
      const newData = [...allSubmittedData]
      newData[value] = submittedData;
      newData?.forEach((a) => {
        isMissing[value] = false;
        if (!a || Object?.entries(a)?.length == 0) {
          console.log("here4")
          isMissing[value] = true;
          return true;
        }
        isMissing[value] = checkForMissingVal(a);
      })
    }
    return isMissing.some((a) => a);
  }


  const allPassed = getQualityCheckData.every((check : any)  => check.quality_check_status === "pass");
  
const duplicacyCheckData = getQualityCheckData.find((check: any) => check.quality_check_type === 'duplicity_check');
const imageQualityCheckData = getQualityCheckData.find((check: any) => check.quality_check_type === 'image_quality_check');
const grammarSpellCheckData = getQualityCheckData.find((check: any) => check.quality_check_type === 'grammar_spell_check');
const correctnessCheckData = getQualityCheckData.find((check: any) => check.quality_check_type === 'question_correctness_check');
const clusterCheckData = getQualityCheckData.find((check: any) => check.quality_check_type === 'cluster_matching_check');
  return (
    <Stack
      spacing={"13px"}
      height="100%"
      justifyContent={"space-between"}
      overflow="hidden"
    >
      <Stack spacing={"20px"} sx={{ overflow: "hidden", flex: "8" }}>
        <Box
          sx={{
            ...rowSB,
            borderBottom: `1px solid ${globalColors.primary.borderColor}`,
            alignItems: "center",
            p: "0 10px 10px",
          }}
        >
          {inputSteps && inputSteps.length > 0 ? (
            <Box sx={{ ...displayRow, gap: "55px" }}>
              {inputSteps.map(({ label }, i: number) => {
                const activeStep = selectedStep === i;
                const pastStep = selectedStep > i;
                return (
                  <Box
                    sx={{
                      ...displayRow,
                      gap: "6px",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "50%",
                        height: "23px",
                        width: "23px",
                        border: pastStep
                          ? ""
                          : `2px solid ${activeStep
                            ? globalColors.primary.green
                            : "#CDCDCD"
                          }`,
                        ...rowCenter,
                        fontSize: "0.9rem",
                        fontWeight: 500,
                        background: pastStep ? globalColors.primary.green : "",
                      }}
                    >
                      {pastStep ? rightIcons : i}
                    </Box>
                    {headerContent({
                      size: "1.1rem",
                      text: label,
                      weight: "500",
                      sx: {
                        textTransform: "capitalize",
                        width: "auto",
                        color:
                          activeStep || pastStep
                            ? globalColors.primary.black
                            : globalColors.primary.normalText,
                      },
                      icons: [],
                    })}
                  </Box>
                );
              })}
            </Box>
          ) : (
            headerContent({
              size: "1.1rem",
              text: fromCluster
                ? "Add Question"
                : `${editData ? "Edit" : "Create a New Question"}`,
              weight: "500",
              sx: {
                color: globalColors.primary.black,
                textTransform: "capitalize",
                width: fromCluster ? "100%" : "auto",
              },
              icons: fromCluster
                ? [
                  {
                    content: <SvgPreview svg={cross} />,
                    onClick: () => {
                      onClear(questionArray)
                    },
                    sx: { padding: "0" },
                  },
                ]
                : [],
            })
          )}
        </Box>
        {/* {questionArray.map((item) => {
          const { res } = item;
          const isSelected =
            questionGettingEditted?.question?.res?.translation.id ===
            res?.translation.id;

          return (
            <Box
              sx={{
                border: `1px solid ${globalColors.primary.grey}`,
                borderRadius: "10px",
                padding: "10px 12px",
                color: globalColors.primary.black,
                ...rowSB,
                background: isSelected ? "rgb(0 0 0 / 3%)" : "",
                pointerEvents: isSelected ? "none" : "inherit",
              }}
            >
              <Box
                sx={{
                  flex: 8,
                  width: "calc(100% - 116px)",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "flex",
                }}
              >
                <Box sx={{ ...displayColumn, width: "120px" }}>
                  {subHeading({
                    size: "0.75rem",
                    sx: { color: globalColors.primary.normalText },
                    text: `Language ${res.explanation?.translation?.language ===
                      res.default_language
                      ? "(Default)"
                      : ""
                      }`,
                  })}
                  <Box>
                    {" "}
                    {subHeading({
                      size: "0.75rem",
                      sx: { color: globalColors.primary.black },
                      text:
                        res.explanation?.translation?.language ??
                        res.default_language,
                    })}
                  </Box>
                </Box>
                <Box sx={{ ...displayColumn, width: "calc(50% - 60px)" }}>
                  {subHeading({
                    size: "0.75rem",
                    sx: { color: globalColors.primary.normalText },
                    text: "Question",
                  })}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: res.translation?.text ?? "",
                    }}
                  />
                </Box>{" "}
                <Box sx={{ ...displayColumn, width: "calc(50% - 60px)" }}>
                  {subHeading({
                    size: "0.75rem",
                    sx: { color: globalColors.primary.normalText },
                    text: "Explanation",
                  })}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: res.explanation?.translation?.text ?? "",
                    }}
                  />
                </Box>
              </Box>

              <CustomIconButton
                content="Edit"
                noHover={true}
                className="linkStyle"
                onClick={() => {
                  // setSubmittedData(form);
                  // console.log("edit", item);
                  if (item.res.id) {
                    fomratQuestionData(item.res.id, item.res);
                  }
                  setQuestionGettingEditted({ question: item });
                }}
              />
            </Box>
          );
        })}
        {questionArray.length > 0 && (
          <Box
            className="dashed-border"
            sx={{ width: "100%", margin: "10px 0" }}
          ></Box>
        )} */}
        {selectedQuestionId ? 
<Typography style={{color : "#793ED6", textDecoration:"underline"}}>Edit Question</Typography> : <></>}
        <Stack
          direction={"row"}
          spacing={"10px"}
          sx={{ flex: "8", height: "100%", width: "100%" }}
        >
          <Box sx={{ width: '60%', bgcolor: 'background.paper' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="English" {...a11yProps(0)} />
                {language_available?.includes('hindi') && (
      <Tab label="Hindi" {...a11yProps(1)} />
    )}
              </Tabs>
            </Box>
            {
              [0, 1]?.map((index: number) => (
                <CustomTabPanel value={value} index={index}>
                  <div style={{ display: "flex", flexDirection: "column", height: "60vh" }}>
                    <Grid
                      container
                      key={"QuestionFormComponentMock"}
                      rowSpacing={1}
                      className="scrollBluePrimarythin"
                      columnGap={oldSubmittedData ? "15px" : ""}
                      sx={{ gap: "0 15px", overflowY: "scroll", paddingRight: "10px", flexGrow: "1" }}
                    >
                      {allFormComp(allInputs, index)}
                      {!showQualityCheckBoxes ? <></> :
                      (!qualityChecksLoading && !isDuplicacyClicked) ? 
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
                      <CircularProgress size={24} style={{ marginRight: '8px' }} />
                      <Typography variant="body2" color="text.secondary">loading quality checks...</Typography>
                    </div> :
                        <>
                          <Grid xs={12} sx={{ paddingBottom: "40px" }}>
                            <Divider orientation="horizontal" variant="middle" flexItem style={{ marginBottom: "20px" }} />

                            <Stack spacing={3} sx={{ padding: "20px 30px 20px 30px", border: "3px dotted grey", borderRadius: "10px", width: "60%" }}>
                              <Stack style={{ fontSize: "20px" }}>A. Basic Quality Check</Stack>
                              <DuplicateQualityCheckBoxes onButtonClick={handleButtonClick} questionArray={questionArray} onClear = {onClear} header={"1. Duplicate Check"} type ="duplicity_check" initialData = {duplicacyCheckData} id={editData?.id} />
                              <CorrectnessQualityCheckBoxes onButtonClick={handleButtonClick} header={"2. Correctness & Relevancy Check"} type ="question_correctness_check" initialData = {correctnessCheckData} id={editData?.id}/>
                              <GrammarQualityCheckBoxes onButtonClick={handleButtonClick} header={"3. Grammar & Spell Check"} type ="grammar_spell_check" initialData = {grammarSpellCheckData} id={editData?.id}/>
                              <ImageQualityCheckBoxes onButtonClick={handleButtonClick} header={"4. Image Quality"} type ="image_check" initialData = {imageQualityCheckData} id={editData?.id} />
                            </Stack>
                          </Grid>
                          <Grid xs={12} sx={{ paddingBottom: "40px" }}>
                            <Stack spacing={3} sx={{ padding: "20px 30px 20px 30px", border: "3px dotted grey", borderRadius: "10px", width: "60%" }}>
                              <Stack style={{ fontSize: "20px" }}>B. EARS ++</Stack>
                              <ClusterQualityCheckBoxes onButtonClick={handleButtonClick} header={"1. Cluster Match"} type ="cluster_matching_check" initialData = {clusterCheckData} id={editData?.id}/>
                            </Stack>
                          </Grid>
                        </>
                      }
                    </Grid>
                    <Box sx={{ width: "100%", ...rowSB }}>
                      <CustomIconButton
                        key="cancel"
                        variant="outlined"
                        content="cancel"
                        sx={{ minWidth: "180px" }}
                        onClick={() => {
                          onClear(questionArray);
                        }}
                      />
                      <Box>
                      {
                          questionStatus == "ready" &&
                          <CustomContainedButton
                            key="Save As Draft"
                            otherProps={{
                              disabled: isAllMandatoryFieldsFilled(),
                              onClick: () => {
                                submit("ready");
                              },
                              sx: { minWidth: "180px" },
                            }}
                            content={"save"
                            }
                          />
                        }
                        {" "}
                        {
                          questionStatus != "unpublished" &&
                          <CustomContainedButton
                            key="Save As Draft"
                            otherProps={{
                              disabled: submittedData && Object.values(submittedData).every(c => c == undefined || c == null || c == ''),
                              onClick: () => {
                                showQualityCheckBoxes ? submit("draft") : submit("draft");
                              },
                              sx: { minWidth: "180px" },
                            }}
                            content={showQualityCheckBoxes ? "Move To Draft" :
                              "Save As Draft"
                            }
                          />
                        }
                        {" "}
                        <CustomContainedButton
                          otherProps={{
                            disabled: (!allPassed || isAllMandatoryFieldsFilled() || !qualityChecksLoading),
                            // checkAllFields() === null
                            //   ? true
                            //   : submittedData.loader ?? false,
                            onClick: () => {
                              questionStatus == "unpublished" ? submit("") : showQualityCheckBoxes ? submit("approved") : submit("ready");

                            },
                            sx: { minWidth: "180px" },
                          }}
                          loading={
                            submittedData && submittedData.loader && submittedData.clicked_button_type
                              ? submittedData.loader
                              : undefined
                          }
                          content={
                            showQualityCheckBoxes ? "Mark As Approved"
                              : questionStatus == "unpublished" ? "Save and Exit" : "Mark As Ready"
                          }
                        />
                      </Box>
                    </Box>
                  </div>
                </CustomTabPanel>
              ))
            }

          </Box>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Grid
            container
            width="40%"
            key={"QuestionFormComponentMock"}
            rowSpacing={1}
            columnGap={oldSubmittedData ? "15px" : ""}
            className="scrollBluePrimarythin"
            style={{ padding: "20px 20px 20px 10px" }}
            sx={{ gap: "0 15px", maxHeight: "70vh", overflowY: "scroll", paddingRight: "10px" }}>
            <Paper elevation={5} square={false} style={{ width: "100%" }}>
              <div style={{ fontSize: "17px", fontWeight: "bold", textAlign: "center", paddingTop: "10px" }}>Question Preview</div>
              {
                allSubmittedData?.map((data, index) => (
                  <>
                    {
                      index == value ?
                        <Stack style={{ padding: "20px 30px 0px 30px", }}>
                         
                          <PreviewComponent htmlContent={submittedData["Question"]} />
                          {/* <div dangerouslySetInnerHTML={{ __html: submittedData["Question"] }} style={{ paddingTop: "15px", fontSize: "15px" }}></div> */}
                          <div style={{ paddingTop: "15px" }}>
                            {(submittedData["Option A"]?.length > 0 ||
                              submittedData["Option B"]?.length > 0 ||
                              submittedData["Option C"]?.length > 0 ||
                              submittedData["Option D"]?.length > 0) &&
                              <>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "15px", fontWeight: "100", border: "1px solid grey", borderRadius: "5px", padding: "10px" }}>
                                <PreviewComponent htmlContent={ getOptionsText(submittedData["Option A"], "A. ")} />
                                  {/* <div dangerouslySetInnerHTML={{ __html: getOptionsText(submittedData["Option A"], "A. ") }}></div> */}
                                  {
                                    submittedData["correct_answer"]?.label == "Option A" && <CheckCircleIcon style={{ color: "green" }} />
                                  }
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "15px", fontWeight: "100", border: "1px solid grey", borderRadius: "5px", padding: "10px", marginTop: "15px" }}>
                                <PreviewComponent htmlContent={ getOptionsText(submittedData["Option B"], "B. ")} />
                                  {
                                    submittedData["correct_answer"]?.label == "Option B" && <CheckCircleIcon style={{ color: "green" }} />
                                  }
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "15px", fontWeight: "100", border: "1px solid grey", borderRadius: "5px", padding: "10px", marginTop: "15px" }}>
                                <PreviewComponent htmlContent={ getOptionsText(submittedData["Option C"], "C. ")} />
                                  {
                                    submittedData["correct_answer"]?.label == "Option C" && <CheckCircleIcon style={{ color: "green" }} />
                                  }
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "15px", fontWeight: "100", border: "1px solid grey", borderRadius: "5px", padding: "10px", marginTop: "15px", marginBottom: "15px" }}>
                                <PreviewComponent htmlContent={ getOptionsText(submittedData["Option D"], "D. ")} />
                                  {
                                    submittedData["correct_answer"]?.label == "Option D" && <CheckCircleIcon style={{ color: "green" }} />
                                  }
                                </div>
                              </>
                            }
                            {submittedData["Explanation"]?.length > 0 && <div style={{ marginTop: "10px" }}>
                              <b>Explanation</b>
                              <div style={{ paddingTop: "15px", fontSize: "15px" }}>
                              <PreviewComponent htmlContent={submittedData["Explanation"]} />
                                </div>
                              {/* <div dangerouslySetInnerHTML={{ __html: submittedData["Explanation"] }} style={{ paddingTop: "15px", fontSize: "15px" }}></div> */}
                            </div>
                            }
                            {
                              submittedData["Question Tags"]?.length > 0 && <div style={{ marginTop: "10px" }}>
                                <b>Question Tags</b>
                                <ul style={{ paddingTop: "5px" }}>
                                  {
                                    submittedData["Question Tags"]?.map((d: any) => (
                                      <Chip
                                        sx={{
                                          margin: "5px",
                                          background: "#E3F4E7",
                                          maxWidth: "calc(100% - 10px)",
                                          "& .MuiChip-label": {
                                            fontSize: "0.69srem",
                                            lineHeight: "1rem",
                                          },
                                        }} label={d.name} variant="outlined" />
                                    ))
                                  }
                                </ul>
                              </div>
                            }
                          </div>
                        </Stack > :
                        <Stack style={{ padding: "20px 30px 0px 30px", }}>
                       { data["Question"] && <PreviewComponent htmlContent={data["Question"]} />}
                          {/* <div dangerouslySetInnerHTML={{ __html: data["Question"] }} style={{ paddingTop: "15px", fontSize: "15px" }}></div> */}
                          <div style={{ paddingTop: "15px" }}>
                            {(data["Option A"]?.length > 0 ||
                              data["Option B"]?.length > 0 ||
                              data["Option C"]?.length > 0 ||
                              data["Option D"]?.length > 0) &&
                              <>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "15px", fontWeight: "100", border: "1px solid grey", borderRadius: "5px", padding: "10px" }}>
                                  
                                <PreviewComponent htmlContent={ getOptionsText(data["Option A"], "A. ")} />
                                {/* <div dangerouslySetInnerHTML={{ __html: getOptionsText(data["Option A"], "A. ") }}></div> */}
                                  {
                                    data["correct_answer"]?.label == "Option A" && <CheckCircleIcon style={{ color: "green" }} />
                                  }
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "15px", fontWeight: "100", border: "1px solid grey", borderRadius: "5px", padding: "10px", marginTop: "15px" }}>
                                <PreviewComponent htmlContent={ getOptionsText(data["Option B"], "B. ")} />
                                  {
                                    data["correct_answer"]?.label == "Option B" && <CheckCircleIcon style={{ color: "green" }} />
                                  }
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "15px", fontWeight: "100", border: "1px solid grey", borderRadius: "5px", padding: "10px", marginTop: "15px" }}>
                                <PreviewComponent htmlContent={ getOptionsText(data["Option C"], "C. ")} />
                                  {
                                    data["correct_answer"]?.label == "Option C" && <CheckCircleIcon style={{ color: "green" }} />
                                  }
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "15px", fontWeight: "100", border: "1px solid grey", borderRadius: "5px", padding: "10px", marginTop: "15px", marginBottom: "15px" }}>
                                <PreviewComponent htmlContent={ getOptionsText(data["Option D"], "D. ")} />
                                  {
                                    data["correct_answer"]?.label == "Option D" && <CheckCircleIcon style={{ color: "green" }} />
                                  }
                                </div>
                              </>
                            }
                            {data["Explanation"]?.length > 0 && <div style={{ marginTop: "10px" }}>
                              <b>Explanation</b>
                             <div style={{ paddingTop: "15px", fontSize: "15px" }}>
                              <PreviewComponent htmlContent={data["Explanation"]} />
                                </div>
                            </div>
                            }
                            {
                              data["Question Tags"]?.length > 0 && <div style={{ marginTop: "10px" }}>
                                <b>Question Tags</b>
                                <ul style={{ paddingTop: "5px" }}>
                                  {
                                    data["Question Tags"]?.map((d: any) => (
                                      <Chip
                                        sx={{
                                          margin: "5px",
                                          background: "#E3F4E7",
                                          maxWidth: "calc(100% - 10px)",
                                          "& .MuiChip-label": {
                                            fontSize: "0.69srem",
                                            lineHeight: "1rem",
                                          },
                                        }} label={d.name} variant="outlined" />
                                    ))
                                  }
                                </ul>
                              </div>
                            }
                          </div>
                        </Stack >
                    }
                    {
                      enableDivider(index) && enableDivider(index)
                        ? <Divider orientation="horizontal" variant="middle" flexItem style={{ marginTop: "10px" }} /> : <></>
                    }
                  </>

                ))
              }
            </Paper>
          </Grid>
        </Stack>
      </Stack>
    </Stack >
  );
}

export default QuestionFormComponentMock;
