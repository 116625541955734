import { useEffect, useState } from "react";
import CustomIconButton from "../CustomIconButton";
import { Box, Drawer } from "@mui/material";
import { ObjectValues, inputProp } from "../../pages/login";
import { copyIcon } from "../../assets/svgs";
import FormComponent, { formCompProps } from "../FormComponent";

function CopyComponent({
  formProps,
  open,
  row,
  onClose,
}: {
  formProps: formCompProps;
  open?: true;
  row?: ObjectValues;
  onClose?(): void;
}) {
  const [dialog, setDialog] = useState<ObjectValues>({});
  useEffect(() => {
    if (open) {
      setDialog({ open: true });
    }
  }, [open]);
  const getData = () => {
    const newSubmittedData: any = {};
    formProps.inputFormArray.map((singleInput: inputProp) => {
      if (!singleInput.defaultValue && !newSubmittedData[singleInput.label]) {
        if (
          row &&
          singleInput.api &&
          (row[singleInput.api] || row[singleInput.api] === false)
        ) {
          newSubmittedData[singleInput.label] = row[singleInput.api];
          newSubmittedData[`${singleInput.label}Id`] = row[`${singleInput.api}`]
            ? row[`${singleInput.api}`]
            : "";

          return newSubmittedData;
        } else {
          return (newSubmittedData[singleInput.label] = "");
        }
      } else if (singleInput.defaultValue) {
        newSubmittedData[singleInput.label] = singleInput.defaultValue.value;
        newSubmittedData[`${singleInput.label}Id`] =
          singleInput.defaultValue.id ?? "";

        return newSubmittedData;
      }
      return singleInput;
    });
    return newSubmittedData;
  };
  const drawer = (
    <Drawer
      transitionDuration={500}
      anchor={"right"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "10px 0 0 10px",
          width: "50%",
          //   backgroundColor: "transparent !important",
          //   width: "fit-content",
          //   maxWidth: "fit-content",
          //   boxShadow: "none",
        },
      }}
      open={dialog.open}
      onClose={() => {
        setDialog({});
        if (onClose) {
          onClose();
        }
      }}
    >
      {" "}
      {/* <Slide
  direction={dialog.open ? "left" : "right"}
  in={dialog.open}
  mountOnEnter
  unmountOnExit
> */}
      <Box
        sx={{
          borderRadius: "10px 0 0 10px",
          padding: "20px 12px",
          width: "calc(100% - 24px)",
          height: "100%",
          // backgroundColor: "white",
          // boxShadow:
          //   "0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)",
        }}
      >
        <FormComponent
          {...formProps}
          oldSubmittedData={formProps.oldSubmittedData ?? getData()}
          editData={undefined}
          onClear={() => {
            setDialog({});
            if (onClose) {
              onClose();
            }
          }}
        />
      </Box>
      {/* </Slide> */}
    </Drawer>
  );
  return open ? (
    drawer
  ) : (
    <Box>
      {dialog.open && drawer}
      <CustomIconButton
        content="Copy and Edit"
        icon={copyIcon}
        variant="outlined"
        // sx={{ padding: "4px 15px" }}
        onClick={() => {
          setDialog({ open: true });
        }}
      />
    </Box>
  );
}

export default CopyComponent;
