import { Box, Stack, Typography } from "@mui/material";
import globalColors from "../../globalColors";
import { rowVerticalCenter, rowSB,displayRow } from "../../GlobalStyles";
import { actionIcon } from "../CustomDialog";
import CustomIconButton from "../CustomIconButton";
import { ObjectValues } from "../../pages/login";

export const paragraphs = ({ text ,sx,size,icon,line,color}: any) => (
  <Box sx={{...displayRow,gap:"5px"}}>
    <Typography
      fontStyle={"normal"}
      fontWeight={400}
      fontSize={size??"0.8rem"}
      lineHeight={line??"21px"}
      color={color??globalColors.primary.normalText}
      sx={sx}
    >
      {text}
    </Typography>
    {icon??""}
  </Box>
);
export const iconText = ({
  text,
  icon,
  sx,
  size,
  weight,
  onClick,
  textsx,
  classname,
  iconsx,
}: any) => (
  <Box sx={{ ...rowVerticalCenter, ...sx }} onClick={onClick} key={text}>
    <Box sx={{ ...rowVerticalCenter, ...iconsx }}>{icon ?? ""}</Box>
    {text && (
      <Typography
        ml={icon && "5px"}
        fontStyle={"normal"}
        fontWeight={weight ?? 400}
        fontSize={size ?? "0.9rem"}
        lineHeight={"0.9rem"}
        color={"currentColor"}
        textTransform={
          text && text.toString().includes(" of ") ? "inherit" : "capitalize"
        }
        sx={{ ...textsx }}
        className={classname}
      >
        {text}
      </Typography>
    )}
  </Box>
);
export const heading = ({ text, size, weight }: any) => (
  <Box sx={{ ...rowVerticalCenter }}>
    <Typography
      fontStyle={"normal"}
      fontWeight={weight ?? 600}
      fontSize={size ?? "2.1rem"}
      color={"currentcolor"}
    >
      {text}
    </Typography>
  </Box>
);
export const subHeading = ({ text, size, weight, sx,parentSx }: any) => (
  <Box sx={parentSx??{}}>
    <Typography
      fontStyle={"normal"}
      fontWeight={weight ?? 500}
      fontSize={size ?? "0.9rem"}
      color={"currentColor"}
      sx={sx ?? {}}
    >
      {text}
    </Typography>
  </Box>
);
export const headerContent = ({
  text,
  size,
  weight,
  icons,
  sx,
}: {
  weight?: string;
  size?: string;
  text: string;
  icons: actionIcon[];
  sx?: ObjectValues;
}) => {
  return (
    <Stack sx={{ m: 0, ...rowSB, ...sx }}>
      {" "}
      <Box sx={{ flex: "8" }}>
        {subHeading({
          text: text ?? "",
          size: size ?? "1.1rem",
          weight: weight ?? "600",
        })}
      </Box>
      <Box>
        {icons &&
          icons.length > 0 &&
          icons.map((icon) => (
            <CustomIconButton
              sx={{ p: "8px 10px" }}
              {...icon}
              key={icon.content}
            />
          ))}
      </Box>
    </Stack>
  );
};
