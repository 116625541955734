import { Box, Stack } from "@mui/material";
import  { useEffect, useState } from "react";
import { rowSB } from "../../../GlobalStyles";
import {  useAppSelecter } from "../../../hooks/storeHooks";
import { ObjectValues } from "../../login";
import CustomIconButton from "../../../Components/CustomIconButton";
import SvgPreview from "../../../Components/SvgPreview";
import { cross } from "../../../assets/svgs";

function QuestionLanguages({ question,fetchSingleQuestionDetails ,handleClose}: any) {
  const allLanguages = question.translation
    ? question.translation.map((l: { language: any }) => l.language)
    : [];
  const [select_language, set_language] = useState<string>(
    question?.default_language ?? ""
  );
  const colorAR = ["#FAEEDD", "#FFECF8", "#EAFAFC", "#E3F4E7", "#F2ECFB"];
  const editExamData = useAppSelecter(
    (state: ObjectValues) => state.exams.single_questions
  );
  // const dispatch = useAppDispatch();
  useEffect(() => {
    if (question?.id) {
      if (editExamData && editExamData[question?.id]) {
      } else if (question?.id) {
        if(fetchSingleQuestionDetails){
          fetchSingleQuestionDetails(question?.id)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question?.id]);
  const all_options = editExamData[question?.id]?.options ?? [];
  return (
    <Stack spacing={"10px"} overflow={"auto"} maxHeight="400px" minHeight={"350px"} minWidth={"400px"}>
      <Stack direction="row" spacing="10px" position={"relative"} padding={"0 50px 0 0"}>
        {allLanguages.map((text: any, i: number) => (
          <Box
            key={text}
            sx={{
              padding: "0 7px",
              background: colorAR[i] ?? "",
              maxWidth: "fit-content",
              cursor: "pointer",
            }}
            onClick={() => {
              set_language(text);
            }}
          >
            {text}
          </Box>
        ))}
         <CustomIconButton
        onClick={handleClose}
        noHover={true}
          sx={{
          position:'absolute',
          right:"3px"
            
          }}
          content={<SvgPreview svg={cross} />}
        />
      </Stack>
      <Stack overflow={"auto"} flex={8}>
        <Box>
          <div 
              style={{wordBreak:"break-all"}}
            dangerouslySetInnerHTML={{
              __html:
                Array.isArray(question.translation) &&
                question.translation.length > 0
                  ? question.translation?.find(
                      (item: { language: any }) =>
                        item.language === select_language
                    )?.text ?? ""
                  : question?.translation?.text ?? "",
            }}
          />
        </Box>

        <Box sx={{ ...rowSB, width: "100%" }}>
          <Box sx={{ width: "50%" }}>
            <Box sx={{ mr: "10px" }}>A</Box>

            {all_options[0] ? (
              <div
              style={{wordBreak:"break-all"}}
                dangerouslySetInnerHTML={{
                  __html:
                    Array.isArray(all_options[0].translation) &&
                    all_options[0].translation.length > 0
                      ? all_options[0].translation?.find(
                          (item: { language: any }) =>
                            item.language === select_language
                        )?.text ?? ""
                      : all_options[0]?.translation?.text ?? "",
                }}
              />
            ) : (
              ""
            )}
          </Box>
          <Box sx={{ width: "50%" }}>
            <Box sx={{ mr: "10px" }}>B</Box>
            {all_options[1] ? (
              <div 
              style={{wordBreak:"break-all"}}
                dangerouslySetInnerHTML={{
                  __html:
                    Array.isArray(all_options[1].translation) &&
                    all_options[1].translation.length > 0
                      ? all_options[1].translation?.find(
                          (item: { language: any }) =>
                            item.language === select_language
                        )?.text ?? ""
                      : all_options[1]?.translation?.text ?? "",
                }}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>

        <Box sx={{ ...rowSB, width: "100%" }}>
          <Box sx={{ width: "50%" }}>
            <Box sx={{ mr: "10px" }}>C</Box>
            {all_options[2] ? (
              <div 
              style={{wordBreak:"break-all"}}
                dangerouslySetInnerHTML={{
                  __html:
                    Array.isArray(all_options[2].translation) &&
                    all_options[2].translation.length > 0
                      ? all_options[2].translation?.find(
                          (item: { language: any }) =>
                            item.language === select_language
                        )?.text ?? ""
                      : all_options[2]?.translation?.text ?? "",
                }}
              />
            ) : (
              ""
            )}
          </Box>
          <Box sx={{ width: "50%" }}>
            <Box sx={{ mr: "10px" }}>D</Box>
            {all_options[3] ? (
              <div 
              style={{wordBreak:"break-all"}}
                dangerouslySetInnerHTML={{
                  __html:
                    Array.isArray(all_options[3].translation) &&
                    all_options[3].translation.length > 0
                      ? all_options[3].translation?.find(
                          (item: { language: any }) =>
                            item.language === select_language
                        )?.text ?? ""
                      : all_options[3]?.translation?.text ?? "",
                }}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}

export default QuestionLanguages;
