

export const Npcbg=(second?:boolean)=> (
<svg
  // width={"1512"}
  height={"100%"}
  viewBox="0 0 1464 762"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  preserveAspectRatio="none"
>
  {!second&&
  <g opacity="0.2">
    <path
      d="M0.692261 7.56863V3.69221C0.692261 2.03536 2.03579 0.686644 3.6924 0.658438C10.3315 0.545394 15.9864 0.453966 21.1153 5.88453C26.2419 13.0048 28.5565 14.9697 32.4936 15.6352C34.0598 15.9 35.3076 17.1633 35.3076 18.7517V23.0739C35.3076 24.5404 34.2417 25.8295 32.7753 25.8084C25.7439 25.7072 20.5523 20.3043 18.6923 17.3076C13.4913 10.9509 9.01389 10.3826 3.85078 10.5697C2.1331 10.632 0.692261 9.28743 0.692261 7.56863Z"
      fill="black"
      stroke="black"
    />
    <circle cx="30.4614" cy="5.53846" r="5.53846" fill="black" />
    <circle cx="5.53846" cy="20.7692" r="5.53846" fill="black" />
  </g>}
  {!second&& <g opacity="0.2">
    <path
      d="M0.692261 567.569V563.692C0.692261 562.035 2.03579 560.687 3.6924 560.658C10.3315 560.545 15.9864 560.454 21.1153 565.885C26.2419 573.005 28.5565 574.97 32.4936 575.635C34.0598 575.9 35.3076 577.163 35.3076 578.752V583.074C35.3076 584.54 34.2417 585.829 32.7753 585.808C25.7439 585.707 20.5523 580.304 18.6923 577.308C13.4913 570.951 9.01389 570.383 3.85078 570.57C2.1331 570.632 0.692261 569.287 0.692261 567.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="30.4614" cy="565.538" r="5.53846" fill="black" />
    <circle cx="5.53846" cy="580.769" r="5.53846" fill="black" />
  </g>}
   {!second&& <g opacity="0.2">
    <path
      d="M0.692261 285.569V281.692C0.692261 280.035 2.03579 278.687 3.6924 278.658C10.3315 278.545 15.9864 278.454 21.1153 283.885C26.2419 291.005 28.5565 292.97 32.4936 293.635C34.0598 293.9 35.3076 295.163 35.3076 296.752V301.074C35.3076 302.54 34.2417 303.829 32.7753 303.808C25.7439 303.707 20.5523 298.304 18.6923 295.308C13.4913 288.951 9.01389 288.383 3.85078 288.57C2.1331 288.632 0.692261 287.287 0.692261 285.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="30.4614" cy="283.538" r="5.53846" fill="black" />
    <circle cx="5.53846" cy="298.769" r="5.53846" fill="black" />
  </g>}
  <g opacity="0.2">
    <path
      d="M238.692 7.56863V3.69221C238.692 2.03536 240.036 0.686644 241.692 0.658438C248.332 0.545394 253.986 0.453966 259.115 5.88453C264.242 13.0048 266.556 14.9697 270.494 15.6352C272.06 15.9 273.308 17.1633 273.308 18.7517V23.0739C273.308 24.5404 272.242 25.8295 270.775 25.8084C263.744 25.7072 258.552 20.3043 256.692 17.3076C251.491 10.9509 247.014 10.3826 241.851 10.5697C240.133 10.632 238.692 9.28743 238.692 7.56863Z"
      fill="black"
      stroke="black"
    />
    <circle cx="268.461" cy="5.53846" r="5.53846" fill="black" />
    <circle cx="243.538" cy="20.7692" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M238.692 567.569V563.692C238.692 562.035 240.036 560.687 241.692 560.658C248.332 560.545 253.986 560.454 259.115 565.885C264.242 573.005 266.556 574.97 270.494 575.635C272.06 575.9 273.308 577.163 273.308 578.752V583.074C273.308 584.54 272.242 585.829 270.775 585.808C263.744 585.707 258.552 580.304 256.692 577.308C251.491 570.951 247.014 570.383 241.851 570.57C240.133 570.632 238.692 569.287 238.692 567.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="268.461" cy="565.538" r="5.53846" fill="black" />
    <circle cx="243.538" cy="580.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M238.692 285.569V281.692C238.692 280.035 240.036 278.687 241.692 278.658C248.332 278.545 253.986 278.454 259.115 283.885C264.242 291.005 266.556 292.97 270.494 293.635C272.06 293.9 273.308 295.163 273.308 296.752V301.074C273.308 302.54 272.242 303.829 270.775 303.808C263.744 303.707 258.552 298.304 256.692 295.308C251.491 288.951 247.014 288.383 241.851 288.57C240.133 288.632 238.692 287.287 238.692 285.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="268.461" cy="283.538" r="5.53846" fill="black" />
    <circle cx="243.538" cy="298.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M135.692 146.569V142.692C135.692 141.035 137.036 139.687 138.692 139.658C145.332 139.545 150.986 139.454 156.115 144.885C161.242 152.005 163.556 153.97 167.494 154.635C169.06 154.9 170.308 156.163 170.308 157.752V162.074C170.308 163.54 169.242 164.829 167.775 164.808C160.744 164.707 155.552 159.304 153.692 156.308C148.491 149.951 144.014 149.383 138.851 149.57C137.133 149.632 135.692 148.287 135.692 146.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="165.461" cy="144.538" r="5.53846" fill="black" />
    <circle cx="140.538" cy="159.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M135.692 424.569V420.692C135.692 419.035 137.036 417.687 138.692 417.658C145.332 417.545 150.986 417.454 156.115 422.885C161.242 430.005 163.556 431.97 167.494 432.635C169.06 432.9 170.308 434.163 170.308 435.752V440.074C170.308 441.54 169.242 442.829 167.775 442.808C160.744 442.707 155.552 437.304 153.692 434.308C148.491 427.951 144.014 427.383 138.851 427.57C137.133 427.632 135.692 426.287 135.692 424.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="165.461" cy="422.538" r="5.53846" fill="black" />
    <circle cx="140.538" cy="437.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M135.692 742.569V738.692C135.692 737.035 137.036 735.687 138.692 735.658C145.332 735.545 150.986 735.454 156.115 740.885C161.242 748.005 163.556 749.97 167.494 750.635C169.06 750.9 170.308 752.163 170.308 753.752V758.074C170.308 759.54 169.242 760.829 167.775 760.808C160.744 760.707 155.552 755.304 153.692 752.308C148.491 745.951 144.014 745.383 138.851 745.57C137.133 745.632 135.692 744.287 135.692 742.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="165.461" cy="740.538" r="5.53846" fill="black" />
    <circle cx="140.538" cy="755.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M476.692 7.56863V3.69221C476.692 2.03536 478.036 0.686644 479.692 0.658438C486.332 0.545394 491.986 0.453966 497.115 5.88453C502.242 13.0048 504.556 14.9697 508.494 15.6352C510.06 15.9 511.308 17.1633 511.308 18.7517V23.0739C511.308 24.5404 510.242 25.8295 508.775 25.8084C501.744 25.7072 496.552 20.3043 494.692 17.3076C489.491 10.9509 485.014 10.3826 479.851 10.5697C478.133 10.632 476.692 9.28743 476.692 7.56863Z"
      fill="black"
      stroke="black"
    />
    <circle cx="506.461" cy="5.53846" r="5.53846" fill="black" />
    <circle cx="481.538" cy="20.7692" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M476.692 567.569V563.692C476.692 562.035 478.036 560.687 479.692 560.658C486.332 560.545 491.986 560.454 497.115 565.885C502.242 573.005 504.556 574.97 508.494 575.635C510.06 575.9 511.308 577.163 511.308 578.752V583.074C511.308 584.54 510.242 585.829 508.775 585.808C501.744 585.707 496.552 580.304 494.692 577.308C489.491 570.951 485.014 570.383 479.851 570.57C478.133 570.632 476.692 569.287 476.692 567.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="506.461" cy="565.538" r="5.53846" fill="black" />
    <circle cx="481.538" cy="580.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M476.692 285.569V281.692C476.692 280.035 478.036 278.687 479.692 278.658C486.332 278.545 491.986 278.454 497.115 283.885C502.242 291.005 504.556 292.97 508.494 293.635C510.06 293.9 511.308 295.163 511.308 296.752V301.074C511.308 302.54 510.242 303.829 508.775 303.808C501.744 303.707 496.552 298.304 494.692 295.308C489.491 288.951 485.014 288.383 479.851 288.57C478.133 288.632 476.692 287.287 476.692 285.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="506.461" cy="283.538" r="5.53846" fill="black" />
    <circle cx="481.538" cy="298.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M373.692 146.569V142.692C373.692 141.035 375.036 139.687 376.692 139.658C383.332 139.545 388.986 139.454 394.115 144.885C399.242 152.005 401.556 153.97 405.494 154.635C407.06 154.9 408.308 156.163 408.308 157.752V162.074C408.308 163.54 407.242 164.829 405.775 164.808C398.744 164.707 393.552 159.304 391.692 156.308C386.491 149.951 382.014 149.383 376.851 149.57C375.133 149.632 373.692 148.287 373.692 146.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="403.461" cy="144.538" r="5.53846" fill="black" />
    <circle cx="378.538" cy="159.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M373.692 424.569V420.692C373.692 419.035 375.036 417.687 376.692 417.658C383.332 417.545 388.986 417.454 394.115 422.885C399.242 430.005 401.556 431.97 405.494 432.635C407.06 432.9 408.308 434.163 408.308 435.752V440.074C408.308 441.54 407.242 442.829 405.775 442.808C398.744 442.707 393.552 437.304 391.692 434.308C386.491 427.951 382.014 427.383 376.851 427.57C375.133 427.632 373.692 426.287 373.692 424.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="403.461" cy="422.538" r="5.53846" fill="black" />
    <circle cx="378.538" cy="437.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M373.692 742.569V738.692C373.692 737.035 375.036 735.687 376.692 735.658C383.332 735.545 388.986 735.454 394.115 740.885C399.242 748.005 401.556 749.97 405.494 750.635C407.06 750.9 408.308 752.163 408.308 753.752V758.074C408.308 759.54 407.242 760.829 405.775 760.808C398.744 760.707 393.552 755.304 391.692 752.308C386.491 745.951 382.014 745.383 376.851 745.57C375.133 745.632 373.692 744.287 373.692 742.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="403.461" cy="740.538" r="5.53846" fill="black" />
    <circle cx="378.538" cy="755.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M714.692 7.56863V3.69221C714.692 2.03536 716.036 0.686644 717.692 0.658438C724.332 0.545394 729.986 0.453966 735.115 5.88453C740.242 13.0048 742.556 14.9697 746.494 15.6352C748.06 15.9 749.308 17.1633 749.308 18.7517V23.0739C749.308 24.5404 748.242 25.8295 746.775 25.8084C739.744 25.7072 734.552 20.3043 732.692 17.3076C727.491 10.9509 723.014 10.3826 717.851 10.5697C716.133 10.632 714.692 9.28743 714.692 7.56863Z"
      fill="black"
      stroke="black"
    />
    <circle cx="744.461" cy="5.53846" r="5.53846" fill="black" />
    <circle cx="719.538" cy="20.7692" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M714.692 567.569V563.692C714.692 562.035 716.036 560.687 717.692 560.658C724.332 560.545 729.986 560.454 735.115 565.885C740.242 573.005 742.556 574.97 746.494 575.635C748.06 575.9 749.308 577.163 749.308 578.752V583.074C749.308 584.54 748.242 585.829 746.775 585.808C739.744 585.707 734.552 580.304 732.692 577.308C727.491 570.951 723.014 570.383 717.851 570.57C716.133 570.632 714.692 569.287 714.692 567.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="744.461" cy="565.538" r="5.53846" fill="black" />
    <circle cx="719.538" cy="580.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M714.692 285.569V281.692C714.692 280.035 716.036 278.687 717.692 278.658C724.332 278.545 729.986 278.454 735.115 283.885C740.242 291.005 742.556 292.97 746.494 293.635C748.06 293.9 749.308 295.163 749.308 296.752V301.074C749.308 302.54 748.242 303.829 746.775 303.808C739.744 303.707 734.552 298.304 732.692 295.308C727.491 288.951 723.014 288.383 717.851 288.57C716.133 288.632 714.692 287.287 714.692 285.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="744.461" cy="283.538" r="5.53846" fill="black" />
    <circle cx="719.538" cy="298.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M611.692 146.569V142.692C611.692 141.035 613.036 139.687 614.692 139.658C621.332 139.545 626.986 139.454 632.115 144.885C637.242 152.005 639.556 153.97 643.494 154.635C645.06 154.9 646.308 156.163 646.308 157.752V162.074C646.308 163.54 645.242 164.829 643.775 164.808C636.744 164.707 631.552 159.304 629.692 156.308C624.491 149.951 620.014 149.383 614.851 149.57C613.133 149.632 611.692 148.287 611.692 146.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="641.461" cy="144.538" r="5.53846" fill="black" />
    <circle cx="616.538" cy="159.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M611.692 424.569V420.692C611.692 419.035 613.036 417.687 614.692 417.658C621.332 417.545 626.986 417.454 632.115 422.885C637.242 430.005 639.556 431.97 643.494 432.635C645.06 432.9 646.308 434.163 646.308 435.752V440.074C646.308 441.54 645.242 442.829 643.775 442.808C636.744 442.707 631.552 437.304 629.692 434.308C624.491 427.951 620.014 427.383 614.851 427.57C613.133 427.632 611.692 426.287 611.692 424.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="641.461" cy="422.538" r="5.53846" fill="black" />
    <circle cx="616.538" cy="437.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M611.692 742.569V738.692C611.692 737.035 613.036 735.687 614.692 735.658C621.332 735.545 626.986 735.454 632.115 740.885C637.242 748.005 639.556 749.97 643.494 750.635C645.06 750.9 646.308 752.163 646.308 753.752V758.074C646.308 759.54 645.242 760.829 643.775 760.808C636.744 760.707 631.552 755.304 629.692 752.308C624.491 745.951 620.014 745.383 614.851 745.57C613.133 745.632 611.692 744.287 611.692 742.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="641.461" cy="740.538" r="5.53846" fill="black" />
    <circle cx="616.538" cy="755.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M952.692 7.56863V3.69221C952.692 2.03536 954.036 0.686644 955.692 0.658438C962.332 0.545394 967.986 0.453966 973.115 5.88453C978.242 13.0048 980.556 14.9697 984.494 15.6352C986.06 15.9 987.308 17.1633 987.308 18.7517V23.0739C987.308 24.5404 986.242 25.8295 984.775 25.8084C977.744 25.7072 972.552 20.3043 970.692 17.3076C965.491 10.9509 961.014 10.3826 955.851 10.5697C954.133 10.632 952.692 9.28743 952.692 7.56863Z"
      fill="black"
      stroke="black"
    />
    <circle cx="982.461" cy="5.53846" r="5.53846" fill="black" />
    <circle cx="957.538" cy="20.7692" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M952.692 567.569V563.692C952.692 562.035 954.036 560.687 955.692 560.658C962.332 560.545 967.986 560.454 973.115 565.885C978.242 573.005 980.556 574.97 984.494 575.635C986.06 575.9 987.308 577.163 987.308 578.752V583.074C987.308 584.54 986.242 585.829 984.775 585.808C977.744 585.707 972.552 580.304 970.692 577.308C965.491 570.951 961.014 570.383 955.851 570.57C954.133 570.632 952.692 569.287 952.692 567.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="982.461" cy="565.538" r="5.53846" fill="black" />
    <circle cx="957.538" cy="580.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M952.692 285.569V281.692C952.692 280.035 954.036 278.687 955.692 278.658C962.332 278.545 967.986 278.454 973.115 283.885C978.242 291.005 980.556 292.97 984.494 293.635C986.06 293.9 987.308 295.163 987.308 296.752V301.074C987.308 302.54 986.242 303.829 984.775 303.808C977.744 303.707 972.552 298.304 970.692 295.308C965.491 288.951 961.014 288.383 955.851 288.57C954.133 288.632 952.692 287.287 952.692 285.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="982.461" cy="283.538" r="5.53846" fill="black" />
    <circle cx="957.538" cy="298.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M849.692 146.569V142.692C849.692 141.035 851.036 139.687 852.692 139.658C859.332 139.545 864.986 139.454 870.115 144.885C875.242 152.005 877.556 153.97 881.494 154.635C883.06 154.9 884.308 156.163 884.308 157.752V162.074C884.308 163.54 883.242 164.829 881.775 164.808C874.744 164.707 869.552 159.304 867.692 156.308C862.491 149.951 858.014 149.383 852.851 149.57C851.133 149.632 849.692 148.287 849.692 146.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="879.461" cy="144.538" r="5.53846" fill="black" />
    <circle cx="854.538" cy="159.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M849.692 424.569V420.692C849.692 419.035 851.036 417.687 852.692 417.658C859.332 417.545 864.986 417.454 870.115 422.885C875.242 430.005 877.556 431.97 881.494 432.635C883.06 432.9 884.308 434.163 884.308 435.752V440.074C884.308 441.54 883.242 442.829 881.775 442.808C874.744 442.707 869.552 437.304 867.692 434.308C862.491 427.951 858.014 427.383 852.851 427.57C851.133 427.632 849.692 426.287 849.692 424.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="879.461" cy="422.538" r="5.53846" fill="black" />
    <circle cx="854.538" cy="437.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M849.692 742.569V738.692C849.692 737.035 851.036 735.687 852.692 735.658C859.332 735.545 864.986 735.454 870.115 740.885C875.242 748.005 877.556 749.97 881.494 750.635C883.06 750.9 884.308 752.163 884.308 753.752V758.074C884.308 759.54 883.242 760.829 881.775 760.808C874.744 760.707 869.552 755.304 867.692 752.308C862.491 745.951 858.014 745.383 852.851 745.57C851.133 745.632 849.692 744.287 849.692 742.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="879.461" cy="740.538" r="5.53846" fill="black" />
    <circle cx="854.538" cy="755.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1190.69 7.56863V3.69221C1190.69 2.03536 1192.04 0.686644 1193.69 0.658438C1200.33 0.545394 1205.99 0.453966 1211.12 5.88453C1216.24 13.0048 1218.56 14.9697 1222.49 15.6352C1224.06 15.9 1225.31 17.1633 1225.31 18.7517V23.0739C1225.31 24.5404 1224.24 25.8295 1222.78 25.8084C1215.74 25.7072 1210.55 20.3043 1208.69 17.3076C1203.49 10.9509 1199.01 10.3826 1193.85 10.5697C1192.13 10.632 1190.69 9.28743 1190.69 7.56863Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1220.46" cy="5.53846" r="5.53846" fill="black" />
    <circle cx="1195.54" cy="20.7692" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1190.69 567.569V563.692C1190.69 562.035 1192.04 560.687 1193.69 560.658C1200.33 560.545 1205.99 560.454 1211.12 565.885C1216.24 573.005 1218.56 574.97 1222.49 575.635C1224.06 575.9 1225.31 577.163 1225.31 578.752V583.074C1225.31 584.54 1224.24 585.829 1222.78 585.808C1215.74 585.707 1210.55 580.304 1208.69 577.308C1203.49 570.951 1199.01 570.383 1193.85 570.57C1192.13 570.632 1190.69 569.287 1190.69 567.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1220.46" cy="565.538" r="5.53846" fill="black" />
    <circle cx="1195.54" cy="580.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1190.69 285.569V281.692C1190.69 280.035 1192.04 278.687 1193.69 278.658C1200.33 278.545 1205.99 278.454 1211.12 283.885C1216.24 291.005 1218.56 292.97 1222.49 293.635C1224.06 293.9 1225.31 295.163 1225.31 296.752V301.074C1225.31 302.54 1224.24 303.829 1222.78 303.808C1215.74 303.707 1210.55 298.304 1208.69 295.308C1203.49 288.951 1199.01 288.383 1193.85 288.57C1192.13 288.632 1190.69 287.287 1190.69 285.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1220.46" cy="283.538" r="5.53846" fill="black" />
    <circle cx="1195.54" cy="298.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1087.69 146.569V142.692C1087.69 141.035 1089.04 139.687 1090.69 139.658C1097.33 139.545 1102.99 139.454 1108.12 144.885C1113.24 152.005 1115.56 153.97 1119.49 154.635C1121.06 154.9 1122.31 156.163 1122.31 157.752V162.074C1122.31 163.54 1121.24 164.829 1119.78 164.808C1112.74 164.707 1107.55 159.304 1105.69 156.308C1100.49 149.951 1096.01 149.383 1090.85 149.57C1089.13 149.632 1087.69 148.287 1087.69 146.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1117.46" cy="144.538" r="5.53846" fill="black" />
    <circle cx="1092.54" cy="159.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1087.69 424.569V420.692C1087.69 419.035 1089.04 417.687 1090.69 417.658C1097.33 417.545 1102.99 417.454 1108.12 422.885C1113.24 430.005 1115.56 431.97 1119.49 432.635C1121.06 432.9 1122.31 434.163 1122.31 435.752V440.074C1122.31 441.54 1121.24 442.829 1119.78 442.808C1112.74 442.707 1107.55 437.304 1105.69 434.308C1100.49 427.951 1096.01 427.383 1090.85 427.57C1089.13 427.632 1087.69 426.287 1087.69 424.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1117.46" cy="422.538" r="5.53846" fill="black" />
    <circle cx="1092.54" cy="437.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1087.69 742.569V738.692C1087.69 737.035 1089.04 735.687 1090.69 735.658C1097.33 735.545 1102.99 735.454 1108.12 740.885C1113.24 748.005 1115.56 749.97 1119.49 750.635C1121.06 750.9 1122.31 752.163 1122.31 753.752V758.074C1122.31 759.54 1121.24 760.829 1119.78 760.808C1112.74 760.707 1107.55 755.304 1105.69 752.308C1100.49 745.951 1096.01 745.383 1090.85 745.57C1089.13 745.632 1087.69 744.287 1087.69 742.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1117.46" cy="740.538" r="5.53846" fill="black" />
    <circle cx="1092.54" cy="755.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1428.69 7.56863V3.69221C1428.69 2.03536 1430.04 0.686644 1431.69 0.658438C1438.33 0.545394 1443.99 0.453966 1449.12 5.88453C1454.24 13.0048 1456.56 14.9697 1460.49 15.6352C1462.06 15.9 1463.31 17.1633 1463.31 18.7517V23.0739C1463.31 24.5404 1462.24 25.8295 1460.78 25.8084C1453.74 25.7072 1448.55 20.3043 1446.69 17.3076C1441.49 10.9509 1437.01 10.3826 1431.85 10.5697C1430.13 10.632 1428.69 9.28743 1428.69 7.56863Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1458.46" cy="5.53846" r="5.53846" fill="black" />
    <circle cx="1433.54" cy="20.7692" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1428.69 567.569V563.692C1428.69 562.035 1430.04 560.687 1431.69 560.658C1438.33 560.545 1443.99 560.454 1449.12 565.885C1454.24 573.005 1456.56 574.97 1460.49 575.635C1462.06 575.9 1463.31 577.163 1463.31 578.752V583.074C1463.31 584.54 1462.24 585.829 1460.78 585.808C1453.74 585.707 1448.55 580.304 1446.69 577.308C1441.49 570.951 1437.01 570.383 1431.85 570.57C1430.13 570.632 1428.69 569.287 1428.69 567.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1458.46" cy="565.538" r="5.53846" fill="black" />
    <circle cx="1433.54" cy="580.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1428.69 285.569V281.692C1428.69 280.035 1430.04 278.687 1431.69 278.658C1438.33 278.545 1443.99 278.454 1449.12 283.885C1454.24 291.005 1456.56 292.97 1460.49 293.635C1462.06 293.9 1463.31 295.163 1463.31 296.752V301.074C1463.31 302.54 1462.24 303.829 1460.78 303.808C1453.74 303.707 1448.55 298.304 1446.69 295.308C1441.49 288.951 1437.01 288.383 1431.85 288.57C1430.13 288.632 1428.69 287.287 1428.69 285.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1458.46" cy="283.538" r="5.53846" fill="black" />
    <circle cx="1433.54" cy="298.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1325.69 146.569V142.692C1325.69 141.035 1327.04 139.687 1328.69 139.658C1335.33 139.545 1340.99 139.454 1346.12 144.885C1351.24 152.005 1353.56 153.97 1357.49 154.635C1359.06 154.9 1360.31 156.163 1360.31 157.752V162.074C1360.31 163.54 1359.24 164.829 1357.78 164.808C1350.74 164.707 1345.55 159.304 1343.69 156.308C1338.49 149.951 1334.01 149.383 1328.85 149.57C1327.13 149.632 1325.69 148.287 1325.69 146.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1355.46" cy="144.538" r="5.53846" fill="black" />
    <circle cx="1330.54" cy="159.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1325.69 424.569V420.692C1325.69 419.035 1327.04 417.687 1328.69 417.658C1335.33 417.545 1340.99 417.454 1346.12 422.885C1351.24 430.005 1353.56 431.97 1357.49 432.635C1359.06 432.9 1360.31 434.163 1360.31 435.752V440.074C1360.31 441.54 1359.24 442.829 1357.78 442.808C1350.74 442.707 1345.55 437.304 1343.69 434.308C1338.49 427.951 1334.01 427.383 1328.85 427.57C1327.13 427.632 1325.69 426.287 1325.69 424.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1355.46" cy="422.538" r="5.53846" fill="black" />
    <circle cx="1330.54" cy="437.769" r="5.53846" fill="black" />
  </g>
  <g opacity="0.2">
    <path
      d="M1325.69 742.569V738.692C1325.69 737.035 1327.04 735.687 1328.69 735.658C1335.33 735.545 1340.99 735.454 1346.12 740.885C1351.24 748.005 1353.56 749.97 1357.49 750.635C1359.06 750.9 1360.31 752.163 1360.31 753.752V758.074C1360.31 759.54 1359.24 760.829 1357.78 760.808C1350.74 760.707 1345.55 755.304 1343.69 752.308C1338.49 745.951 1334.01 745.383 1328.85 745.57C1327.13 745.632 1325.69 744.287 1325.69 742.569Z"
      fill="black"
      stroke="black"
    />
    <circle cx="1355.46" cy="740.538" r="5.53846" fill="black" />
    <circle cx="1330.54" cy="755.769" r="5.53846" fill="black" />
  </g>
</svg>

  )