import { Box, Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomProgress from "../CustomProgress";
export interface CustomIconButtonProps extends ButtonProps {
  content: any;
  loading?: boolean;
  keyId?: any;
  icon?:any;
  noHover?:true
}
const textcss:any={textTransform: "capitalize",
fontWeight: 500,
fontSize: "0.875rem",
lineHeight:"normal"}
const CustomIcon = styled(Button)(({ theme }) => ({
  minWidth: "0",
  // padding: "0",
  padding: "8px 10px",
  // "&:hover": {
  //   backgroundColor:"transparent"
  // },"&:focus": {
  //   backgroundColor:"transparent"
  // },
  ...textcss
  // color: globalColors.primary.black,
}));
function CustomIconButton(props: CustomIconButtonProps) {
  const onclick:any=props.loading === true ||props.disabled ?undefined:props.onClick??undefined
  return props.noHover? <Box
  key={props.keyId ?? "cutsom-utton"}
  // {...{ variant: "text" }}
  // {...{...props,loading:undefined}}
  // disabled={props.loading === true ? true : props.disabled ?? undefined}
  className={props.className??""}
  onClick={onclick}
  sx={{...textcss,...props.sx,gap:"5px",cursor:props.loading === true ||props.disabled ?"auto":"pointer",alignItems:"center",display:"flex"}}
>{props.loading ? (
 ""
) :props.icon&& (
  props.icon
)}
  
  {props.loading ? (
    <CustomProgress
      sx={{ height: "25.2px !important", width: "25.2px !important" }}
    />
  ) : (
    props.content
  )}
</Box>:(
    <CustomIcon
      key={props.keyId ?? "cutsom-utton"}
      {...{ variant: "text" }}
      {...{...props,loading:undefined}}
      disabled={props.loading === true ? true : props.disabled ?? undefined}
      sx={{...props.sx,gap:"5px"}}
    >{props.loading ? (
     ""
    ) :props.icon&& (
      props.icon
    )}
      
      {props.loading ? (
        <CustomProgress
          sx={{ height: "25.2px !important", width: "25.2px !important" }}
        />
      ) : (
        props.content
      )}
    </CustomIcon>
  );
}

export default CustomIconButton;
