import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import CustomTextField from "../../Components/CustomTextfield";
import { heading, subHeading } from "../../Components/CustomTexts";
import { routes } from "../../store/slices/constants/staticContents";
import SvgPreview from "../../Components/SvgPreview";
import CustomIconButton from "../../Components/CustomIconButton";
import CustomCard from "../../Components/CustomCard";
import globalColors from "../../globalColors";
import CustomContainedButton from "../../Components/CustomContainedButton";
import { allApis } from "../../request/config";
import { useAppDispatch } from "../../hooks/storeHooks";
import OtpTextfeild from "../../Components/OtpTextfeild";
import apiRequest, { requestObject } from "../../request/apiRequest/apiRequest";
import { useNavigate } from "react-router-dom";
import { showSnack } from "../../store/slices/constants";
import { columnVerticalCenter, columnSB } from "../../GlobalStyles";
import { loginVerify } from "../../store/slices/userManagement/thunks/loginVerify";
import { allStaticText } from "../../store/slices/constants/staticContents/AllTexts";

/**
 * Interface for storing key-value pairs of form details.
 */
export interface ObjectValues {
  [key: string]: any;
}

export interface inputProp {
  defaultValue?: { value: string; id: any };
  label: string;
  grid?:any;
  fullWidth?:true;
  pinned?: true;
  api?: string;
  not_send_id?: true;
  placeholder?: string;
  only_number?: true;
  multiple?:true;
  value: any;
  //  string|ObjectValues;
  error: boolean;
  helperText: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | any) => void;
  onUpdate?:any;
  endAdornment?: React.ReactNode;
  type?: "password";
  fieldType?:
    | "text"
    | "select"
    | "cktextEditor"
    | "autocomplete"
    | "date"
    | "extra"|"image"|"checkbox"|"multiselect"|"dateTime";
  multiline?: number;
  list?: ObjectValues[];
  disabled?: boolean;
  not_required?: true;
  listLabel?: string;
  freeSolo?:{
    dialog?:boolean,
    content?:ObjectValues,
    addText?:string,};
  dataType?: "number"|"option";
  fieldSetName?: string | null;
  allowNegative?: boolean;
  validate?: boolean;
  validateExp?: RegExp
}
/**
 * Login component that handles user authentication.
 */
function Login() {
  const { LoginContents } = allStaticText;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [details, setDetails] = useState<ObjectValues>({});
  const emailLabel = LoginContents.email.label;
  const otpLabel = LoginContents.otp.label;
  const passwordLabel = LoginContents.password.label;

  /**
   * Handles input change for form fields.
   * @param {string} label - The label of the form field.
   * @param {string} value - The value entered in the form field.
   */
  const handleInputChange = (label: string, value: string | number) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      [label]: value,
      [`${label}-error`]: undefined,
    }));
  };
  const inputFormToLogin: inputProp[] = [
    {
      label: emailLabel,
      placeholder: allStaticText.LoginContents.email.placeholder,
      value: details[emailLabel] ?? "",
      error: details[`${emailLabel}-error`] ?? false,
      helperText: details[`${emailLabel}-error`] ?? " c",
      onChange: (e) => handleInputChange(emailLabel, e.target.value),
      endAdornment: <SvgPreview svg={LoginContents.email.icon} />,
    },
    {
      label: passwordLabel,
      placeholder: LoginContents.password.placeholder,
      value: details[passwordLabel] ?? "",
      error: details[`${passwordLabel}-error`] ?? false,
      type: "password",
      helperText: details[`${passwordLabel}-error`] ?? " d",
      onChange: (e) => handleInputChange(passwordLabel, e.target.value),
      endAdornment: (
        <CustomIconButton
        noHover={true}
          onClick={() => {
            setDetails((prev) => {
              return { ...prev, visible: !details.visible };
            });
          }}
          content={
            <SvgPreview
              svg={
                details.visible
                  ? LoginContents.password.icon.close
                  : LoginContents.password.icon.open
              }
            />
          }
        />
      ),
    },
  ];
  const otpForm: inputProp[] = [
    {
      label: otpLabel,
      value: details[otpLabel] ?? "",
      error: details[`${otpLabel}-error`] ?? false,
      helperText: details[`${otpLabel}-error`] ?? " c",
      onChange: (value: any) => handleInputChange(otpLabel, value),
    },
  ];

  /**
   * Helper function to render TextField with consistent structure.
   * @param {Object} param0 - The properties of the text field.
   */
  function renderTextField({
    label,
    placeholder,
    value,
    error,
    helperText,
    onChange,
    endAdornment,
    type,
  }: inputProp) {
    return (
      <CustomTextField
        placeholder={placeholder}
        disabled={details.loading}
        id={label}
        variant="outlined"
        error={error}
        helperText={helperText}
        value={value}
        onChange={onChange}
        onKeyPress={async (ev: any) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
            onSubmit();
          }
        }}
        sx={{ width: "100%" }}
        InputProps={{
          endAdornment,
        }}
        inputProps={{
          type: details.visible ? "" : type && "password",

          autoComplete: "new-password",
          "data-testid": `${label}-input`, // Test ID for testing purposes
        }}
      />
    );
  }

  /**
   * Validates the form fields and displays error messages if needed.
   * @returns {boolean} - Returns true if validation passes, false otherwise.
   */
  const validationCheck = () => {
    if (details.otpSent) {
      if (details[otpLabel]?.toString().length === 6) {
        return true;
      }
    } else {
      if (details[emailLabel]) {
        if (details[passwordLabel]) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Handles form submission.
   */

  const onVerify = async () => {
    await dispatch(
      loginVerify({
        api: allApis.verifyOtp,
        payload: {
          email: details[emailLabel].toString().toLowerCase(),
          otp: details[otpLabel],
          resend: false,
        },
        navFunc:{function:navigate,route:routes.initial}
      })
    );
    //
    setDetails((prve) => {
      return { ...prve, loading: false };
    });
    
  };
  const verifyEmail = async () => {
    const loginReqBody: requestObject = {
      api: allApis.login,
      payload: {
        email: details[emailLabel].toString().toLowerCase(),
        password: details[passwordLabel],
      },
    };
    const response = await apiRequest(loginReqBody);
    if (response && response.msg === LoginContents.successMessage) {
      setDetails((prve) => {
        return { ...prve, otpSent: true, [otpLabel]: "", loading: false };
      });
    } else {
      
      dispatch(
        showSnack({
          open: true,
          message: response && response.msg ? response.msg : "",
          severity: "error",
        })
      );setDetails((prve) => {
        return { ...prve, [otpLabel]: "", loading: false };
      });
    }
  };
  const onEdit = () => {
    setDetails((prve) => {
      return { ...prve, otpSent: false, [otpLabel]: "" };
    });
  };
  const onSubmit = async () => {
    if (validationCheck()) {
      setDetails((prev) => {
        return { ...prev, loading: true };
      });
      if (details.otpSent) {
        onVerify();
      } else {
        verifyEmail();
      }
    }
  };
  const onResend = () => {
    verifyEmail();
    dispatch(
      showSnack({
        open: true,
        message: LoginContents.otpSentSuccess,
        severity: "success",
      })
    );
  };
  const loginHead = (
    <Box sx={{ ...columnVerticalCenter }}>
      <SvgPreview
        svg={LoginContents.logo}
        sx={{ marginBottom: "20px", height: "72px" }}
      />
      {heading({ text: LoginContents.heading, size: "1.8rem" })}
    </Box>
  );

  const loginContent = (
    <Stack sx={{ ...columnSB ,minWidth:"300px"}} p={"20px 30px 30px"} minHeight={"370.7px"}>
      {loginHead}
      <Box sx={{ margin: "30.91px 0 15px", width: "100%" }}>
        {inputFormToLogin.map((input: inputProp, i: number) => (
          <form key={input.label} style={{ width: "100%" }}>
            <Stack width="100%" key={input.label} spacing={"5px"}>
              {subHeading({ text: input.label ,size:"0.8rem"})}
              {renderTextField(input)}
            </Stack>
          </form>
        ))}
      </Box>
      {/* <Stack
    direction={"row-reverse"}
    sx={{ m: "-10px 0 0 0 !important" }}
    width="100%"
  >
    <CustomIconButton
      content={LoginContents.forgotpassword}
      onClick={onForgot}
    />
  </Stack> */}

      {/* Submit Button */}
      <CustomContainedButton
        loading={details.loading}
        otherProps={{
          disabled: !validationCheck(),
          onClick: onSubmit,
          fullWidth: true,
        }}
        content={LoginContents.buttons.sendOtp}
      />
    </Stack>
  );
  const otpContent = (
    <Stack sx={{ ...columnSB ,minWidth:"300px"}} p={"20px 30px 30px"} minHeight={"370.7px"}>
      {loginHead}
      <Box sx={{ margin: "30.91px 0 15px", width: "100%", flex: 8 }}>
        <Stack direction={"row"} spacing={"5px"} width="100%">
          {subHeading({ text: LoginContents.optSentMessage, size: "0.8rem" })}
          {subHeading({
            text: details[emailLabel],
            weight: 600,
            size: "0.8rem",
          })}

          <CustomIconButton
            content={LoginContents.edit}
            onClick={onEdit}
            noHover={true}
            disabled={details.loading}
          />
        </Stack>
        {otpForm.map((input: inputProp) => (
          <Stack mt="15px" width="100%" spacing={"5px"} key={input.label}>
            {subHeading({ text: input.label,size:"0.8rem"})}
            <OtpTextfeild
              autoComplete="new-password"
              disabled={details.loading}
              otp={details[otpLabel] ?? ""}
              setOtp={(value: any) => {
                if (input.onChange) {
                  input.onChange(value);
                }
              }}
              onKeyDown={ (ev: any) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  onSubmit();
                }
              }}
            />
          </Stack>
        ))}
        <Stack direction={"row-reverse"} mt="10px" width="100%">
          <CustomIconButton
            disabled={details.loading}
            content={LoginContents.buttons.resend}
            noHover={true}
            onClick={onResend}
            sx={{ fontWeight:500,color:globalColors.primary.purple,fontSize:"0.8rem" }}
          />
        </Stack>
      </Box>

      {/* Submit Button */}
      <CustomContainedButton
        otherProps={{
          disabled: !validationCheck(),
          onClick: onSubmit,
          fullWidth: true,
        }}
        loading={details.loading}
        content={LoginContents.buttons.submit}
      />
    </Stack>
  );
  const renderCard = details.otpSent ? otpContent : loginContent;

  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent={"center"}
      alignItems={"center"}
      direction={"row"}
    >
      <CustomCard
        sx={{
          bgcolor: globalColors.primary.pureWhite,
          zIndex: 1,
          maxWidth: "fit-content",
          borderRadius: "14px",
          boxShadow: "0px 0px 28px 0px #00000026",
        }}
        content={renderCard}
      />
      {/* Background Image */}
      <Stack
        height="calc(100% - 60px)"
        direction={"row"}
        width="calc(100% - 30px)"
        sx={{
          position: "absolute",
          top: "30px",
          left: "30px",
          // right: "30px",
          bottom: "30px",
          overflow: "hidden",
          display: "flex",
        }}
      >
        <Stack sx={{ width: "fit-content" }} direction={"row"}>
          {LoginContents.background()}
          <Stack
            flex="1" // This makes the second Stack take the remaining space
            sx={{
              width: "auto",
              marginLeft: "-27px",
            }}
          >
            {LoginContents.background(true)}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Login;
