import { onlyChildProps } from "../../store/slices/constants/requiresVerification";
import { Stack } from "@mui/system";
import globalColors from "../../globalColors";
import CustomHeader from "../CustomHeader";
import { rowCenter } from "../../GlobalStyles";
import CustomSidebar from "../CustomSidebar";
import { useAppSelecter } from "../../hooks/storeHooks";
import { ObjectValues } from "../../pages/login";
import NetworkStatus from "../NetworkStatus";
import OfflineScreen from "../OfflineScreen";

function Layout({ children }: onlyChildProps) {

  const userSlice = useAppSelecter((state: ObjectValues) => state.userSlice.loggedUser);
  const drawerOpen =useAppSelecter((state: ObjectValues) => state.constantSlice.drawerOpen);

  const token = userSlice?.token ?? false;

  const child = NetworkStatus() === "Offline" ? <OfflineScreen /> : children;
  return (
    <Stack
      height="100vh"
      width="100%"
      sx={{ background: globalColors.primary.appBackground }}
    >
      <CustomHeader />

      {/* Main Content Section */}
      <Stack
        flex="8"
        position="relative"
        width="100%"
        mt={token && "4px"}
        sx={{ ...rowCenter,height:"calc(100% - 68px)" }}
      >
        {" "}
        {token && <CustomSidebar />}
        <Stack
          flex="8"
          height="100%"
          position="relative"
          width={drawerOpen ? "calc(100% - 240px)" : "calc(100% - 65px)"}
          sx={{ ...rowCenter }}
        >
          {child}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Layout;
