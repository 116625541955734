import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { snackbarProps } from "../../../Components/CustomSnackbar";
import {
  SidebarContent,
  allRouteModules,
  moduleListProps,
  structureProps,
} from "./staticContents";
import { GlobalLoaderProps } from "../../../Components/GlobalLoader";
export interface breadcrumbData {
  label?: string;
  icon?: any;
  onClick?(): void;
  disabled?:true
}
export type constantsRecord = {
  version: string;
  snackbar: snackbarProps;
  selectedModule: moduleListProps;
  drawerOpen:boolean;
  global_loader:GlobalLoaderProps;
  
};
const defaultSnackbar: snackbarProps = {
  open: false,
  message: "",
  severity: "info",
};
export const formatStructures = (exams_structure:structureProps): structureProps[] => {
    const allDetails: structureProps[] = [];

    const processStructure = (currentStructure: structureProps) => {
      allDetails.push(currentStructure);

      if (currentStructure.child) {
        processStructure(currentStructure.child);
      }
    };

    processStructure(exams_structure);

    return allDetails;
  };
const getInitialRoute = () => {
  const currentPath = window.location.pathname;
  const tofilterWIth =
    currentPath.indexOf("/", currentPath.indexOf("/") + 1) === -1
      ? currentPath
      : currentPath
          .toString()
          .slice(0, currentPath.indexOf("/", currentPath.indexOf("/") + 1));
  const hasPathContainingSubstring = () => {
    return allRouteModules.find((obj: any) => obj.path === tofilterWIth);
  };
  return hasPathContainingSubstring() ?? SidebarContent.list[1];
};
export const initialModule: moduleListProps = getInitialRoute();
const initialConstantsRecord: constantsRecord = {
  version: "2.0.0",
  snackbar: defaultSnackbar,
  selectedModule: initialModule,
  drawerOpen:false,
  global_loader:{open:true}
};
const constantsSlice = createSlice({
  name: "constantsSlice",
  initialState: initialConstantsRecord,
  reducers: {
    showSnack: (state, action: PayloadAction<snackbarProps>) => {
      state.snackbar = action.payload;
    },
    hideSnack: (state) => {
      state.snackbar = defaultSnackbar;
    },
    setSelectedModule: (state, action: PayloadAction<any>) => {
      state.selectedModule = action.payload;
    },
    // setBreadCrumb: (state, action: PayloadAction<breadcrumbData[]>) => {
    //   state.breadcrumb = action.payload;
    // },
    setDrawer:(state,action)=>{
      state.drawerOpen=action.payload
    },
   setLoader: (state, action: PayloadAction<GlobalLoaderProps>) => {
      state.global_loader = action.payload;
    },
  },
  extraReducers(builder) {},
});

export const { showSnack,setDrawer, hideSnack, setSelectedModule ,setLoader} =
  constantsSlice.actions;
export default constantsSlice.reducer;
