import { Box, Fade, Paper, Popper, PopperPlacementType } from "@mui/material";
import React, { useEffect } from "react";
import "./index.css";
import QuestionLanguages from "../../pages/exams/QuestionLanguages";

function MessagePopper({ text,id,type,content,question ,fetchSingleQuestionDetails}: { text?: string,id:string,type?:"ck" ,content?:any,question?:any,
fetchSingleQuestionDetails?:any}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [overflow,setOverflow]=React.useState<boolean>(false)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== "bottom" || !prev);
    setPlacement("bottom");
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Close the popover if the click is outside the popover content
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        if(question){}else{
        handleClose();}
      }
    };

    // Attach the event listener to the document body
    document.body.addEventListener("click", handleOutsideClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);
  
  const idText=`my-para-${text}-${id}`
  const paragraph: any = document.getElementById(idText);
  const isOverflowing=(element: HTMLElement | null)=>{
    if (element) {
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );
    }
  }
  useEffect(() => {
    // Function to check if the paragraph overflows
  //  console.log(id,text,paragraph,"id,text,paragraph")

    // Add ellipsis if paragraph overflows
  function addEllipsisIfNeeded() {
      //   if (isOverflowing(paragraph)) {
      //         paragraph.classList.add('hidden-ellipsis');
      //       } else {
      //         paragraph.classList.remove('hidden-ellipsis');
      //       }
      function findLastVisibleWord(words: any[]) {
        let left = 0;
        let right = words.length - 1;

        while (left <= right) {
          const mid = Math.floor((left + right) / 2);
          const testText = words.slice(0, mid + 1).join(" ");

          paragraph.textContent = testText;
          if (isOverflowing(paragraph)) {
            right = mid - 1;
          } else {
            left = mid + 1;
          }
        }

        return right;
      }
     
      if (isOverflowing(paragraph)) {
        const words = paragraph.textContent.split(" ");
        const lastVisibleIndex = findLastVisibleWord(words);

        paragraph.textContent =
          words.slice(0, lastVisibleIndex + 1).join(" ") + "...";
          setOverflow(true)
      }
    }

    // Call the function initially
    if(text){
    addEllipsisIfNeeded();}
  }, [id,text,paragraph]);
  return (
    <>
      <Popper
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{
          zIndex: 1200,
          m: "-20px 20px 0",
          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
          maxWidth:question?"420px": "300px",
          borderRadius:"8px",
          maxHeight: "calc(100% - 150px)",
        }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ p: "10px",fontSize:"0.75rem" }} className="all-content">
        {question?<QuestionLanguages   handleClose={  handleClose} fetchSingleQuestionDetails={fetchSingleQuestionDetails}question={question}/>:type?<div dangerouslySetInnerHTML={{ __html: text??"" }} />:text}</Paper>
          </Fade>
        )}
      </Popper>
      <Box
        id={idText}
        sx={{
          whiteSpace: "pre-wrap",
          maxHeight: "98px",
          width: "100%",
          textOverflow: "ellipsis",
          overflow: "hidden",
          cursor:overflow||question? "pointer":"inherit",
          height:"auto",
          fontSize:"0.75rem"
          // display:'flex'
        }}
        onClick={(e: any) => {
          if(overflow||question)
        {  handleClick(e);}
        }}
      >
        {content?content:type?<div dangerouslySetInnerHTML={{ __html: text??"" }} />:text}
      </Box>
    </>
  );
}

export default MessagePopper;
