import { List, Stack } from "@mui/material";
import CustemListItem from "../CustemListItem";
import {
  headerContent,
  routes,
} from "../../store/slices/constants/staticContents";
import { useState } from "react";
import ConfirmationDialog from "../ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
import { logoutUser } from "../../store/slices/userManagement/thunks/logout";
import { ObjectValues } from "../../pages/login";
import { setSelectedModule, showSnack } from "../../store/slices/constants";
import { allStaticText } from "../../store/slices/constants/staticContents/AllTexts";

function HeaderProfile() {
  const {logoutContents}=allStaticText;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userSlice = useAppSelecter(
    (state: ObjectValues) => state.userSlice.loggedUser
  );
  const selectedModule = useAppSelecter(
    (state: ObjectValues) => state.constantSlice.selectedModule
  );
  const token = userSlice?.token ?? false;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  return (
    token && (
      <List sx={{ m: "0", p: "0", width: "fit-content" }}>
        <CustemListItem
          key={`Hello`}
          keyId={`Hello`}
          {...{
            primary: `Hello 👋`,
            secondary: userSlice.user_email ??userSlice.email ?? "User",
            action: {
              onClick: () => {},

              popperContent: (
                <Stack spacing={"5px"} minWidth={"125px"}>
                  {headerContent.list.map(
                    ({
                      label,
                      icon,
                      path,
                    }: {
                      label: string;
                      icon: any;
                      path?: string;
                    }) => {
                      return (
                        <CustemListItem
                          key={`${label}`}
                          keyId={`${label}`}
                          isSelected={selectedModule.path === path}
                          onClick={() => {
                            switch (label) {
                              case "Sign Out":
                                setOpenDialog(true);
                                break;
                              case "Edit Profile":
                                setOpenDialog(false);

                                dispatch(
                                  setSelectedModule({ label, icon, path })
                                );
                                navigate(path ?? "");
                                break;
                              case "Settings":
                                setOpenDialog(false);
                                dispatch(
                                  setSelectedModule({ label, icon, path })
                                );
                                navigate(path ?? "");
                                break;
                            }
                          }}
                          primary={label}
                          icon={icon}
                        />
                      );
                    }
                  )}
                </Stack>
              ),
            },
            avatar: "e",
          }}
        />
        <ConfirmationDialog
          title={logoutContents.confirmation.header}
          content={
            logoutContents.confirmation.content
          }
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          yesButtonText={logoutContents.confirmation.confirmButton}
          noButtonText={logoutContents.confirmation.cancelButton}
          onSubmit={() => {
            dispatch(logoutUser());
            dispatch(
          showSnack({
            open: true,
            message: "You have successfully logged out!",
            severity: "success",
          })
        );
            setOpenDialog(false);
            navigate(routes.initial);
          }}
        />
      </List>
    )
  );
}

export default HeaderProfile;
