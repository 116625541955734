import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import globalColors from "../../globalColors";

export const textfieldsx={
  // "& input:-webkit-autofill": {
  //     "-webkit-box-shadow": "0 0 0 30px transparent inset",
  //     "-webkit-text-fill-color": "#333",
  //     "background-color": "transparent",
  //     transition: "background-color 5000s ease-in-out 0s",
  //   },
    "& .MuiInputBase-root": {
      // padding: "0",
    },
    "& .MuiInputBase-input": {
      color: globalColors.primary.black, // sets the color of the input text to green
      borderRadius: "10px",
      background:"transparent",
      padding: "10px 12px",
      "&:fieldset": {
        background: "transparent",
      },
      "&:-webkit-autofill": {
        background: "transparent",
      },
      "&:-internal-autofill-selected": {
        background:"transparent",
      },
    },
  
    "& .MuiInputLabel-root": {
      color: globalColors.primary.black, // sets the color of the label text to red
    },
    "& .MuiFormHelperText-root.Mui-disabled": {
      color: globalColors.primary.white, // sets the color of the helper text to blue
  
      background: "transparent",
    },
    "& .MuiButtonBase-root ":{
      color:"#374957"
    },
    "& .MuiFormHelperText-root": {
      color: globalColors.primary.white , // sets the color of the helper text to blue
  
      background: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor:  globalColors.primary.grey, // mostLightGrey, // sets the border color to purple
        borderRadius: "10px",
        borderWidth: "1px",
        background: "transparent",
      },
  
      "&:hover fieldset": {
        borderColor: globalColors.primary.grey, // sets the border color to orange on hover
        borderRadius: "10px",
        borderWidth: "1px",
        background: "transparent",
      },
      "& .Mui-disabled:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
        background: "transparent",  
        borderColor: globalColors.primary.grey, // sets the border color to orange on hover
      
      },
      "&:-webkit-autofill": {
        borderWidth: "1px",
        background: "transparent",
      },
      "&:-internal-autofill-selected": {
        borderWidth: "1px",
        background: "transparent",
      },
    }, "& .MuiInputBase-input::placeholder": {
      color: globalColors.primary.grey
    },
    "&.MuiPopover-paper":{
      borderRadius:"10px"

    }
  //  "&.MuiInputLabel-outlined": {
  //     transform: "translate(12px , 8px) scale(1)",
  //   },
  //   "& .MuiInputLabel-outlined": {
  //     transform: "translate(12px , 8px) scale(1)",
  //   },
    // "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    //   transform: "translate(12px, -8px) scale(0.75)",
    // },
}
const CustomTextField = styled(TextField)(({ theme }) => (textfieldsx));

export default CustomTextField;
