import React from 'react';
import DOMPurify from 'dompurify';
import katex from 'katex';
import 'katex/dist/katex.min.css';

interface PreviewProps {
    htmlContent: string;
}

const PreviewComponent: React.FC<PreviewProps> = ({ htmlContent }) => {
    const decodeHTMLEntities = (text: string): string => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    };

    const renderMath = (text: string): string => {
        return text.replace(/\$\$(.*?)\$\$/g, (_, expr) => {
            return katex.renderToString(expr, {
                throwOnError: false
            });
        });
    };

    const decodedContent = decodeHTMLEntities(htmlContent);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);
    const mathRenderedContent = renderMath(sanitizedContent);

    return (
        <div dangerouslySetInnerHTML={{ __html: mathRenderedContent }} />
    );
};

export default PreviewComponent;
