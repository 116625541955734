import PageNotFound from "../../../pages/pageNotFound";
import Login from "../../../pages/login";
import RequiresVerification from "./requiresVerification";
import Profile from "../../../pages/profile";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import SnackbarProvider from "../../../providers/SnackbarProvider/SnackbarProvider";
import Layout from "../../../Components/Layout";
import { routes } from "./staticContents";
import Exams from "../../../pages/exams";
import Home from "../../../pages/home";
import { initialModule } from ".";
import Settings from "../../../pages/settings";
import GlobalLoaderProvider from "../../../providers/GlobalLoaderProvider";
import NotVerfied from "./NotVerfied";
import OrderManagement from "../../../pages/orderManagement";
import MockTest from "../../../pages/mockTest";
import ContentFeedback from "../../../pages/contentFeedback";
import EditScreen from "../../../pages/contentFeedback/EditScreen";
const router = createBrowserRouter([
  {
    element: (
      <SnackbarProvider>
        <Layout>
          <Outlet />
        </Layout>
      </SnackbarProvider>
    ),
    // errorElement: <ErrorBoundary />,
    children: [
      { path: routes.initial, element: <Navigate to={initialModule.path} /> },
      //  public routes
      { element: (
        <NotVerfied>
          <Outlet />
        </NotVerfied>
      ),
      children: [ {
        path: "/login",
        element: <Login />,
      }]},
      // unknown sources
      {
        path: "*",
        element: <PageNotFound />,
      },
      // protected routes
      {
        element: (
          <RequiresVerification>
          <GlobalLoaderProvider>
            <Outlet />
            </GlobalLoaderProvider>
          </RequiresVerification>
        ),
        children: [
          {
            path: routes.profile,
            element: <Profile />,
          },
          {
            path: routes.exams,
            element: <Exams />,
          },
          {
            path: routes.home,
            element: <Home />,
          },
          { path: routes.settings, element: <Settings /> },
          {
            path: routes.mockTest,
            element: <MockTest />
          },
          {
            path: routes.contentFeedback,
            element: <ContentFeedback />
          },
          {
            path: routes.orderManagement,
            element: <OrderManagement />,
          },
          {
            path: "/contentFeedback/editScreen",
            element: <EditScreen />
          }
        ],
      },
    ],
  },
]);

export default router;
