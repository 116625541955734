import { useAppSelecter } from "../../hooks/storeHooks";
import { ObjectValues } from "../login";
import { Grid, Stack } from "@mui/material";
import ExpandViewCard, { CollapseButton } from "../../Components/ExpandViewCard.tsx";
import {
  structureProps,
} from "../../store/slices/constants/staticContents";

function AllExpanded() {
  const examsData = useAppSelecter(
    (state: ObjectValues) => state.exams
  );
  const is_expanded = examsData.is_expanded;
  const formatted_exams_structure :structureProps[]= examsData.formatted_exams_structure;

  const dockedCard:structureProps=examsData.docked_exams;
  return (
    is_expanded ? (
      <Stack
        height="100%"
        width="100%"
        direction={"row"}
        spacing={"8px"}
        position={"relative"}
      >
        <Grid
          container
          gap={"8px"}
          flexWrap="nowrap"
          sx={{ overflowX: "auto", width: "100%" }}
        >
          {formatted_exams_structure?.length>0&&formatted_exams_structure.map((item,i) => {
            return (
              <Grid key={`${item.title??"a"}${i}`} item xs height="100%" display="flex" sx={{maxWidth:"250px !important"}}>
                <ExpandViewCard item={item} />
              </Grid>
            );
          })}
        </Grid>
        {CollapseButton()}
      </Stack>
    ):dockedCard&&dockedCard.child?<Grid item xs height="100%" display="flex">
    <ExpandViewCard item={dockedCard}singleCard={true} />
  </Grid>:<></>
  )
}

export default AllExpanded;
