import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Box } from '@mui/material';
import moment from 'moment';

interface ReportDialogProps {
  open: boolean;
  onClose: () => void;
  reportLoading: boolean;
  reportData: {
    reported_by: string;
    reported_message: string;
    reported_at: string;
  }[];
}

const ReportDialog: React.FC<ReportDialogProps> = ({ open, onClose, reportData, reportLoading }) => {
  return (

    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>User Report Details</DialogTitle>
      <DialogContent>
        {reportLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="200px">
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Reported By</TableCell>
                <TableCell>Reported Message</TableCell>
                <TableCell>Reported On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData?.map((report, index) => (
                <TableRow key={index}>
                  <TableCell>{report.reported_by}</TableCell>
                  <TableCell>{report.reported_message}</TableCell>
                  <TableCell>{moment(report.reported_at).format("DD-MM-YYYY hh:mm")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;
