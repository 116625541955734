import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { logoutUser } from "./thunks/logout";
import { restoreUser, userResponse } from "./thunks/restoreUser";
import { loginVerify } from "./thunks/loginVerify";

export type userRecord = {
    loggedUser: userResponse|null;
};
const initialUserRecord: userRecord = {
  loggedUser:null
};
const userSlice = createSlice({
  name: "userSlice",
  initialState: initialUserRecord,
  reducers: {},
  extraReducers(builder) {
    // verify api call started
     builder.addCase(
        loginVerify.pending,
        function (state, action) {
            state.loggedUser = null
        }
      );
       // verify api call success
      builder.addCase(
        loginVerify.fulfilled,
        function (state, action: PayloadAction<userResponse|null>) {
          const { payload } = action;
          state.loggedUser = payload;
        }
      );

       // verify api call rejected
      builder.addCase(
        loginVerify.rejected,
        function (state, action) {
            state.loggedUser = null
        }
      );
      // restore User data
      builder.addCase(
        restoreUser.pending,
        function (state, action) {
            state.loggedUser = null
        }
      );
       // verify api call success
      builder.addCase(
        restoreUser.fulfilled,
        function (state, action: PayloadAction<userResponse|null>) {
          const { payload } = action;
          state.loggedUser = payload;
        }
      );

       // verify api call rejected
      builder.addCase(
        restoreUser.rejected,
        function (state) {
            state.loggedUser = null
        }
      );
      // user logout
      builder.addCase(
        logoutUser.fulfilled,
        function (state) {
          state.loggedUser = null;
        }
      );
      
  },
});
export default userSlice.reducer;
