import { CKEditor } from "@ckeditor/ckeditor5-react";
import * as Generic from "./packages/res/demos/imports";
import * as ClassicEditor from "./ckeditor";
import { useEffect, useState } from "react";

const toolbar = [
  "bold",
  "italic",
  "MathType",
  "ChemType",
  "alignment:left",
  "alignment:center",
  "alignment:right",
];
const ckConfig = {
  iframe: true,
  charCounterCount: false,
  toolbar,
  htmlAllowedTags: [".*"],
  htmlAllowedAttrs: [".*"],
  htmlAllowedEmptyTags: ["mprescripts"],
  imageResize: false,
  useClasses: false,
};

function updateContent(ckeditor: { getData: () => any }) {
  const ele: any = document.getElementById("btn_update");

  if (ele) {
    ele.addEventListener("click", (e: { preventDefault: () => void }) => {
      e.preventDefault();
      Generic.updateContent(ckeditor.getData(), "transform_content");
    });
  }
}

function CustomCkeditor(props: any) {
  const [editorContent, setEditorContent] = useState(props.value || "");

  useEffect(() => {
    if (props.onChange) {
      props.onChange(editorContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorContent]);
  useEffect(() => {
    if ((props.value||props.value==="")&&props.value !== editorContent) {
      setEditorContent(props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);
  return (
    <CKEditor
      editor={ClassicEditor}
      config={ckConfig}
      data={editorContent} // Set default value here
      onChange={(event, editor) => {
        const data = editor.getData();
        setEditorContent(data);
      }}
      onReady={(editor: any) => {
        updateContent(editor);
      }}
    />
  );
}

export default CustomCkeditor;
