import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { cross } from "../../assets/svgs";
import SvgPreview from "../SvgPreview";
import  {
  CustomContainedButtonProps,
} from "../CustomContainedButton";
import { DialogActions, DialogContent } from "@mui/material";
import CustomIconButton, { CustomIconButtonProps } from "../CustomIconButton";
import { rowSB ,rowCenter} from "../../GlobalStyles";
import styled from "@emotion/styled";
import { subHeading } from "../CustomTexts";
import { ObjectValues } from "../../pages/login";
export interface action extends CustomContainedButtonProps {}
export interface actionIcon extends CustomIconButtonProps {}
export interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  content: any;
  actions: actionIcon[];
  title?: string;
 customHeader?:any;
 actionsx?:ObjectValues;
 contentsx?:ObjectValues
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: '0 15px 15px',
    minHeight:"50px"
  },
  "& .MuiDialogActions-root": {
    // padding: theme.spacing(1),
  },
}));
export default function CustomDialog(props: CustomDialogProps) {
  const { onClose, title, open, content, actions ,customHeader,actionsx,contentsx} = props;

  const handleClose = () => {
    onClose();
  };
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
    {customHeader?customHeader:  <DialogTitle
        sx={{ m: 0, p: "20px 15px 15px", ...rowSB, }}
        id="customized-dialog-title"
      >
        {subHeading({ text: title ?? "" ,size:"1.1rem",weight:"600"})}
        <CustomIconButton
        onClick={onClose}
        noHover={true}
          sx={{
            color: (theme) => theme.palette.grey[500],
            
          }}
          content={<SvgPreview svg={cross} />}
        />
      </DialogTitle>
}
     <DialogContent sx={contentsx}> {content ?? ""}</DialogContent>
      {actions && actions.length > 0 && (
        <DialogActions   sx={{ m: 0, p: "0px 15px 20px", ...rowCenter,...actionsx }}
        >
          {actions.map((item,i) => {
            return <CustomIconButton {...item}key={`${i}-button`} />;
          })}
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}
