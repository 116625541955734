import Breadcrumbs from "@mui/material/Breadcrumbs";
import { ObjectValues } from "../../pages/login";
import { useAppSelecter } from "../../hooks/storeHooks";
import globalColors from "../../globalColors";
import { breadcrumbData } from "../../store/slices/constants";
import { iconText } from "../CustomTexts";

export default function CustomBreadcrumbs() {
  const constantsData = useAppSelecter(
    (state: ObjectValues) => state.exams
  );
  const breadcrumbData = constantsData.breadcrumb;
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        p: "5px 10px 0 15px",
        alignItems:"flex-end",
        ".MuiBreadcrumbs-separator": {
          fontSize: "0.75rem",
          color: globalColors.primary.normalText,
          margin: "0 10px",
        },
      }}
    >
      {breadcrumbData.map(
        ({ label, icon, onClick ,disabled}: breadcrumbData, i: number) => {
          // const first = i === 0;
          const last = i === breadcrumbData.length - 1||disabled;
          return iconText({
            key:"label",
            // iconsx:"2px",
            // iconsx:!last&&{marginBottom:"4px"},
            text: label ?? "",
            icon: icon ?typeof icon === 'function'?icon({height:"14px"}):icon: "",
            sx: {
              cursor: !onClick||last ? "" : "pointer",
              color:!last? globalColors.primary.purple: globalColors.primary.normalText,
              // first ? globalColors.primary.purple :
            },
            size:"0.75rem",
            onClick: last?()=>{}:onClick,
            classname: last ? "" : "linkStyle-always",
          });
        }
      )}
    </Breadcrumbs>
  );
}
