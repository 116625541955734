export const copyIcon=
<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.66732 13.3333C8.55105 13.3323 9.39828 12.9808 10.0232 12.3559C10.6481 11.731 10.9996 10.8837 11.0007 10V4.16201C11.0017 3.81158 10.9332 3.46444 10.799 3.14069C10.6649 2.81694 10.4679 2.52304 10.2193 2.27601L8.72465 0.781345C8.47762 0.532794 8.18372 0.335747 7.85997 0.201625C7.53623 0.0675033 7.18908 -0.0010253 6.83865 1.15931e-05H3.66732C2.78359 0.00107016 1.93636 0.3526 1.31146 0.977492C0.686572 1.60238 0.335043 2.44961 0.333984 3.33334V10C0.335043 10.8837 0.686572 11.731 1.31146 12.3559C1.93636 12.9808 2.78359 13.3323 3.66732 13.3333H7.66732ZM1.66732 10V3.33334C1.66732 2.80291 1.87803 2.2942 2.2531 1.91913C2.62818 1.54406 3.13688 1.33334 3.66732 1.33334C3.66732 1.33334 6.94665 1.34268 7.00065 1.34934V2.66668C7.00065 3.0203 7.14113 3.35944 7.39118 3.60949C7.64122 3.85954 7.98036 4.00001 8.33398 4.00001H9.65132C9.65798 4.05401 9.66732 10 9.66732 10C9.66732 10.5304 9.4566 11.0392 9.08153 11.4142C8.70646 11.7893 8.19775 12 7.66732 12H3.66732C3.13688 12 2.62818 11.7893 2.2531 11.4142C1.87803 11.0392 1.66732 10.5304 1.66732 10ZM13.6673 5.33334V12.6667C13.6663 13.5504 13.3147 14.3976 12.6898 15.0225C12.0649 15.6474 11.2177 15.999 10.334 16H4.33398C4.15717 16 3.9876 15.9298 3.86258 15.8048C3.73756 15.6797 3.66732 15.5102 3.66732 15.3333C3.66732 15.1565 3.73756 14.987 3.86258 14.8619C3.9876 14.7369 4.15717 14.6667 4.33398 14.6667H10.334C10.8644 14.6667 11.3731 14.456 11.7482 14.0809C12.1233 13.7058 12.334 13.1971 12.334 12.6667V5.33334C12.334 5.15653 12.4042 4.98696 12.5292 4.86194C12.6543 4.73692 12.8238 4.66668 13.0007 4.66668C13.1775 4.66668 13.347 4.73692 13.4721 4.86194C13.5971 4.98696 13.6673 5.15653 13.6673 5.33334Z" fill="#121212"/>
</svg>
// export const CopyIcon = (props: iconProps) => {
//     const { height, width } = props;
//     return (
//       <svg
//         width={width ? width : "24"}
//         height={height ? height : "24"}
//         viewBox="0 0 24 24"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
//           stroke={primary}
//           strokeWidth="2"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <path
//           d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
//           stroke={primary}
//           strokeWidth="2"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//       </svg>
//     );
//   };