import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, { requestObject } from "../../../../../request/apiRequest/apiRequest";
import { allApis } from "../../../../../request/config";
import { store } from "../../../../store";
import { setLoader } from "../../../constants";
// import  {
//   requestObject,
// } from "../../../../../request/apiRequest/apiRequest";
// import { allApis } from "../../../../../request/config";
export interface userResponse {
  // token: string;
  user_id: number;
  user_email: string;
  permissions: string[];
}
export const restoreUser = createAsyncThunk(
  "userSlice/restoreUser",
  async () => {
    try {
      const token = localStorage.getItem("npc-admin-token");
      const uid = localStorage.getItem("npc-admin-userid");
      if (token && uid) {
        const userBody: requestObject = {
          api: `${allApis.user}${JSON.parse(uid)}/`,
          method: "GET",
        };
        const response: userResponse = await apiRequest(userBody);

    store.dispatch(setLoader({open:false}));
        return  response ?{token:JSON.parse(token), ...response} : null;
      } else {
        return null;
      }
    } catch (error: any) {
      return null;
    }
  }
);
