import { Box, Stack } from "@mui/material";
import  { useEffect, useState } from "react";
import SvgPreview from "../../Components/SvgPreview";
import { HelpIcon, cross } from "../../assets/svgs";
import {
  headerContent,
  subHeading,
} from "../../Components/CustomTexts";
import globalColors from "../../globalColors";
import { rowSB, displayRow, columnCenter } from "../../GlobalStyles";
import ListCard, { itemProp } from "./ListCard";
import CustomIconButton from "../../Components/CustomIconButton";
import { ObjectValues } from "../login";
import NewQuestionsCard from "./NewQuestionsCard";
import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
import { fetchClusterQuestions, fetchTagsData } from "../../store/slices/exams/thunks/fetchContentData";
import { allApis } from "../../request/config";
import { ModuleDetails, allModules } from "../../store/slices/constants/staticContents";
import apiRequest from "../../request/apiRequest/apiRequest";
import ClusterQuestionCreate from "./ClusterQuestionCreate";

function QuestionForm({
  onClear,
  item,
  onSubmit,
  res,
  formData,
  editData,dockedCard
}: {
  item: itemProp;
  onClear(): void;
  onSubmit: any;
  res: any;
  formData: any;
  editData?: any;
  dockedCard?:any;
  
}) {
  const list = [
    "View the questions in a cluster by clicking on the Cluster name",
    "Directly add all the questions of a cluster to Free Question by selecting the Cluster and clicking on Add button or add questions by selecting the question and clicking on Add button.",
    "To preview the questions and answers, hover mouse over the questions.",
    "To add or remove a question select the question and click on Add or Remove depending on the action you want to perform",
  ];
  const dispatch = useAppDispatch();
  const [updateData, setUpdates] = useState<ObjectValues>({});
  const [editQuestions,setEditQuestions]=useState<ObjectValues[]>([])

  const cluster_questions = useAppSelecter(
    (state: ObjectValues) => state.exams.cluster_questions
  );

  const all_clusters = useAppSelecter(
    (state: ObjectValues) => state.exams.all_clusters
  );


  const tags_list = useAppSelecter((state: ObjectValues) => state.exams.tags_list)??[];

  const [createQuestion,setCreateQuestion]=useState<any>({open:false})
  const formatAllData = () => {
    const finalArr: any = [];
    const oldArr: any = [];
    const li_que = updateData ?? undefined;
    if (li_que) {
      const whiteList = li_que.whiteList ?? [];
      const final = li_que.final ?? [];
      const allQues = formData?.cluster_res?.questions ??editQuestions?? [];
      whiteList.map((it: any) => {
        if (allQues.find((ss: { id: any }) => ss.id === it.id)) {
          oldArr.push({
            is_whitelist: true,
            is_priority: it.priority ?? false,
          
            questions: it.id,
          });
        } else {
          finalArr.push({
            is_whitelist: true,
            is_priority: it.priority ?? false,
          
            questions: it.id,
            old_cluster: it.cluster,
          });
        }
        return it
      });
      final.map((it: any) => {
        if (allQues.find((ss: { id: any }) => ss.id === it.id)) {
          oldArr.push({
            is_whitelist: false,
            is_priority: it.priority ?? false,
          
            questions: it.id,
          });
        } else {
          finalArr.push({
            is_whitelist: false,
            is_priority: it.priority ?? false,
          
            questions: it.id,
            old_cluster: it.cluster,
          });
        }
        return it
      });
    }
    return {
      new_questions: finalArr,
      old_questions: oldArr,
      syllabus_tree_cluster: res ? res.id : "",
    };
  };
  const fetchQuestions = (val: ObjectValues) => {
    dispatch(
      fetchClusterQuestions({
        api: `${allApis[allModules[7]].list(null, val.id, null, {
          page: 1,
          size: 10,
        })}`,
        method: "GET",
        payload: {},
        id: val.id,
      })
    );
  };
  useEffect(() => {
    dispatch(fetchTagsData());

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (onSubmit) {
      onSubmit(formatAllData());
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);
  useEffect(() => {
    if (formData) {
      const allQues = formData?.cluster_res?.questions ?? [];
      if (allQues) {
        if (
          allQues.length > 0 &&
          (!updateData.fixlist ||
            (updateData.fixlist &&
              updateData.fixlist.length !== allQues.length))
        ) {
          setUpdates((pre) => {
            return { ...pre, fixlist: allQues };
          });
        }
      }

     if(!formProps&&tags_list.length>0){
         setForm({
                        
        onClear: (data: any ) => {
          setCreateQuestion({open:false})
          if(data&&data.length>0){
            const defaultData=data.find((ss: { res: { default_language: any; translation: { language: any; }; }; })=>ss.res.default_language===ss.res.translation.language)??data[0]
          setUpdates((p)=>{return{...p,final:p.final?
            [...p.final,{...defaultData?.res,text:defaultData?.res.translation?.text??""}]: [{...defaultData?.res,text:defaultData?.res.translation?.text??""}]}})}
        },
        successMessage: (res: ObjectValues) => {
          return ModuleDetails[title].success(
           undefined,
            res
          );
        },
        formatPayload: ModuleDetails[title].formatPayload,
        extraFields: [
              { label: "status", value: "draft" },
              {
                label: "is_active",
                value: true,
              },
            ],
        inputFormArray: ModuleDetails[title].inputArray(
          {
            tags_list: tags_list ?? [],
            ...dockedCard,
            selected_row: null,
          },
         { ...dockedCard,
          list:[formData?.cluster_res??res?.cluster?.id?res:{...res,cluster:{name:formData["Cluster Name"],id:res.cluster}}],
          selected_row:formData?.cluster_res?? res?.cluster?.id?res:{...res,cluster:{name:formData["Cluster Name"],id:res.cluster}}
         }
        ),
        header: title,
        editData:  undefined,
        api: {
          edit: null,
          create: allApis[title].create,
          create2: allApis[title].create2,
        },
        onSubmit: () => {
         
        },
        // onClear: (data:any) => {
        //   createQuestion({open:false})
        //   console.log(data,"response");
        //   setUpdates((p)=>{return{...p,final:[...p.final,{...data,text:data.translation?.text??""}]}})
        // },
      })
    }}

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData,tags_list]);
  useEffect(() => {
    if (editData) {
      const fetchQue = async () => {
        const res = await apiRequest({
          api: allApis.cluster_questions(
            `${editData?.cluster?.id}/?stc-id=${editData.id}`
          ),
          method: "GET",
        });
        setEditQuestions(res.questions)
        setUpdates((p) => {
          return {
            ...p,
            final: res.questions
              ? res.questions.filter(
                  (it: { is_whitelist: boolean }) => it.is_whitelist !== true
                )?.map((s: any)=>{return{...s,priority:s.is_priority&&s.is_priority!==null?true:false}})
              : [],
            whiteList: res.questions
              ? res.questions.filter(
                  (it: { is_whitelist: boolean }) => it.is_whitelist === true
                )?.map((s: any)=>{return{...s,priority:s.is_priority&&s.is_priority!==null?true:false}})
              : [],
          };
        });
      };
    if(!formProps&&tags_list.length>0){
      setForm({
                        
        onClear: (data: any ) => {
          setCreateQuestion({open:false})
     
          if(data&&data.length>0){
            const defaultData=data.find((ss: { res: { default_language: any; translation: { language: any; }; }; })=>ss.res.default_language===ss.res.translation.language)??data[0]
          setUpdates((p)=>{return{...p,final:p.final?
            [...p.final,{...defaultData?.res,text:defaultData?.res.translation?.text??""}]: [{...defaultData?.res,text:defaultData?.res.translation?.text??""}]}})}
        },
        successMessage: (res: ObjectValues) => {
          return ModuleDetails[title].success(
           undefined,
            res
          );
        },
        formatPayload: ModuleDetails[title].formatPayload,
        extraFields: [
              { label: "status", value: "draft" },
              {
                label: "is_active",
                value: true,
              },
            ],
        inputFormArray: ModuleDetails[title].inputArray(
          {
            tags_list: tags_list ?? [],
            ...dockedCard,
      
            selected_row: null,
          },
         { ...dockedCard,
          list:[editData?.cluster?.id?editData:{...res,cluster:{name:editData["Cluster Name"],id:res.cluster}}],
          selected_row:res?editData?.cluster?.id?editData:{...res,cluster:{name:editData["Cluster Name"],id:res.cluster}}:null
         }
        ),
        header: title,
        editData:  undefined,
        api: {
          edit: null,
          create: allApis[title].create,
          create2: allApis[title].create2,
        },
        onSubmit: () => {
         
        },
        // onClear: (data:any) => {
        //   createQuestion({open:false})
        //   console.log(data,"response");
        //   setUpdates((p)=>{return{...p,final:[...p.final,{...data,text:data.translation?.text??""}]}})
        // },
      })}
      fetchQue();
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);
  const title="questions"
const[formProps,setForm]=useState<any>();

  return (
    <Stack spacing="10px" flex={8} overflow={"hidden"}>
      {headerContent({
        size: "1.1rem",
        text: "Choose Questions for Cluster",
        weight: "500",
        sx: {
          color: globalColors.primary.black,
          textTransform: "capitalize",
          width: "100%",
        },
        icons: [
          {
            content: <SvgPreview svg={cross} />,
            onClick: onClear,
            sx: { padding: "0" },
          },
        ],
      })}
      {/* {paragraphs({
        sx: { color: globalColors.primary.black },
        //   size: "1.1rem",
        text: "Select the questions from the cluster and questions pool. You can also directly add the clusters too which will automatically add all the questions from the cluster.",
        //   weight: "500",
        //   sx: {
        //     color: globalColors.primary.black,
        //     textTransform: "capitalize",
        //     width: "100%",
        //   },
      })} */}
      <Stack
        direction={"row"}
        sx={{
          background: globalColors.primary.harvestGold,
          padding: "8px",
          borderRadius: "8px",
          gap: "11px",
        }}
      >
        {HelpIcon}
        <Stack spacing="5px">
          {list.map((msg) => (
            <Box sx={{ gap: "5px", ...displayRow }}>
              <Box
                sx={{
                  background: globalColors.primary.black,
                  borderRadius: "50px",
                  height: "6px",
                  width: "6px",
                  margin: "3.4px 0",
                  minWidth: "6px",
                }}
              ></Box>
              {subHeading({
                text: msg,
                sx: {
                  width: "100%",
                  whiteSpace: "normal",
                  lineHeight: "0.8rem",
                },
                size: "0.7rem",
              })}
            </Box>
          ))}
        </Stack>
      </Stack>
      <Box
        // className="dashed-border"
        sx={{ width: "100%", margin: "10px 0" }}
      ></Box>
      <Stack
        sx={{ flex: 8, ...rowSB, mt: "5px !important", overflowY: "hidden" }}
        direction="row"
      >
        <ListCard
          item={{
            ...item,
            list: all_clusters
              ? all_clusters.filter(
                  (cl: { name: any }) => cl.name !== formData["Cluster Name"]
                )
              : [],
          }}
          setUpdates={(val) => {
            setUpdates((p) => {
              return {
                ...p,
                cluster: val,
                // [val.id]: { add: [], remove: [], whiteList: [], final: [] },
              };
            });
            fetchQuestions(val);
          }}
          //
          selected={updateData.cluster ?? null}
        />
        <ListCard
          selected={updateData?.add ?? []}
          setUpdates={(item) => {
            const addObj = updateData?.add ?? [];
            const oldFinal = updateData?.final ?? [];
            const oldWhitelist = updateData?.whiteList ?? [];
            let index = addObj.findIndex((obj: any) => obj.id === item.id);
            if (index !== -1) {
              addObj.splice(index, 1);
            } else {
              addObj.push(item);
            }
            setUpdates((prev) => {
              return {
                ...prev,

                add: addObj,
                whiteList: oldWhitelist.filter(
                  (s: { id: any }) => s.id !== item.id
                ),
                final: oldFinal.filter((s: { id: any }) => s.id !== item.id),
              };
            });
          }}
          item={{
            list: cluster_questions[updateData.cluster?.id]
              ? cluster_questions[updateData.cluster?.id].results.map(
                  (li: any) => {
                    return {
                      ...li,
                      name:
                        li?.translation.find(
                          (item: { language: any }) =>
                            item.language === li?.default_language
                        )?.text ?? "",
                    };
                  }
                ) ?? []
              : [],
            selected_row: null,
            primary: "name",
            title: "Question",
          }}
        />
        <Box sx={{ height: "100%", ...columnCenter, gap: "15px" }}>
          <CustomIconButton
            key="Add"
            variant="outlined"
            content="Add"
            sx={{ minWidth: "100px" }}
            onClick={() => {
              const newObj = updateData?.add ?? [];
              setUpdates((prev) => {
                const check = prev;
                return {
                  ...prev,
                  final: [
                    ...prev.final,
                    ...newObj.filter(
                      (ss: any) =>
                        !check.whiteList.find(
                          (data: any) => data.id === ss.id
                        ) &&
                        !check.final.find((data: any) => data.id === ss.id) &&
                        !check.fixlist?.find((data: any) => data.id === ss.id)
                    ),
                  ],

                  // &&
                  // !prev.final.find((data:any)=>data.id===ss.id)
                  // )}
                };
              });
            }}
            disabled={
              updateData?.add?.length > 0
                ? updateData?.add.filter((it: { id: any }) => {
                    return updateData?.final.find(
                      (ss: { id: any }) => ss.id === it.id
                    )
                      ? false
                      : updateData?.whiteList.find(
                          (ss: { id: any }) => ss.id === it.id
                        )
                      ? false
                      : true;
                  }).length === 0
                : true
              // sum([
              //   updateData?.final?.length,
              //   updateData?.whiteList?.length,
              // ])
            }
          />
          {/* <CustomIconButton
            key="Remove"
            variant="outlined"
            content="Remove"
            sx={{ minWidth: "100px" }}
            disabled={
              updateData[updateData.cluster?.cluster.id]?.remove
                ? updateData[updateData.cluster?.cluster.id]?.remove.length ===
                  0
                : true
            }
            onClick={() => {
              setUpdates((prev) => {
                const check = prev[updateData.cluster?.cluster.id];
                const newObj =
                  updateData[updateData.cluster?.cluster.id]?.remove ?? [];

                const filteredArray = check.final.filter((item: any) =>
                  newObj.find((ss: { id: any }) => ss.id !== item.id)
                );
                const filteredWArray = check.whiteList.filter((item: any) =>
                  newObj.find((ss: { id: any }) => ss.id !== item.id)
                );

                return {
                  ...prev,
                   ...{
                    final: filteredArray,
                    remove: [],
                    whiteList: filteredWArray,
                  },
                };
              });
            }}
          /> */}
        </Box>
        {createQuestion.open?<ClusterQuestionCreate
                   formProps={formProps}

                  />:""}
        <NewQuestionsCard
        setCreateQuestion={setCreateQuestion}
          item={{
            title: `${formData["Cluster Name"] ?? ""} Questions`,
        
            white_list: {
              list: updateData?.whiteList ?? [],
              selected_row: null,
              primary: "name",
              onClick: (value, priority) => {
                setUpdates((p) => {
                  return priority
                    ? {
                        ...p,
                        ...{
                          whiteList: p.whiteList?.map((it: { id: any }) => {
                            return it.id === value.id
                              ? { ...it, priority: !value.priority }
                              : { ...it };
                          }),
                        },
                      }
                    : {
                        ...p,
                        ...{
                          final: [...(p.final ?? []), value],
                          whiteList: p.whiteList?.filter(
                            (it: { id: any }) => it.id !== value.id
                          ),
                        },
                      };
                });
              },
              title: "New Cluster Questions",
            },
            free: {
              list: updateData?.final ?? [],
              fixlist: updateData.fixlist ?? [],
              selected_row: null,
              primary: "name",
              onClick: (value, priority) => {
                setUpdates((p) => {
                  return priority
                    ? {
                        ...p,
                        ...{
                          final: p.final?.map((it: { id: any }) => {
                            return it.id === value.id
                              ? { ...it, priority: !value.priority }
                              : { ...it };
                          }),
                          fixlist: p.fixlist?.map((it: { id: any }) => {
                            return it.id === value.id
                              ? { ...it, priority: !value.priority }
                              : { ...it };
                          }),
                        },
                      }
                    : {
                        ...p,
                        ...{
                          whiteList: [...(p.whiteList ?? []), value],
                          final: p.final?.filter(
                            (it: { id: any }) => it.id !== value.id
                          ),
                          fixlist: p.fixlist?.filter(
                            (it: { id: any }) => it.id !== value.id
                          ),
                        },
                      };
                });
              },
              title: "New Cluster Questions",
            },
          }}
        />
      </Stack>
    </Stack>
  );
}

export default QuestionForm;
