// var git = require('../git-data.json');

/**
 * Copies the content of an element x as the content of an element y.
 * @param {String} x Identifier of the element to take its content.
 * @param {String} y Identifier of the element where to copy the content of x.
 */
export function copyContentFromxToy(x, y) {
  const content = document.getElementById(x).innerHTML;
  document.getElementById(y).innerHTML = content;
}

/**
 * Sets the editor, the wiris plugins version, the GitHub branch and commit on the front end page.
 * @param {*} editorVersion Version of the current editor.
 * @param {*} wirisVersion Version of the wiris plugin used.
 */
export function setEditorAndWirisVersion(editorVersion = 0, wirisVersion = 0) {
  if (wirisVersion !== 0) document.getElementById('version_wiris').innerHTML += wirisVersion; 
  if (editorVersion !== 0) document.getElementById('version_editor').innerHTML += editorVersion;
  // document.getElementById('git_branch').innerHTML += 'Branch: ' + git.branch;
  // document.getElementById('git_commit').innerHTML += 'Commit hash: ' + git.hash;
}

/**
 * Takes the editor content,
 * Transforms it into an image,
 * Places it on a reserved containder identified with and idContainer.
 * @param {*} editorContent Content of the editor to be transformed.
 * @param {String} containerId Identifier of the container place.
 */
export function updateContent(editorContent, containerId) {
  document.getElementById(containerId).innerHTML = editorContent;
  com.wiris.js.JsPluginViewer.parseElement(document.getElementById(containerId)); //eslint-disable-line
}

// Initial content for the editors that use <math> for loading content
export const editorContentMathML = ``;

// Initial content for the editors that use <img> for loading content
export const editorContentImg = ``;
