import React from "react";
import { Navigate, useLocation } from "react-router";

export interface onlyChildProps {
  children: React.ReactElement<any, any> | null;
}

const RequiresVerification: React.FC<onlyChildProps> = ({
  children,
}) => {
  const location = useLocation();
  const token=localStorage.getItem("npc-admin-token");
  // const userStatus :any= useAppSelecter((state) => state);
  return token  
  ? (
    children
  ) : (
    <Navigate
      to={"/login"}
      replace
      state={{ destinationPathname: location.pathname }}
    />
  );
};

export default RequiresVerification;
