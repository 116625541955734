import { useEffect, useState } from "react";
import CustomDialog from "../CustomDialog";
import { rowSB } from "../../GlobalStyles";
import {
  Box,
  Stack,
} from "@mui/material";
import globalColors from "../../globalColors";
import { subHeading } from "../CustomTexts";
import CustomSearch from "../CustomSearch";
import { ObjectValues } from "../../pages/login";
import DraggableListComponent from "./DraggableListComponent";

function PathOrderDialog({ onClose, list, onSave ,title}: any) {
  const [tableData, setTableData] = useState<ObjectValues>({
    actual: [],
    copy: [],
  });
  useEffect(() => {
    setTableData({
      actual: [],
      copy: list,
      sort: list,
    });// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <CustomDialog
      contentsx={{
        padding: "0 !important",
      }}
      actionsx={rowSB}
      customHeader={
        <Box
          sx={{
            background: globalColors.primary.black,
            // width: "",
            flex: "8",
            padding: "10px 8px",
            color: globalColors.primary.pureWhite,
            borderRadius: "0 0 8px 8px"
          }}
        >
          {subHeading({
            text: `Arrange ${title} Path Order`,
            sx: { padding: "0 5px" },
          })}
        </Box>
      }
      open={true}
      content={
        <Stack
          spacing="10px"
          width="350px"
          sx={{ padding: "15px 0", height: "calc(100vh - 200px)" }}
        >
          <Box sx={{ padding: "0 15px", width: "calc(100% - 30px)" }}>
          
            <CustomSearch
              copyData={tableData.copy}
              fieldName={["name"]}
              filteredData={(array: ObjectValues[]) => {
                setTableData((prev) => {
                  return { ...prev, actual: array };
                });
              }}
              onClear={() => {
                setTableData((prev) => {
                  return { ...prev, actual: [] };
                });
              }}
            />
          </Box>
          <Box
            sx={{
              height: "calc(100% - 40px)",
              width: "100%",
              overflowY: "auto",
            }}
            className="scrollBluePrimarythin"
          >
            {tableData.copy.length > 0 ?
             <DraggableListComponent updateItem={(newList: ObjectValues[]) => {
              setTableData((p) => { return { ...p, sort: newList } })
            }} list={tableData.copy.sort((a: { path_order: any; },b: { path_order: any; })=>a.path_order-b.path_order)} selected={tableData.actual} /> : ""}
          </Box>
        </Stack>
      }
      onClose={onClose}
      actions={[
        {
          sx: { width: "130px", },
          variant: "outlined",
          onClick: onClose,
          content: "Cancel",
          // disabled: dialog.loading,
        },
        {
          sx: { width: "130px", },
          variant: "contained",
          onClick: () => {
            onSave(tableData.sort)
          },
          content: "Save",
          // disabled: dialog.loading,
        },
      ]}
    />
  );
}

export default PathOrderDialog;
