import { useState } from "react";
import CustomIconButton from "../CustomIconButton";
import { Box, } from "@mui/material";
import { ObjectValues } from "../../pages/login";
import PathOrderDialog from "./PathOrderDialog";
import { allApis } from "../../request/config";
import apiRequest from "../../request/apiRequest/apiRequest";
import { useAppSelecter } from "../../hooks/storeHooks";
import { structureProps } from "../../store/slices/constants/staticContents";

function PathOrderManagment({ list,title,onClear }: { list: any[],title:string,onClear:any }) {
  const [dialog, setDialog] = useState<ObjectValues>({});
  const examsData = useAppSelecter((state: ObjectValues) => state.exams);
  const dockedCard: structureProps = examsData.docked_exams_table;
   return (
    <Box>
      {dialog.open && (
        <PathOrderDialog
          list={list}title={title}
          onClose={() => {
            setDialog({})
          }}
          onSave={
            async (new_list: ObjectValues[]) => {
              const initialPath=list.sort((a: { path_order: any; },b: { path_order: any; })=>a.path_order-b.path_order)[0].path_order??0
              
              const response: any = await apiRequest({
                api: `${allApis.path_order}?model=${dockedCard.title === "goals" ? "goal" : "syllabus_tree_node"}`,
                payload: new_list.map((id, i) => { return { id: id.id, path_order: initialPath + i } }),
                method: "PATCH",
              });
              if (response.error) {
                await setDialog({})
              } else {
                onClear()
                setDialog({})

              }
            }
          }
        />
      )}
     {list?.length>1&& <CustomIconButton
        content="Arrange Path Order"
        variant="outlined"
        // sx={{ padding: "4px 15px" }}
        onClick={() => {
          setDialog({ open: true });
        }}
      />}
    </Box>
  );
}

export default PathOrderManagment;
