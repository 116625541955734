import React from "react";
import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
import { ObjectValues } from "../../pages/login";
import { hideSnack } from "../../store/slices/constants";
import CustomSnackbar from "../../Components/CustomSnackbar";
import { onlyChildProps } from "../../store/slices/constants/requiresVerification";

const SnackbarProvider: React.FC<onlyChildProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { open, message ,severity} = useAppSelecter((state: ObjectValues) => state.constantSlice.snackbar);

  const handleClose = () => {
    dispatch(hideSnack());
  };

  return (
    <React.Fragment>
      <CustomSnackbar severity={severity} open={open} message={message} onClose={handleClose} />
      {children}
    </React.Fragment>
  );
};

export default SnackbarProvider;
