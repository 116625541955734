import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ObjectValues, inputProp } from '../../pages/login';
import CustomTextField from '../CustomTextfield';
import QuestionTag from '../../pages/exams/QuestionTag';
import { Chip } from '@mui/material';
const filter = createFilterOptions<ObjectValues>();

export default function MultipleAutoComplte({list,
    listLabel,
    freeSolo,
    helperText,
    error,
    onChange,
    disabled,
    value,multiple
  }: inputProp){
    const[newVal,setnewval]=React.useState<any>()
const newValue=value?.length>0?value.find((ss: { inputValue: any; })=>ss&&ss.inputValue):null;
    React.useEffect(()=>{
      if(newValue&&list&&list.length>0&&newValue!==null&&value?.length>0){
        const newObj=list.find((item)=>item[listLabel??"name"]===newValue.inputValue)
      if(onChange){
        const allVal=value.filter((ss: { inputValue: any; })=>!ss.inputValue)
        allVal.push(newObj)
        onChange(allVal)
      }
      }
    },[list])
  return (<> 
    <QuestionTag
           value={newVal}
           />
    <Autocomplete
      multiple
      limitTags={2}
      id="multiple-limit-tags"  
      disabled={disabled ?? false}
      value={value?value?.length>0&&newValue===null?value:value.filter((item: { inputValue: any; })=>!item.inputValue):[]}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
   
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option[listLabel??""]
        );
        if (inputValue !== "" && !isExisting && freeSolo) {
          // if(freeSolo.dialog){
          //   setDialog({open:true,value:inputValue})
          // }else{
          filtered.push({
            inputValue,
            [listLabel??""]: `Add ${inputValue} ${freeSolo.addText??""}`,
          });
        // }
        }
   
        return filtered;
      }}
      onChange={(event, newValue:any) => {

        const last=newValue[newValue.length-1]
        if (onChange) {
          onChange( newValue
          );
        }
        if (last?.inputValue) {
            setTimeout(() => {
              setnewval(last?.inputValue)
            });
        }
        
       }}
      options={list ?list.map((option) => option): []}
      freeSolo={true}
      // options={list ?? []}
      // getOptionLabel={(option) => option[listLabel??"name"]}
      renderTags={(value: any, getTagProps) =>
       {
        return value.map((option: string, index: number) => (
          <Chip variant="outlined" label={option[listLabel??"" as any]} {...getTagProps({ index })} />
        ))}
      }
   getOptionLabel={(option:any) => {
    
     if (typeof option === "string") {
       return option;
     }
     return option[listLabel??""];
   }}
      sx={{
        width: "100%",
        padding: "0",
        "& .MuiInputBase-root": { padding: "10px 12px" },
        "& .MuiOutlinedInput-input": {
          padding: "0 !important",
        },
      }}
    //   defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
    renderInput={(params) => (
        <CustomTextField
          {...params}
          helperText={helperText}
          error={error}
          disabled={disabled ?? false}
        />
      )}
    />
    </>
  );
}
