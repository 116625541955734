import { useEffect, useState } from "react";
import { Box, Drawer } from "@mui/material";
import { ObjectValues, inputProp } from "../../login";
import FormComponent, { formCompProps } from "../../../Components/FormComponent";
import QuestionFormComponentExams from "../QuestionFormComponentExams";
import { allApis } from "../../../request/config";
import { fetchTagsData } from "../../../store/slices/exams/thunks/fetchContentData";
import { useAppDispatch } from "../../../hooks/storeHooks";

function ClusterQuestionCreate({
  formProps,
  row,
  onClose,
}: {
  formProps: formCompProps;
  row?: ObjectValues;
  onClose?(data?:any): void;
}) {
    const dispatch = useAppDispatch();
  
  const [dialog, setDialog] = useState<ObjectValues>({open:true});

  const drawer = (
    <Drawer
      transitionDuration={500}
      anchor={"right"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "10px 0 0 10px",
          width: "80%",
        },
      }}
      open={dialog.open}
      onClose={() => {
        setDialog({});
        if (onClose) {
          onClose();
        }
      }}
    >
      {" "}
      {/* <Slide
  direction={dialog.open ? "left" : "right"}
  in={dialog.open}
  mountOnEnter
  unmountOnExit
> */}
      <Box
        sx={{
          borderRadius: "10px 0 0 10px",
          padding: "20px 12px",
          width: "calc(100% - 24px)",
          height: "calc(100% - 40px)",
          // backgroundColor: "white",
          // boxShadow:
          //   "0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)",
        }}
      >
        <QuestionFormComponentExams
           getApi={(id: string) => allApis["questions"].item(id)}
             {...formProps}
             fromCluster={true}
              editData={undefined}
              onClear={(data?:any) => {
                  setDialog({});
                  if (onClose) {
                      onClose(data);
                  }
                  formProps.onClear(data)
              } }        />
      </Box>
      {/* </Slide> */}
    </Drawer>
  );
  return drawer
}

export default ClusterQuestionCreate;
