import { RouterProvider } from "react-router-dom";
import router from "./store/slices/constants/Router";
import { useEffect } from "react";
import { useAppDispatch } from "./hooks/storeHooks";
import { restoreUser } from "./store/slices/userManagement/thunks/restoreUser";
function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(restoreUser());
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
        //  localStorage.setItem("npc-admin-token", JSON.stringify("qwqwe"));
        //  localStorage.setItem("npc-admin-userid", JSON.stringify("qed"));
  return <RouterProvider router={router} />;
}

export default App;
