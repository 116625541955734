import React from "react";
import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
import { ObjectValues } from "../../pages/login";
import { onlyChildProps } from "../../store/slices/constants/requiresVerification";
import GlobalLoader from "../../Components/GlobalLoader";

const GlobalLoaderProvider: React.FC<onlyChildProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { open, sx ,backdrop_sx} = useAppSelecter((state: ObjectValues) => state.constantSlice.global_loader);

  return (
    <React.Fragment>
      <GlobalLoader  open={open} sx={sx??{}} backdrop_sx={backdrop_sx??{}} />
      {children}
    </React.Fragment>
  );
};

export default GlobalLoaderProvider;
