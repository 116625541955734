import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, {  requestObject } from "../../../../../request/apiRequest/apiRequest";
import { allApis } from "../../../../../request/config";
import moment from "moment";
export interface userResponse{
}
export const fetchHistory = createAsyncThunk(
  "exams/fetchHistory",
  async ({row_id,content_id}:{row_id:string,content_id:number}) => {
    try {
      const response: any = await apiRequest({api:allApis.status_log(row_id,content_id),method:"GET"});
      console.log(response)
      return {
        [row_id]:response?.length > 0 ? response?.sort((a: any, b: any) => {

          var dateA: any = new Date(
            moment(new Date(b.modified_at ?? b.created_at)).format("MMM D YYYY h:mm:ss A")
          );
          var dateB: any = new Date(
            moment(new Date(a.modified_at ?? a.created_at)).format("MMM D YYYY h:mm:ss A")
          );

          return dateA - dateB;
        }) : []
      };
    } catch (error: any) {
      return {}
    }
  }
);
