import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, {  requestObject } from "../../../../../request/apiRequest/apiRequest";
export interface userResponse{
}
export const fetchTableData = createAsyncThunk(
  "exams/fetchTableData",
  async (props: requestObject, ) => {
    console.log(props)
    try {
      if(props.token == "questions" || props.token == "mockquestion") {
        const response: any = await apiRequest(props ?? {});
        // console.log("exam rssposne",response)
        let newResponse = {...response, language_available: response.languages_available, module: props.token, totalCount: response.count}
        // if (newResponse?.results) {
        //   return newResponse?.results?.map((r: any) => ({...r, module: props.token, totalCount: response.count
        //     }))
        // }
        return newResponse;
      }
      if(props.token == "section" || props.token == "mocksection") {
        const response: any[] = await apiRequest(props ?? []);
        const section = response?.map((r: any) => ({...r.section, id: r.id}))
        // console.log(section)
        if (section) {
          return section;
        }
        return null;
      }
      const response: any[] = await apiRequest(props ?? []);
      if (response) {
        // console.log("exam rssposne",response)
        const data = response.map((r: any) => ({...r, module: props.token }));
        return data?.length > 0? data : [{ module: props.token}]
      }
      return null;
    } catch (error: any) {
      return null
    }
  }
);
