import { Stack } from '@mui/material';
import { rowCenter } from '../../GlobalStyles';
import { heading } from '../../Components/CustomTexts';
import globalColors from '../../globalColors';
import { allStaticText } from '../../store/slices/constants/staticContents/AllTexts';
function ComingSoon() {
  return (
    <Stack height={"100%"} width="100%" sx={rowCenter} color={globalColors.primary.grey}>
     {heading({text:allStaticText.defaultTexts.comingSoon})} </Stack>
  )
}

export default ComingSoon