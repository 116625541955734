import { CustomListItem } from "../CustemListItem";
import globalColors from "../../globalColors";
import { Box, ListItemButton, ListItemText } from "@mui/material";
import { draggabledots } from "../../assets/svgs";
import  { useState,useEffect } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

const DraggableListComponent = ({ list ,selected,updateItem}: any) => {
  const [items, setItems] = useState(list??[]);
  
  useEffect(()=>{
    setItems(list??[])
  },[list])

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedItems = Array.from(items);
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(updatedItems);
    updateItem(updatedItems)
  };
const checkIfIncludes=(list: any[],id: any)=>{
  return list.map((l)=>l.id).includes(id)
}
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <ul {...provided.droppableProps} ref={provided.innerRef}>
            {/* .sort((a: { path_order: any; },b: { path_order: any; })=>a.path_order-b.path_order) */}
            {items.map((li: { id: any; name: any; }, index: number) => (
              <Draggable key={li.id} draggableId={li.id} index={index}>
                {(provided) => (<Box>
                    <CustomListItem
                  
                     {...provided.draggableProps}
                     {...provided.dragHandleProps}
                     ref={provided.innerRef}
 
                      alignItems="flex-start"
                      sx={{
                       background: checkIfIncludes(selected,li.id) ? globalColors.primary.lightGrey: "",
        color: globalColors.primary.black,
                        borderRadius: "7px",
                        transition: "background-color 0.3s ease", // Optional: Add a transition for smoother effect
                        "&:hover":  checkIfIncludes(selected,li.id)
                        ? {background: globalColors.primary.lightGrey, // Change the background color on hover
                        color: globalColors.primary.black,borderRadius: "7px", }
                        : {
                          background: globalColors.primary.lightGrey, // Change the background color on hover
                          color: globalColors.primary.black,
                          borderRadius: "7px", // Change the text color on hover
                        },
                        // mb: "5px",
                        p: "0 15px",
                        minHeight: "42px",
                        maxHeight: "42px",
                        alignItems: "center",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <ListItemButton
                        // {...getItemProps(li.id)}
                        sx={{
                          height: "42px",
                          width: "100%",
                          padding: "0 5px !important",
                          borderRadius: "0 !important",
                          background: "transparent !important",
                        }}
                      >
                        <Box sx={{ marginRight: "5px" }}>{draggabledots}</Box>
                        <ListItemText
                          sx={{ ...{ m: "0", p: "0" } }}
                          primary={li.name ?? ``}
                        />
                      </ListItemButton>
                    </CustomListItem>     
                       {index + 1 !== items.length && (
          <Box className="dashed-border" />
        )}</Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableListComponent;
