export const HelpIcon = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C11.9778 20 13.9112 19.4135 15.5557 18.3147C17.2002 17.2159 18.4819 15.6541 19.2388 13.8268C19.9957 11.9996 20.1937 9.98891 19.8079 8.0491C19.422 6.10929 18.4696 4.32746 17.0711 2.92894C15.6725 1.53041 13.8907 0.578004 11.9509 0.192152C10.0111 -0.193701 8.00043 0.00433286 6.17317 0.761209C4.3459 1.51809 2.78412 2.79981 1.6853 4.4443C0.58649 6.08879 0 8.02219 0 10C0.00286757 12.6513 1.05736 15.1932 2.9321 17.0679C4.80684 18.9426 7.34872 19.9971 10 20ZM10 4.16667C10.2472 4.16667 10.4889 4.23998 10.6945 4.37733C10.9 4.51469 11.0602 4.70991 11.1549 4.93832C11.2495 5.16672 11.2742 5.41806 11.226 5.66053C11.1778 5.90301 11.0587 6.12574 10.8839 6.30055C10.7091 6.47537 10.4863 6.59442 10.2439 6.64265C10.0014 6.69088 9.75005 6.66613 9.52165 6.57152C9.29324 6.47691 9.09801 6.3167 8.96066 6.11113C8.82331 5.90557 8.75 5.6639 8.75 5.41667C8.75 5.08515 8.8817 4.76721 9.11612 4.53279C9.35054 4.29837 9.66848 4.16667 10 4.16667ZM9.16667 8.33334H10C10.442 8.33334 10.866 8.50893 11.1785 8.82149C11.4911 9.13405 11.6667 9.55798 11.6667 10V15C11.6667 15.221 11.5789 15.433 11.4226 15.5893C11.2663 15.7455 11.0543 15.8333 10.8333 15.8333C10.6123 15.8333 10.4004 15.7455 10.2441 15.5893C10.0878 15.433 10 15.221 10 15V10H9.16667C8.94565 10 8.73369 9.91221 8.57741 9.75593C8.42113 9.59965 8.33333 9.38768 8.33333 9.16667C8.33333 8.94566 8.42113 8.7337 8.57741 8.57742C8.73369 8.42114 8.94565 8.33334 9.16667 8.33334Z"
      fill="#FAA860"
    />
  </svg>
);
export const  smallHelpIcon = (

<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1247_10362)">
<path d="M6 12.5C7.18669 12.5 8.34673 12.1481 9.33342 11.4888C10.3201 10.8295 11.0892 9.89246 11.5433 8.7961C11.9974 7.69975 12.1162 6.49335 11.8847 5.32946C11.6532 4.16558 11.0818 3.09648 10.2426 2.25736C9.40353 1.41825 8.33443 0.846802 7.17054 0.615291C6.00666 0.38378 4.80026 0.5026 3.7039 0.956726C2.60754 1.41085 1.67047 2.17989 1.01118 3.16658C0.351894 4.15328 0 5.31331 0 6.5C0.00172054 8.09077 0.634414 9.6159 1.75926 10.7407C2.88411 11.8656 4.40923 12.4983 6 12.5ZM6 3C6.14834 3 6.29334 3.04399 6.41668 3.1264C6.54002 3.20881 6.63614 3.32595 6.69291 3.46299C6.74968 3.60003 6.76453 3.75083 6.73559 3.89632C6.70665 4.04181 6.63522 4.17544 6.53033 4.28033C6.42544 4.38522 6.2918 4.45665 6.14632 4.48559C6.00083 4.51453 5.85003 4.49968 5.71299 4.44291C5.57594 4.38615 5.45881 4.29002 5.3764 4.16668C5.29399 4.04334 5.25 3.89834 5.25 3.75C5.25 3.55109 5.32902 3.36032 5.46967 3.21967C5.61032 3.07902 5.80109 3 6 3ZM5.5 5.5H6C6.26522 5.5 6.51957 5.60536 6.70711 5.7929C6.89464 5.98043 7 6.23479 7 6.5V9.5C7 9.63261 6.94732 9.75979 6.85355 9.85356C6.75979 9.94732 6.63261 10 6.5 10C6.36739 10 6.24022 9.94732 6.14645 9.85356C6.05268 9.75979 6 9.63261 6 9.5V6.5H5.5C5.36739 6.5 5.24022 6.44732 5.14645 6.35356C5.05268 6.25979 5 6.13261 5 6C5 5.86739 5.05268 5.74022 5.14645 5.64645C5.24022 5.55268 5.36739 5.5 5.5 5.5Z" fill="#C8C8C8"/>
</g>
<defs>
<clipPath id="clip0_1247_10362">
<rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>

);
export const addQuestion=
<svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.24935 10.5003C1.82852 10.5003 0.666016 11.6628 0.666016 13.0837V46.667C0.666016 49.5087 2.99102 51.8337 5.83268 51.8337H39.416C40.8369 51.8337 41.9994 50.6712 41.9994 49.2503C41.9994 47.8295 40.8369 46.667 39.416 46.667H8.41602C6.99518 46.667 5.83268 45.5045 5.83268 44.0837V13.0837C5.83268 11.6628 4.67018 10.5003 3.24935 10.5003ZM47.166 0.166992H16.166C13.3243 0.166992 10.9993 2.49199 10.9993 5.33366V36.3337C10.9993 39.1753 13.3243 41.5003 16.166 41.5003H47.166C50.0077 41.5003 52.3327 39.1753 52.3327 36.3337V5.33366C52.3327 2.49199 50.0077 0.166992 47.166 0.166992ZM41.9994 23.417H34.2494V31.167C34.2494 32.5878 33.0868 33.7503 31.666 33.7503C30.2452 33.7503 29.0827 32.5878 29.0827 31.167V23.417H21.3327C19.9118 23.417 18.7493 22.2545 18.7493 20.8337C18.7493 19.4128 19.9118 18.2503 21.3327 18.2503H29.0827V10.5003C29.0827 9.07949 30.2452 7.91699 31.666 7.91699C33.0868 7.91699 34.2494 9.07949 34.2494 10.5003V18.2503H41.9994C43.4202 18.2503 44.5827 19.4128 44.5827 20.8337C44.5827 22.2545 43.4202 23.417 41.9994 23.417Z" fill="#C8C8C8"/>
</svg>
export const playList=
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.33398 4C3.33398 3.724 3.55798 3.5 3.83398 3.5H13.1673C13.4433 3.5 13.6673 3.724 13.6673 4C13.6673 4.276 13.4433 4.5 13.1673 4.5H3.83398C3.55798 4.5 3.33398 4.276 3.33398 4ZM7.16732 11.5H3.83398C3.55798 11.5 3.33398 11.724 3.33398 12C3.33398 12.276 3.55798 12.5 3.83398 12.5H7.16732C7.44332 12.5 7.66732 12.276 7.66732 12C7.66732 11.724 7.44332 11.5 7.16732 11.5ZM13.1673 6.16667H3.83398C3.55798 6.16667 3.33398 6.39067 3.33398 6.66667C3.33398 6.94267 3.55798 7.16667 3.83398 7.16667H13.1673C13.4433 7.16667 13.6673 6.94267 13.6673 6.66667C13.6673 6.39067 13.4433 6.16667 13.1673 6.16667ZM7.16732 8.83333H3.83398C3.55798 8.83333 3.33398 9.05733 3.33398 9.33333C3.33398 9.60933 3.55798 9.83333 3.83398 9.83333H7.16732C7.44332 9.83333 7.66732 9.60933 7.66732 9.33333C7.66732 9.05733 7.44332 8.83333 7.16732 8.83333ZM13.1673 10.1667H11.6673V8.66667C11.6673 8.39067 11.4433 8.16667 11.1673 8.16667C10.8913 8.16667 10.6673 8.39067 10.6673 8.66667V10.1667H9.16732C8.89132 10.1667 8.66732 10.3907 8.66732 10.6667C8.66732 10.9427 8.89132 11.1667 9.16732 11.1667H10.6673V12.6667C10.6673 12.9427 10.8913 13.1667 11.1673 13.1667C11.4433 13.1667 11.6673 12.9427 11.6673 12.6667V11.1667H13.1673C13.4433 11.1667 13.6673 10.9427 13.6673 10.6667C13.6673 10.3907 13.4433 10.1667 13.1673 10.1667Z" fill="#838383"/>
</svg>;