import SvgPreview from "../SvgPreview";
import { heading } from "../CustomTexts";
import { Box, Stack } from "@mui/material";
import globalColors from "../../globalColors";
import HeaderProfile from "../HeaderProfile";
import { rowCenter } from "../../GlobalStyles";
import { allStaticText } from "../../store/slices/constants/staticContents/AllTexts";

function CustomHeader() {
  return (
    <Stack
      spacing={"15px"}
      direction="row"
      justifyContent={"space-between"}
      padding={"10px 40px"}
      sx={{ background: globalColors.primary.pureWhite,boxShadow:"0px 4px 16px 0px rgba(0, 0, 0, 0.05)" }}
    >
      <Box sx={{...rowCenter}}>
      <SvgPreview
        sx={{ height: "32px", width: "32px",marginRight:"20px" }}  
        svg={allStaticText.LoginContents.logo}
      />
      {heading({ text: allStaticText.appName, size: "1.5rem", weight: 400 })}
      </Box>
      <HeaderProfile/>
    </Stack>
  );
}

export default CustomHeader;
