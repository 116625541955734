import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from "@mui/material";
import globalTheme from "./globalTheme";
import { Provider } from "react-redux";
import { store } from "./store/store";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// eslint-disable-next-line react/no-array-index-key

root.render(
  // <React.StrictMode>
   <Provider store={store}>
      <ThemeProvider theme={globalTheme}>
        <App />
      </ThemeProvider>
    </Provider>
  // </React.StrictMode>
);
reportWebVitals();
