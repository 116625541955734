import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
  Paper, Tabs, Tab,
  Chip,
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppSelecter } from "../hooks/storeHooks";
import { RootState } from "../store/store";
import PreviewComponent from "../pages/mockTest/QuestionFormComponentMock/previewComponent";

// Define types for the data structure
interface Option {
  label: string;
  text: string;
  isCorrect?: boolean; // Optional, only present for the correct answer
}


interface QuestionPreviewProps {
  questionPreviewData: []; // Ensure this is passed
  language_available: string[]; // Define language_available as an array of strings
}


const QuestionPreview: React.FC<QuestionPreviewProps> = ({
  // questionPreviewData,
  language_available,
}) => {

  const questionPreviewData = useAppSelecter(
    (state: RootState) => state.contentFeedback.questionEditData
  );

  const [selectedLanguage, setSelectedLanguage] = React.useState<number>(0);

  const handleLanguageChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedLanguage(newValue);
  };


  return (
    <Box sx={{ maxHeight: "300vh" }}>
      <Tabs value={selectedLanguage} onChange={handleLanguageChange} aria-label="language tabs">
        <Tab label="English" />
        {language_available?.includes('hindi') && (
          <Tab label="Hindi" />
        )}
      </Tabs>
        <Card variant="outlined" sx={{ maxWidth: 700, margin: "auto", mt: 4 }}>
          {/* <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <img src={image} alt="Question" style={{ maxWidth: "100%", height: "auto" }} />
          </Box> */}
          <CardContent>
            <Typography variant="body2">
            <PreviewComponent
          htmlContent={
            questionPreviewData?.translation &&
            questionPreviewData?.translation.find(
              (trans: any) => trans.language === (selectedLanguage === 0 ? "english" : "hindi")
            )?.text
          }
        />
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup>
                <Grid container spacing={2}>
                  {questionPreviewData?.options?.map((option: any, index: number) => (
                    <Grid item xs={12} key={option.order}>
                      <div style={{ display: "flex", alignItems: "center", fontSize: "15px", fontWeight: "100", border: "1px solid grey", borderRadius: "5px", padding: "10px", marginTop: "15px" }}>
                      {['A', 'B', 'C', 'D'][index]}.&nbsp;<PreviewComponent
                    htmlContent={
                      option.translation.find(
                        (trans: any) => trans.language === (selectedLanguage === 0 ? "english" : "hindi")
                      )?.text
                    }
                  />
                        <div style={{ marginLeft: "auto" }}>
                          {option.is_correct ? (
                            <CheckCircleIcon style={{ color: "green" }} />
                          ) : null}
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </FormControl>
            {questionPreviewData?.explanation && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold">
                Explanation:
              </Typography>
              <Typography variant="body2">
              <PreviewComponent
              htmlContent={
                questionPreviewData?.explanation?.translation &&
                questionPreviewData?.explanation.translation.find(
                  (trans: any) => trans.language === (selectedLanguage === 0 ? "english" : "hindi")
                )?.text
              }
            />
                </Typography>
            </Box>
            )}
            {questionPreviewData?.tag?.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold">
                Question Tags:
              </Typography>
              <Typography variant="body2"><Chip
                sx={{
                  margin: "5px",
                  background: "#E3F4E7",
                  maxWidth: "calc(100% - 10px)",
                  "& .MuiChip-label": {
                    fontSize: "0.69srem",
                    lineHeight: "1rem",
                  },
                }} label={questionPreviewData?.tag[0]?.name} variant="outlined" /></Typography>
            </Box>
            )}
          </CardContent>
        </Card>
    </Box>
  );
};

export default QuestionPreview;
