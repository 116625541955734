import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  InputAdornment, 
  MenuItem, 
  Select, 
  FormControl, 
  InputLabel, 
  IconButton, 
  Button, 
  Box, 
  Typography,
  Paper,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ClearIcon, DatePicker, DateRangeIcon, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { SelectChangeEvent } from '@mui/material/Select';
import { MuiDateRangePicker } from '../../Components/DateRangePicker';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useAppDispatch, useAppSelecter } from '../../hooks/storeHooks';
import { fetchOrdersAndPayments } from '../../store/slices/ordersAndPayments/ordersAndPaymentSlice';
import { ObjectValues } from '../login';
import { allApis } from '../../request/config';
import * as fns from "date-fns";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Papa from 'papaparse';
import 'jspdf-autotable';
import { set } from 'lodash';
import { npclogo } from '../../assets/svgs';
import logoSrc from '../../assets/svgs/npclogo.png';

// Sample table row data structure
interface UserData {
  id: number;
  date: string;
  userName: string;
  userId: string;
  phone: string;
  joiningDate: string;
  plan: string;
  mockTestStarted: number;
  mockTestCompleted: number;
  pastPurchases: number;
  userSource: string;
  status: string;
}

const OrderManagement: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
});
  // const [dateRange, setDateRange] = useState<[string | null, string | null]>([moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]);
  const [planFilter, setPlanFilter] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState('All');
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [loaderforPDF, setLoaderforPDF] = useState(false);
  const [loaderForCSV, setLoaderForCSV] = useState(false);
  const [progress, setProgress] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);

  const dispatch = useAppDispatch();
  const Papa = require('papaparse') as any;
  const { orderPaymentList, loading, next, previous} = useAppSelecter(
    (state: ObjectValues) => state.orderAndPaymentSlice
  );

  useEffect(() => {
    // Fetch data logic based on filters
  }, [searchQuery, dateRange, planFilter]);

  useEffect(() => {
    dispatch(
      fetchOrdersAndPayments({
        page: paginationModel.page ,
        pageSize: paginationModel.pageSize,
        search: searchQuery,
        status: statusFilter === "All" ? "" : statusFilter,
        plan_type: planFilter,
        modified_at_after: dateRange.startDate ? fns.format(dateRange.startDate, 'yyyy-MM-dd') : undefined,
        modified_at_before: dateRange.endDate ? fns.format(dateRange.endDate, 'yyyy-MM-dd') : undefined,
      })
    ).then((action: any) => {
      if (action.payload) {
        setTotalRowCount(action.payload.count);
      }
    });
  }, [dispatch, paginationModel.page,paginationModel.pageSize, searchQuery, statusFilter,planFilter, dateRange]);

  useEffect(() => {
    // Format data when orderPaymentList changes
    
  const formatted = orderPaymentList.map((item: any) => ({
    id: item.id,
    modified_at: moment(item.modified_at).format('DD-MM-YYYY, hh:mm A'),
    full_name: `${item.user_data.full_name}, ${item.user_data.id}, ${item.user_data.phone}`, // Formatting as "Name, ID, Phone Number"
    joiningDate: `${moment(item.joining_details.joining_date).format('DD-MM-YYYY')} , ${item.joining_details.joined_days} days`,
    plan: `${item.product_details.name} , ${item.amount}`,
    userSource: item.user_source,
    mockTestStarted: item.mock_test_started,
    mockTestCompleted: item.mock_test_completed,
    pastPurchases: item.purchases,
    status: item.order_status,
}));
    setFormattedData(formatted);
  }, [orderPaymentList]);

  const toggle = () => setOpen(!open);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handlePlanChange = (event: SelectChangeEvent<string[]>) => {
    setPlanFilter(event.target.value as string[]);
  };

  const fetchFirstPageData = async () => {
    // Fetching first page data after the PDF is generated
    const response = await dispatch(
      fetchOrdersAndPayments({
        page: 0, // First page
        pageSize: 20, // Adjust the page size if needed
        search: searchQuery,
        status: statusFilter === "All" ? "" : statusFilter,
        plan_type: planFilter,
        modified_at_after: dateRange.startDate ? fns.format(dateRange.startDate, 'yyyy-MM-dd') : undefined,
        modified_at_before: dateRange.endDate ? fns.format(dateRange.endDate, 'yyyy-MM-dd') : undefined,
      })
    ).then((action: any) => {
      if (action.payload) {
        setFormattedData(formattedData);
      }
    });
  };

  const fetchAllPagesData = async () => {
    setLoaderforPDF(true); 
    const allData: any[] = [];
    let currentPage = 0;
    const pageSize = 20; // Adjust based on your API settings
    let totalPageCount = 1;
  
    while (currentPage < totalPageCount) {
      const response = await dispatch(
        fetchOrdersAndPayments({
          page: currentPage,
          pageSize,
          search: searchQuery,
          status: statusFilter === "All" ? "" : statusFilter,
          plan_type: planFilter,
          modified_at_after: dateRange.startDate ? fns.format(dateRange.startDate, 'yyyy-MM-dd') : undefined,
          modified_at_before: dateRange.endDate ? fns.format(dateRange.endDate, 'yyyy-MM-dd') : undefined,
        })
      ).then((action: any) => {
        if (action.payload) {
          allData.push(...action.payload.results);
        totalPageCount = Math.ceil(action.payload.count / pageSize);
        currentPage++;
         // Update progress
         const newProgress = Math.round(((currentPage) / totalPageCount) * 100);
         setProgress(newProgress);
        }
      });
    }
  setLoaderforPDF(false);
    return allData;
  };
  

  const handleCsvDownload = async () => {
    const allData = await fetchAllPagesData();
  
    const csvData = allData.map((item, index) => ({
      SerialNumber: index + 1,
      Date: moment(item.modified_at).format('DD-MM-YYYY, hh:mm A'),
      FullName: `${item.user_data.full_name} | ${item.user_data.id} | ${item.user_data.phone}`,
      JoiningDate: moment(item.joining_details.joining_date).format('DD-MM-YYYY'),
      Plan: item.product_details.name,
      UserSource: item.user_source,
      MockTestStarted: item.mock_test_started,
      MockTestCompleted: item.mock_test_completed,
      Purchases: item.purchases,
      Status: item.order_status,
    }));
  
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'full_data_report.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Fetch the first page data after the PDF has started downloading
   fetchFirstPageData();
  };
 

// const handlePdfDownload = async () => {
//   const allData = await fetchAllPagesData();

//   const doc = new jsPDF({
//     orientation: 'landscape', // or 'landscape'
//     unit: 'mm',              // units for measurements
//     format: 'a4',            // set the page size to A5
//     putOnlyUsedFonts: true,  // optional
//   });
//   doc.text('Orders and payments data report', 5, 15);
//   const tableData = allData.map((item) => [
//     item.id,
//     moment(item.modified_at).format('DD-MM-YYYY, hh:mm A'),
//     `${item.user_data.full_name} | ${item.user_data.id} | ${item.user_data.phone}`,
//     moment(item.joining_details.joining_date).format('DD-MM-YYYY'),
//     item.product_details.name,
//     item.user_source,
//     item.mock_test_started,
//     item.mock_test_completed,
//     item.purchases,
//     item.order_status,
//   ]);

//   (doc as any).autoTable({
//     head: [['Order ID', 'Date', 'Full Name', 'Joining Date', 'Plan', 'User Source', 'Mock Test Started', 'Mock Test Completed', 'Past Purchases', 'Status']],
//     body: tableData,
//     styles: {
//       fontSize: 10, // Set the font size
//       cellPadding: 3, // Cell padding
//       lineColor: [0, 0, 0], // Border color
//       lineWidth: 0.1, // Border width
//     },
//     headStyles: {
//       fillColor: [22, 160, 133], // Header background color (RGB)
//       textColor: [255, 255, 255], // Header text color (RGB)
//       fontStyle: 'bold', // Make header text bold
//     },
//     alternateRowStyles: {
//       fillColor: [240, 240, 240], // Alternate row color (light gray)
//     },
//     columnStyles: {
//       0: { minWidth: 10, cellWidth: 20 }, // ID
//       1: { minWidth: 35, cellWidth: 30 }, // Date
//       2: { minWidth: 50, cellWidth: 40 }, // Full Name
//       3: { minWidth: 40, cellWidth: 30 }, // Joining Date
//       4: { minWidth: 30, cellWidth: 30 }, // Plan
//       5: { minWidth: 40, cellWidth: 30 }, // User Source
//       6: { minWidth: 35, cellWidth: 30 }, // Mock Test Started
//       7: { minWidth: 35, cellWidth: 30 }, // Mock Test Completed
//       8: { minWidth: 30, cellWidth: 25 }, // Past Purchases
//       9: { minWidth: 30, cellWidth: 20 }, // Status
//     },
//     margin: { top: 20, left: 6,  }, // Margin at the top of the page
//   });

//   doc.save('full_data_report.pdf');
//   // Fetch the first page data after the PDF has started downloading
//    fetchFirstPageData();
// };



const handlePdfDownload = async () => {
  const allData = await fetchAllPagesData();

  const doc = new jsPDF({
    orientation: 'landscape', 
    unit: 'mm',              
    format: 'a4',            
    putOnlyUsedFonts: true,  
  });
  // Adjust the logo width and height as needed
  const logoWidth = 15; // Set the desired width
  const logoHeight = 15; // Set the desired height

  // Add logo at the top-left corner
    doc.addImage(logoSrc, 'PNG', 13, 5, logoWidth, logoHeight);// Make sure the path is correct
    doc.setFontSize(12);
    doc.text("NetPractice", 10, 25);
  // logo.onload = () => {

    // Add title and filters information
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Orders and Payments Data Report', 100, 15); // Adjust position as needed

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    const filtersText1 = `Product Plan - ${planFilter.join(', ')}`; 
    const filtersText2 =  `Order Status - ${statusFilter}`; 
    const filtersText3 =  `Date Range - ${dateRange.startDate ? "From" : ""}  ${dateRange.startDate ? moment(dateRange.startDate).format("DD-MMM-YYYY") : ""} ${dateRange.endDate ? "To" : ""} ${dateRange.endDate ? moment(dateRange.endDate).format("DD-MMM-YYYY") : ""}`;
    doc.text(filtersText1, 50, 30);
    doc.text(filtersText2, 130, 30);
    doc.text(filtersText3, 200, 30);

    doc.setFont("Roboto");
    const tableData = allData.map((item, index) => {
      const formattedPrice = `\u20B9${(item.amount / 100).toFixed(2)}`;
  // const priceInRupees = item.amount !== 'N/A' ? `₹ ${(item.amount / 100).toFixed(2)}` : 'N/A';
      return [
        index + 1,
      moment(item.modified_at).format('DD-MM-YYYY, hh:mm A'),
      `${item.user_data.full_name} (${item.user_data.id}) ${item.user_data.phone}`,
      `${moment(item.joining_details.joining_date).format('DD-MM-YYYY')}\n${item.joining_details.joined_days} days`,
      `${item.product_details.name}\nRs.${(item.amount / 100).toFixed(2)}`,
      item.user_source,
      item.mock_test_started,
      item.mock_test_completed,
      item.purchases,
      item.order_status,
    ]
  });

    (doc as any).autoTable({
      head: [['Serial No.', 'Date', 'Full Name', 'Joining Date', 'Plan', 'User Source', 'Mock Test Started', 'Mock Test Completed', 'Purchases', 'Status']],
      body: tableData,
      styles: {
        fontSize: 9, // Standard font size for content
        cellPadding: 4, 
        lineColor: [220, 220, 220],
        lineWidth: 0.2,
      },
      headStyles: {
        fillColor: [240, 240, 240], // Standardized header color (blue)
        textColor: [0, 0, 0],
        fontStyle: 'bold',
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245], 
      },
      columnStyles: {
        0: { minWidth: 10, cellWidth: 20 }, 
        1: { minWidth: 35, cellWidth: 30 }, 
        2: { minWidth: 50, cellWidth: 40 },
        3: { minWidth: 40, cellWidth: 30 },
        4: { minWidth: 30, cellWidth: 30 },
        5: { minWidth: 40, cellWidth: 30 },
        6: { minWidth: 35, cellWidth: 30 },
        7: { minWidth: 35, cellWidth: 30 },
        8: { minWidth: 30, cellWidth: 25 },
        9: { minWidth: 30, cellWidth: 20 },
      },
      margin: { top: 35, left: 6 }, 
      // didDrawPage: function (allData: any) {
      //   // Footer with page number
      //   const pageCount = (doc as any).internal.getNumberOfPages();
      //   const pageSize = doc.internal.pageSize;
      //   const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      //   doc.setFontSize(10);
      //   doc.setFont('helvetica', 'normal');
      //   doc.text(`Page ${allData.pageNumber} of ${pageCount}`, pageSize.width - 20, pageHeight - 10, {
      //     align: 'right'
      //   });
      // }
    });

    // Recalculate the number of pages after the table has been drawn
  const pageCount = (doc as any).internal.getNumberOfPages();

  // Loop through all pages to add consistent footers
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(`Page ${i} of ${pageCount}`, pageSize.width - 20, pageHeight - 10, {
      align: 'right'
    });
  }

    doc.save('full_data_report.pdf');
    // Fetch the first page data after the PDF has started downloading
    fetchFirstPageData();
  // };
};




  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setStatusFilter(event.target.value);
  };

  const formatDateRange = (range: { startDate: Date | null; endDate: Date | null }) => {
    const formatDate = (date: Date | null) => {
        if (!date) return "";
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: '2-digit' }); // Get the full month name
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const start = formatDate(range.startDate);
    const end = formatDate(range.endDate);
    return start && end ? `${start} - ${end}` : "";
};

  // Table columns definition
  const columns: GridColDef[] = [
    { field: 'id', headerName: '#', width: 50, renderCell: (params) => {
      // Calculate the serial number based on the pagination model
      const rowIndex = formattedData.findIndex(item => item.id === params.row.id);
      return (paginationModel.page * paginationModel.pageSize) + (rowIndex + 1);
    }},
    { field: 'modified_at', headerName: 'Date', width: 180 },
    {
      field: 'full_name',
      headerName: 'User Details',
      width: 250,
      renderCell: (params) => {
          // Splitting the userName to extract name, ID, and placeholder number
          const [name = 'Unknown', id = 'N/A', phone = 'N/A'] = params.value?.split(', ');
  
          return (
              <div>
                  {/* Display username and ID on the same line */}
                  <Typography variant="body1">
                      {name}({id})
                  </Typography>
                  {/* Display phone number below */}
                  <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                      {phone}
                  </Typography>
              </div>
          );
      },
  },
    { field: 'joiningDate', headerName: 'Joining Date', width: 150 ,
      renderCell: (params) => {
          // Splitting the userName to extract name, ID, and placeholder number
          const [date = 'Unknown',  days = 'N/A'] = params.value?.split(', ');
  
          return (
              <div>
                  {/* Display username and ID on the same line */}
                  <Typography variant="body1">
                      {date}
                  </Typography>
                  {/* Display phone number below */}
                  <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                      {days}
                  </Typography>
              </div>
          );
      },
    },
    { field: 'plan', headerName: 'Plan Name', width: 150 ,
      renderCell: (params) => {
          // Splitting the userName to extract name, ID, and placeholder number
          const [name = 'Unknown',  price = 'N/A'] = params.value?.split(', ');
          // Convert price from paise to rupees
    const priceInRupees = price !== 'N/A' ? `₹ ${(price / 100).toFixed(2)}` : 'N/A';
  
          return (
              <div>
                  {/* Display username and ID on the same line */}
                  <Typography variant="body1">
                      {name}
                  </Typography>
                  {/* Display phone number below */}
                  <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                      {priceInRupees}
                  </Typography>
              </div>
          );
      },
    },
    { field: 'userSource', headerName: 'User Source', width: 130 },
    { field: 'mockTestStarted', headerName: '# of Mock Tests Started', width: 140 },
    { field: 'mockTestCompleted', headerName: '# of Mock Tests Completed', width: 140 },
    { field: 'pastPurchases', headerName: 'Purchases', width: 150 },
    {field:"status", headerName:"Status", width: 90}
  ];

  const handleDateRangeChange = (range: any) => {
    setDateRange(range);
    setOpen(false); // Close the date picker after selection
};

  return (
    <Paper style={{ width: '99%', height: "90vh",  overflow: 'hidden' }}>
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Orders and Payments</Typography>

      {/* Filters */}
      <Box sx={{ display: 'flex', mb: 2, justifyContent: 'space-between', alignItems: 'center', width:"100%" }}>
        
        {/* Search Bar */}
        <Box sx={{ flexGrow: 1,width: '25%'  }}>
        <TextField
          label="Search by Name, ID, Phone"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{width:"100%" }}
        />
        </Box>

        {/* Date Range Picker */}
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            startText="Start Date"
            endText="End Date"
            value={dateRange}
            onChange={(newRange: any) => setDateRange([moment(newRange[0]).format('YYYY-MM-DD'), moment(newRange[1]).format('YYYY-MM-DD')])}
            renderInput={(startProps: any, endProps: any) => (
              <>
                <TextField {...startProps} variant="outlined" sx={{ marginRight: 2 }} />
                <TextField {...endProps} variant="outlined" />
              </>
            )}
          />
        </LocalizationProvider> */}
        
        {/* Plan Multi-select */}
        <FormControl sx={{ flexGrow: 1, width: "25%", marginLeft:"1.8%" }}>
          <InputLabel>Plan</InputLabel>
          <Select
          label="Plan"
            multiple
            value={planFilter}
            onChange={handlePlanChange}
            renderValue={(selected) => (selected as string[]).join(', ')}
            sx={{ width: '100%' }}
          >
            <MenuItem value="Success">Success</MenuItem>
            <MenuItem value="Topper">Topper</MenuItem>
          </Select>
        </FormControl>

        {/* Actions */}
        <Box sx={{ display: 'flex', alignItems: 'center', mx: 3, width: "25%" }}>
        {/* <Button
                            // key={D?.label}
                            // style={styles.dateButtonsGroup(dateSelected, D?.label)}
                            onClick={(e) => {
                               setOpen(!open)
                            }}
                          >
                            {<DateRangeIcon />}
                          </Button> */}

                          <TextField
  variant="outlined"
  onClick={(e) => {
    setOpen(!open); // This will toggle the open state
  }}
  value={formatDateRange(dateRange)}
  sx={{ width: '100%'}}
  style={{cursor: 'pointer'}}
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
          <DateRangeIcon />
          {dateRange.startDate && dateRange.endDate && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation(); // Prevent opening the picker when clicking the clear button
                handleDateRangeChange([null, null]); // Clear the date range
              }}
            >
              <ClearIcon />
            </IconButton>
          )}
        </InputAdornment>
    ),
  }}
/>

        <MuiDateRangePicker
                      open={open}
                       toggle={toggle}
                      dateRange={dateRange}
                      onDateRangeChange={handleDateRangeChange}
                    />
                    </Box>

                    {/* Status Select Box */}
  <FormControl sx={{ flexGrow: 1, width: "25%", marginRight: "1.5%" }}>
    <InputLabel>Status</InputLabel>
    <Select
    label="Status"
      value={statusFilter}
      onChange={handleStatusChange}
      sx={{ width: '100%' }}
    >
      <MenuItem value="All">All</MenuItem>
      <MenuItem value="paid">Paid</MenuItem>
      <MenuItem value="created">Created</MenuItem>
      <MenuItem value="attempted">Attempted</MenuItem>
    </Select>
  </FormControl>

                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, mx: 2, width:"25%" }}>
                      <Tooltip title="Download PDF" placement='top'>
          <IconButton onClick={handlePdfDownload} color="primary">
            <LocalPrintshopIcon />
          </IconButton>
          </Tooltip>
          <Tooltip title="Download CSV" placement='top'>
          <IconButton onClick={handleCsvDownload} color="primary" sx={{ marginLeft: '10px' }}>
            <CloudDownloadOutlinedIcon/>
          </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {/* DataGrid Table */}
      <div style={{ height: 'calc(90vh - 150px)', width: '100%', overflow: 'hidden'}}>
        {loaderforPDF  ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            Loading... {progress}%
          </Typography>
        </Box>
      ) : (
        <DataGrid
          rows={formattedData}
          columns={columns}
          pageSizeOptions={[20]}
          loading={loading}
          paginationMode="server"
          rowCount={totalRowCount}
          paginationModel={paginationModel}
          onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
          pagination
        />
        )
        }
      </div>
    </Box>
    </Paper>
  );
};

export default OrderManagement;
