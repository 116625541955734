import React from 'react'

export const book=(props:{height?:string})=> (
<svg width={props?.height??"16"} height={props?.height??"16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_150_269)">
  <path d="M15.2813 1.13336C15.0509 0.942334 14.7808 0.805144 14.4906 0.731793C14.2004 0.658441 13.8975 0.650776 13.604 0.709356L10.856 1.37602C10.2416 1.48781 9.68586 1.81171 9.28577 2.29123C8.88567 2.77076 8.66657 3.3755 8.66667 4.00002V13.8667L8 13.9874L7.33333 13.8667V4.00002C7.33577 3.38169 7.12213 2.78193 6.72931 2.30439C6.3365 1.82685 5.7892 1.50155 5.182 1.38469L2.35733 0.699356C2.06918 0.647031 1.77305 0.658628 1.48987 0.733328C1.20669 0.808028 0.943372 0.944008 0.718521 1.13166C0.49367 1.31931 0.312775 1.55405 0.188618 1.8193C0.0644604 2.08455 7.11635e-05 2.37382 0 2.66669L0 13.8894L8 15.3447L16 13.8894V2.66669C16 2.37404 15.9357 2.08496 15.8115 1.81997C15.6873 1.55498 15.5063 1.32057 15.2813 1.13336Z" fill="currentColor"/>
  </g>
  <defs>
  <clipPath id="clip0_150_269">
  <rect width="16" height="16" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  
  )


  