import React, { useState } from "react";
import { DateRange, DateRangePicker } from "materialui-daterange-picker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import * as fns from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core/TextField";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const MuiDateRangePicker = (props: any) => {
    const classes = useStyles();
    const format = props.fomat || "yyyy-MM-dd";


    const getDisplayDateRange = (dateRange: any) => {
        const startDate = dateRange?.startDate
            ? fns.format(dateRange.startDate, format)
            : undefined;

        const endDate = dateRange?.endDate
            ? fns.format(dateRange.endDate, format)
            : undefined;

        return startDate || endDate ? `${startDate} - ${endDate}` : "";
    };

    return (
        <>
            <Modal
                className={classes.modal}
                open={props.open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <DateRangePicker
                                open={true}
                                 toggle={props.toggle}
                                closeOnClickOutside={true}
                                onChange={(range: any) => {
                                    props.onDateRangeChange(range);
                                    // props.toggle();
                                }}
                                initialDateRange={props.dateRange}
                            />
                        </div>
                        {/* <div style={{ backgroundColor: 'white', width: '100%', padding: '4px' }}>
                            <Button style={{ width: 150, height: 40, backgroundColor: "#293C79", color: "white" }}
                                onClick={() => props.onDateRangeChange(null)}>Clear Dates</Button>
                        </div> */}
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export { MuiDateRangePicker };