import React, { useState, useEffect } from 'react';
import {
  Card, CardHeader, TableContainer, CircularProgress, CardActions, Divider, Typography, Button, Tooltip, IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableHead, TableRow, Stepper, Step, StepLabel,
  Snackbar,
  Alert
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import { useAppDispatch } from '../../../hooks/storeHooks';
import { getQCPassFailLogs, getQualityChecks, passFailQualityChecks } from '../../../store/slices/exams/thunks/fetchContentData';

interface DuplicateQualityCheckBoxesProps {
  header: string;
  id: string;
  type: string;
  initialData: any;
  onButtonClick: (data: any) => void;
}

const ClusterQualityCheckBoxes: React.FC<DuplicateQualityCheckBoxesProps> = ({onButtonClick, header, id, type, initialData }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [logDialogOpen, setLogDialogOpen] = useState(false); // For status log dialog
  const [currentCheckData, setCurrentCheckData] = useState(initialData);
  const [statusLogData, setStatusLogData] = useState<any[]>([]); // State to store status log data
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string | null>(initialData?.quality_check_status);
  const [checkedBy, setCheckedBy] = useState<string | null>(initialData?.checked_by);
  const [timestamp, setTimestamp] = useState<string | null>(initialData?.manual_verification_timestamp);
  const [lastQualityCheckTimestamp, setLastQualityCheckTimestamp] = useState<string | null>(initialData?.last_quality_check_timestamp);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success or error
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (initialData) {
      setCurrentCheckData(initialData);
      setStatus(initialData?.quality_check_status);
      setCheckedBy(initialData?.checked_by);
      setTimestamp(moment(initialData?.manual_verification_timestamp).format('DD MMM, YYYY, h:mm A'));
      setLastQualityCheckTimestamp(moment(initialData?.last_quality_check_timestamp).format('DD MMM, YYYY, h:mm A'));
    }
  }, [initialData]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleLogDialogOpen = async () => {
   const logData = await dispatch(getQCPassFailLogs({ id, type: 'cluster_matching_check' }))
   setStatusLogData(logData?.payload); // Update status log data
    setLogDialogOpen(true);
  };

  const handleLogDialogClose = () => {
    setLogDialogOpen(false);
  };

  const handleCheckAgain = async () => {
    onButtonClick(true);
    setLoading(true);
    const updatedData = await dispatch(getQualityChecks({ id, type: 'cluster_matching_check' }));
    const updatedCheck = updatedData?.payload?.find((check: any) => check.quality_check_type === 'cluster_matching_check');
    if (updatedCheck) {
      setCurrentCheckData(updatedCheck);
      setLastQualityCheckTimestamp(moment().format('DD MMM, YYYY, h:mm A')); // Set current timestamp
    }
    setLoading(false);
  };

  const handleStatusChange = (newStatus: string) => {
    dispatch(passFailQualityChecks({ id: initialData?.id, quality_check_status: newStatus }))
      .then((response) => {
        if (response.payload) {
          setStatus(newStatus);
          // setCheckedBy(response.payload.checked_by);
          setTimestamp(moment().format('DD MMM, YYYY, h:mm A')); // Updates timestamp on successful status change
          setSnackbarMessage('Status updated successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);

        }
      })
      .catch((error) => {
        console.error('Error changing status:', error);
        setSnackbarMessage('Error changing status');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  }

  const removeHtmlTags = (text: any) => {
    return text?.replace(/<[^>]*>/g, ''); // This regex matches any HTML tags
  };

  return (
    <>
    <Card sx={{ maxWidth: 500, p: 2 }}>
      <CardHeader
        action={
          <Tooltip title="Duplicacy Check Information" arrow>
            <IconButton aria-label="info">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        }
        title={header}
        titleTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
      />
      <Divider variant="middle" />

      {/* Duplicate Check Info */}
      <div style={{ display: 'flex', flexDirection: 'column', padding: '16px 0' }}>
        <Typography variant="subtitle2" component="h2" style={{ marginLeft: '15px', fontWeight: 'bold' }}>
          Intelligent Suggestion
        </Typography>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 0' }}>
          {loading ? (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
              <CircularProgress size={24} style={{ marginRight: '8px' }} />
              <Typography variant="body2" color="text.secondary">Checking for matching clusters...</Typography>
            </div>
          ) : currentCheckData?.data && currentCheckData.data.length > 0 ? (
            <div>
              <Typography
                variant="body2"
                component="p"
                style={{ marginLeft: '15px', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={handleDialogOpen}
              >
                {currentCheckData.data.length} matching clusters
              </Typography>
              <Typography variant="body1" color="text.secondary" component="p" style={{ marginLeft: '15px' }}>
                checked on {moment(lastQualityCheckTimestamp).format('DD MMM, YYYY, hh:mm A')}
              </Typography>
            </div>
          ) : (
            <Typography variant="body2" color="text.secondary" component="p" style={{ marginLeft: '15px' }}>
              No matching clusters found
            </Typography>
          )}
          <Button variant="text" size="small" onClick={handleCheckAgain} sx={{ textDecoration: 'underline', marginTop: '10px', marginLeft: '15px' }}>
            Check Again
          </Button>
        </div>
      </div>

      <Divider variant="middle" />

      {/* Manual Approval Section */}
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 2 }}>
      <>{console.log("st for duplicacy",status)}</>
        {(status !== "pending" && status !== null) ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="body2"
                style={{ textDecoration: 'underline', marginLeft: "10px", cursor: "pointer" }}
                onClick={handleLogDialogOpen}  // Open status log dialog
              >
                {status === 'pass' ? 'PASSED' : 'FAILED'}
              </Typography>
              <Typography variant="body1" color="text.secondary" style={{ marginLeft: "10px" }}>
                by {checkedBy} {timestamp}
              </Typography>
            </div>
            <Button size="small" variant="outlined" onClick={() => handleStatusChange('pending')}>
              Change Status
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body2">Manual Approval</Typography>
            <div>
              <Button size="small" variant="text" onClick={() => handleStatusChange('fail')}>
                Fail
              </Button>
              <Button
                size="small"
                variant="outlined"
                sx={{ "&:hover": { background: "black" }, ml: 1, background: "black", color: "white" }}
                onClick={() => handleStatusChange('pass')}
              >
                Pass
              </Button>
            </div>
          </>
        )}
      </CardActions>

      {/* Duplicacy Details Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Cluster Check Details</DialogTitle>
        <DialogContent style ={{height:250, overflowY: 'auto'}}>
          <TableContainer style={{ border: '3px solid #f0f4f8'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#f0f4f8', width: '80px' }}><strong>ID</strong></TableCell>
                  <TableCell style={{ backgroundColor: '#f0f4f8' }}><strong>Name</strong></TableCell>
                  <TableCell style={{ backgroundColor: '#f0f4f8' }}><strong>Description</strong></TableCell>
                  <TableCell style={{ backgroundColor: '#f0f4f8' }}><strong>Example Question</strong></TableCell>

                </TableRow>
              </TableHead>
              <TableBody style={{ backgroundColor: '#fbfcfe' }}>
              {currentCheckData?.data?.map((entry: any) => (
                    <TableRow key={entry.id}>
                      <TableCell>{entry.id}</TableCell>
                      <TableCell>{entry.name}</TableCell>
                      <TableCell>{entry.description}</TableCell>
                      <TableCell>{removeHtmlTags(entry.example_question)}</TableCell>

                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Status Log Dialog */}
      <Dialog open={logDialogOpen} onClose={handleLogDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Status Change Log</DialogTitle>
        <DialogContent>
          <Stepper orientation="vertical">
            {statusLogData.map((log, index) => (
              <Step key={index} active={true}>
                <StepLabel>
                  {log.new_value.toUpperCase()} by {log.created_by} on {moment(log.created_at).format('DD MMM, YYYY, h:mm A')}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
    <Snackbar
    open={snackbarOpen}
    autoHideDuration={4000}
    onClose={handleSnackbarClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Position of snackbar
  >
    <Alert onClose={handleSnackbarClose} sx={{ width: '100%' }}>
      {snackbarMessage}
    </Alert>
  </Snackbar>
  </>
  );
};

export default ClusterQualityCheckBoxes;
