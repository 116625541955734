import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Stack } from "@mui/material";
import { rowCenter, rowSB, rowVerticalCenter } from "../../GlobalStyles";
import globalColors from "../../globalColors";
import { subHeading } from "../CustomTexts";
import moment from "moment";
import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
import { setPaginationModel } from "../../store/slices/exams";

export default function DataGridTable({
  columns,
  rows,
  renderRowDetails,
  loading,
  page,
  size,
  isServerSide
}: any) {
  const dispatch = useAppDispatch();
  const examsData = useAppSelecter(s => s.exams)
  const [expanded, setExpanded] = React.useState<any>({});
  const handleExpandClick = (row: any) => {
    setExpanded((prev: { [x: string]: any }) => {
      return { ...prev, [row.id]: !prev[row.id] };
    });
  };
  // const ExpandButton = ({ row, api }: any) => {

  //   return (
  //     <Button onClick={handleExpandClick}>
  //       {expanded[row.id] ? 'Collapse' : 'Expand'}
  //     </Button>
  //   );
  // };
  // const columns: GridColDef[] = [
  //   { field: 'firstName', headerName: 'First name', width: 200 },
  //   { field: 'lastName', headerName: 'Last name', width: 200 },
  //   // { field: 'age', headerName: 'Age', type: 'number', width: 200 },
  //   {
  //     field: 'expand',
  //     headerName: 'Expand',
  //     width: 200,
  //     renderCell: (params) => <ExpandButton {...params} />,
  //   },
  // ];
  const CustomRow = (w: any) => {
    const { row } = w;
    return (
      <Box
        key={`row-${row.id}`}
        style={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto" }}
        className={
          row.id ===
            (rows && rows[rows.length - 1]?.id ? rows[rows.length - 1]?.id : "")
            ? ""
            : "InovuaReactDataGrid__row"
        }
      >
        <Box style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <Stack
            direction="row"
            p="10px 0"
            sx={{ minWidth: "100%", ...rowVerticalCenter, ...rowSB }}
          >
            {columns
              .filter((is: { field: string }) =>
                w.visibleColumns.find(
                  (ss: { field: string }) => ss.field === is.field
                )
              )
              .map(
                (is: {
                  minWidth: string;
                  width: any;
                  renderCell: (
                    arg0: any
                  ) =>
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                      any,
                      string | React.JSXElementConstructor<any>
                    >
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                  field: string | number;
                  type: string;
                }) => {
                  return (
                    <Box

                      key={`row-${is.field}`}
                      sx={{
                        minWidth: is.minWidth ?? is.width ?? "fit-content",
                        maxWidth: is.width ?? "fit-content",
                        textWrap: "normal",
                        whiteSpace: "normal",
                        height: "100%",
                        ...rowVerticalCenter,
                      }}
                      className="all-content"
                    >
                      <Box
                        p="0 10px"
                        sx={{
                          width: "100%",
                          textWrap: "normal",
                          whiteSpace: "normal",
                          height: "100%",
                          ...rowVerticalCenter,
                        }}
                      >
                        {is.renderCell
                          ? is.renderCell({
                            ...w,
                            value: row[is.field] ?? "",
                            data: w.row,
                            rowExpanded: expanded[row.id] ? true : false,
                            toggleRowExpand: () => {
                              handleExpandClick(row);
                            },
                          })
                          : is.type != "datetime" ? row[is.field] : moment(row[is.field]).format("YYYY-MM-DD HH:mm:ss A")}
                      </Box>
                    </Box>
                  );
                }
              )}
          </Stack>
        </Box>
        {expanded[row.id] && renderRowDetails(row)}
      </Box>
    );
  };
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {
        isServerSide ?
          <DataGrid
            loading={loading ?? false}
            rows={rows.map((row: any) => ({ ...row, expanded: false }))}
            columns={columns}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            rowCount={rows?.length > 0 && rows[0]?.totalCount || rows?.length}
            paginationMode="server"
            paginationModel={examsData.paginationModel}
            onPaginationModelChange={(paginationModel: any) => {
              dispatch(setPaginationModel(paginationModel));
            }

            }
            autoHeight
            getRowId={(row) => row.id}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  width={`calc(${document.getElementById("table-width")?.offsetWidth ?? 400
                    } - 45px)`}
                  sx={{
                    ...rowCenter,
                    padding: "10px 0",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  {subHeading({
                    text: "No Changes yet!",
                    sx: { color: globalColors.primary.disableText },
                    weight: 600,
                  })}
                </Stack>
              ),
              Row: CustomRow,
            }}
          /> :
          <DataGrid
            loading={loading ?? false}
            rows={rows.map((row: any) => ({ ...row, expanded: false }))}
            columns={columns}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            rowCount={rows?.length || 0}
            initialState={{
              pagination: {
                paginationModel: { pageSize: size || 10, page: (page || 1) - 1 },
              },
            }}
            autoHeight
            getRowId={(row) => row.id}
            // 385+extra 5 +tab includes in it
            components={{
              NoRowsOverlay: () => (
                <Stack
                  width={`calc(${document.getElementById("table-width")?.offsetWidth ?? 400
                    } - 45px)`}
                  sx={{
                    ...rowCenter,
                    padding: "10px 0",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  {subHeading({
                    text: "No Changes yet!",
                    sx: { color: globalColors.primary.disableText },
                    weight: 600,
                  })}
                </Stack>
              ),
              Row: CustomRow,
            }}
          />
      }

    </Box>
  );
}
