import React from 'react'

function SvgPreview({svg,sx}:any) {
  return (
    <img
    src={svg} // Assuming sd is the source URL for your image
    style={sx?sx:{
      // objectFit: "cover",
      // width: "100%", // You might want to adjust the width based on your layout
      // height: "100%", // You might want to adjust the height based on your layout
    }}
    alt="Description"
  />
  )
}

export default SvgPreview