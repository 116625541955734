import { ObjectValues } from "./pages/login";
import { allStatus } from "./store/slices/constants/staticContents";

const primary: ObjectValues = {
  black: "#000000",
  appBackground:"#F4F5FA",
  white: "#FAFAFA",
  pureWhite: "#FFFFFF",
  chipGreyColor:"#DFDFDF",
  grey: "#969696",
  darkGrey:"#444444",
  disableBg: "rgba(200, 200, 200, 1)",
  disableText: "rgba(131, 131, 131, 1)",
  lightGrey:"#F6F6F6",
  purple:"#793ED6",
  normalText:"#838383",
  borderColor:"#EBEBEB",
  green:"#02B534",
  harvestGold:"#FAEEDD"
};
const colorCombinations:ObjectValues={
  [allStatus[0].label]:{
    bg:"#E1E6FF",
    color:"#5570F1"
  }, 
  [allStatus[1].label]:{
    bg:"#FAEEDD",
    color:"#CC861D"
  },   approved:{
    bg:"#E3F4E7",
    color:"#519C66"
  }, 
  published:{
    bg:"#F2ECFB",
    color:primary.purple
  },
  archived:{
    bg:"#FFECF8",
    color:"#E642AA"
  },
  rejected:{
    bg:"#FFF0F0",
    color:"#FF1A1A"
  }, 
  unpublished:{
    bg:"#EAFAFC",
    color:"#179EAF"
  },
  whitelist:{
    bg:'#EAFAFC',
    color:"black"
  }, 
  free:{
    bg:primary.lightGrey,
    color:"black"
  }
}
const grey = {
  10: "rgb(150 150 150 / 10%)",
};
const red = {
  700: "#800000",
  600: "#CC0000",
  500: "#FF1A1A",
  400: "#FF6464",
  300: "#FFC1C1",
  200: "#FFE0E0",
  100: "#FFF0F0",
};

const globalColors = {
  primary,
  red,
  grey,colorCombinations
};

export default globalColors;
