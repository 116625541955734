import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks/storeHooks";
import { Box, Stack } from "@mui/material";
import CustomCard from "../../../Components/CustomCard";
import { headerContent } from "../../../Components/CustomTexts";
import { ObjectValues } from "../../login";
import globalColors from "../../../globalColors";
import CustemListItem from "../../../Components/CustemListItem";
import CustomSearch from "../../../Components/CustomSearch";
import CustomProgress from "../../../Components/CustomProgress";
import { fetchSinlgQustion } from "../../../store/slices/exams/thunks/fetchContentData";
import MessagePopper from "../../../Components/MessagePopper";

export interface itemProp {
  list: ObjectValues[];
  primary: string;
  selected_row: ObjectValues | null;
  title: string;
}
function ListCard({ item,setUpdates,selected  }: { item: itemProp;selected?:any,
  setUpdates?(id:any):void }) {
  const dispatch = useAppDispatch();
  const [table_list,setTableData]=useState<ObjectValues>({copy:[],actual:[],paginated:[],count:15,page:1,hasMore:true})
  useEffect(()=>{
    const list=item?.list??[];
    if(list.length>0)
  {  setTableData({copy:list,actual:list,paginated:list.slice(0,table_list.count),count:table_list.count,page:1,hasMore:true
    })}else{
      setTableData({copy:[],actual:[],paginated:[],count:table_list.count,page:1,hasMore:false})
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    item
  ])

  const [paginationLoader, setPagingLoding] = useState(false);
  function isScrolledToBottom(element: HTMLElement | null) {
    if (!element) return false;
    return (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    );
  }
  // function isScrolledToTop(element: HTMLElement | null) {
  //   if (!element) return false;
  //   return element.scrollTop === 0;
  // }
   const fetchMoreData = (top?:any) => {
    
    if (table_list.actual.length>0&&(table_list.actual.length===table_list.paginated.length)) {
      setTableData((p)=>{return {...p, hasMore: false}});
      return;
    }
    setTimeout(() => {
      setTableData((p)=>{return {...p,page:p.page + 1, paginated: p.actual.slice(table_list.count*(p.page),table_list.count*(p.page+1))}});

      setPagingLoding(false);
    }, 100);
  };
  useEffect(() => {
    const scrollElement = document.getElementById("scroll");

    const handleScroll = () => {

      if (isScrolledToBottom(scrollElement)) {
        if (
          table_list.actual.length>table_list.paginated.length
          // &&
          // status !== "LOADING"
        ) {
          if (!paginationLoader) {
            setPagingLoding(true);
            fetchMoreData();
          }
        }
      }
      // else if(isScrolledToTop(scrollElement)){
      //   if (
      //     table_list.actual.length>table_list.paginated.length
      //     // &&
      //     // status !== "LOADING"
      //   ) {
      //     if (!paginationLoader) {
      //       setPagingLoding(true);
      //       fetchMoreData("top");
      //     }
      //   } 
      // }
    };

    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ paginationLoader,table_list.actual]);
  const fetchSingleQuestionDetails = (id: string) => {
    dispatch(
      fetchSinlgQustion({
        api: `cms/content/question/${id}/`,
        method: "GET",
        payload: {},
        id: id,
      })
    );
  };
  useEffect(()=>{
    setTableData((p)=>{return {...p,hasMore:table_list.actual.length!==table_list.actual.slice(0, table_list.count*(p.page+1)).length, paginated: table_list.actual.slice(0, table_list.count*(p.page+1))}});
  
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[table_list.actual])
  return (
    <CustomCard
      sx={{
        maxWidth: "250px",
        minWidth: "150px",
        p: "7px",
        boxShadow: "none",
        position: "relative",
        height: "calc(100% - 16px)",
        flex: "8",
        border: `1px solid ${globalColors.primary.borderColor}`,
      }}
      content={
        <Stack spacing={"7px"} sx={{height:"100%"}}>
          {headerContent({
            size: "0.8rem",
            text: item.title,
            sx: {
              p: "10px",
              color: globalColors.primary.pureWhite,
              textTransform: "capitalize",
              background: globalColors.primary.black,
              borderRadius: "6px",
              alignItems: "center",
              display: "flex",
            },
            icons: [],
          })}
          <CustomSearch
            copyData={table_list.copy??[]}
            
            
            fieldName={["name"]}
            filteredData={(array: ObjectValues[]) => {
              setTableData((prev: any) => {
                return { ...prev, actual: array};
              });
            }}
            onClear={() => {
              setTableData((prev: any) => {
                return { ...prev,  actual: table_list.copy??[]};
              });
            }}/>
          <Box
            sx={{
              mt: "7px",
              borderBottom: `1px solid ${globalColors.primary.borderColor}`,
            }}
          ></Box>
          <Stack
            id="scroll"
            minWidth={"125px"}
            sx={{ overflowY: "auto", flex: 8 }}
          > 
            {item?.list &&
              item?.list.length > 0 &&
              table_list.paginated.map((li:any, i: number) => {
                return (
                  <>
                    <CustemListItem
                      key={`${li.name}-${i}`}
                      ListItemButtonsx={{ maxHeight: "fit-content" }}
                      keyId={`${li.name}`}
                        isSelected={ selected&&selected.length>0?selected?.find((it:any)=>it.id===li.id)?true:false:selected&&selected?.id&&(selected?.id===li.id?true:false)}
                      selectedColor={globalColors.primary.disableBg}
                      onClick={() => {
                        if(setUpdates){setUpdates(li)}}}
                      direction="reverse"
                      primary={ item.title==="Question"?
                      <MessagePopper
                      fetchSingleQuestionDetails={fetchSingleQuestionDetails}
                      text={li.name ?? ""}
                      type="ck"
                      id={"question"}
                      question={li}
                    />
                     :li.name}
                    />{" "}
                    {i + 1 !== item.list.length && (
                      <Box className="dashed-border" sx={{m:"7px 0"}}/>
                    )}
                  </>
                );
              })}
              <Box
                sx={{ minHeight: "30px", with: "100%", margin: "0 !important" }}
                position={"relative"}
              >
                {
                  table_list.actual.length>table_list.paginated.length&&
                  paginationLoader && (
                    <Box width="100%" position={"absolute"}>
                      <CustomProgress
          sx={{ height: "17.75px !important", width: "17.75px !important" }}
        />
                    </Box>
                  )}
              </Box>
          </Stack>
        </Stack>
      }
    />
  );
}

export default ListCard;
