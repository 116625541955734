export const display={display:"flex",};
export const displayRow={...display,flexDirection:"row"};
export const displayColumn={...display,flexDirection:"column"};
export const justifC={justifyContent:"center"}
export const justifSB={justifyContent:"space-between"}
export const justifEnd={justifyContent:"flex-end"}
export const alignC={alignItems:"center"}
export const rowHorizontalCenter={...displayRow,...justifC};
export const rowHorizontalEnd={...displayRow,...justifEnd};
export const rowVerticalCenter={...displayRow,...alignC};
export const rowCenter={...displayRow,...justifC,...alignC};

export const rowSB={...displayRow,...justifSB,...alignC};
export const columnHorizontalCenter={...displayColumn,...justifC};
export const columnVerticalCenter={...displayColumn,...alignC};
export const columnCenter={...displayColumn,...justifC,...alignC};
export const columnSB={...displayColumn,...justifSB,...alignC};