

import { ObjectValues } from "../../pages/login";
import  { showSnack } from "../../store/slices/constants";
import {
  routes,
  serverDetails,
  structureProps,
} from "../../store/slices/constants/staticContents";
import { logoutUser } from "../../store/slices/userManagement/thunks/logout";
import { store } from "../../store/store";

const apiRequest = async ({
  payload,
  api,
  method,
  extraFunction,
  form
}: requestObject) => {
  try {
    const token = localStorage.getItem("npc-admin-token");
    const reqObj: any = {
      method: method ?? "POST",
      headers: token
        ?form?{
          Authorization: `Bearer ${token.replace(/"/g, "")}`,
        }: {
            Authorization: `Bearer ${token.replace(/"/g, "")}`,
            "Content-Type": "application/json",
            // "application/json",
          }
        : {
            "Content-Type": "application/json",
          },
    };
    if (payload && Object.keys(payload).length > 0) {
      const formData = new FormData();
for (const key in payload) {
  if (payload.hasOwnProperty(key)) {
    formData.append(key, payload[key]);
  }
}
      reqObj.body =form? formData: JSON.stringify(payload);
      //  convertPayloadToFormData(payload)
    }
    const response: any = await fetch(`${serverDetails}/${api}`, reqObj);

    const responseBody: any = await response.json();
    if (!response.ok) {
      const getText: any = (text: { [x: string]: any }) => {
      
        const errorFields = Object.keys(text).filter((key) => {
          return (
            (Array.isArray(text[key]) && text[key].length > 0) || text[key]
          );
        });

        if (errorFields.length > 0) {
          // Extract details for each error field
          const errorDetails = errorFields.map((field) => {
           
            return `${field} : ${
              Array.isArray(text[field])
                ? typeof text[field][0] === "object"
                  ? getText(text[field][0])
                  : text[field].join(". ")
                : text[field]
            }`;
          });

          // Combine all error details into a single string
          return errorDetails.join(" and ");
        } else {
          // Default case, return a generic message
          return "An error occurred";
        }
      };
      const errorText = getText(responseBody);
      
      store.dispatch(
        showSnack({
          message: errorText,
          severity: "error",
          open: true,
        })
      );
      if (responseBody.code === "token_not_valid") {
        store.dispatch(logoutUser());
        
        window.location.replace(routes.initial);
        //  navigate(routes.initial);
      } 
      return {error:true,msg:errorText}
    }  else {
      const responseData: any = responseBody.data ?? responseBody;
      if (extraFunction) {
        extraFunction(responseData);
      }
      return responseData;
    }

    // if (!responseBody.count&&!responseBody.data)
    // throw new Error(
    //   SOMETHING_WENT_WRONG_URL +
    //     "@getting response with body where there is no data."
    // );
  } catch (error: any) {
    store.dispatch(
      showSnack({ message: "Some error!", severity: "error", open: true })
    );

    // if (error.message.startsWith("/") === false)
    //   throw new Error(SOMETHING_WENT_WRONG_URL + "@" + error.message);
    // throw new Error(error.message);
  }
};

export default apiRequest;

export interface requestObject {
  token?: string;
  form?:true;
  api: string;
  method?: "POST" | "GET" | "PATCH"|"DELETE"|"PUT";
  payload?: ObjectValues;
  cacheData?: boolean | string;
  oldData?: any;
  extraFunction?(data: any): void;
  extra?:structureProps;
  navFunc?:any
}
export interface ResponseBody {
  ok: any;
  status: "error" | "message";
  msg: string;
  data: any;
  count?: number;
  results?: ObjectValues[];
}
export interface idRes {
  id: number;
}
