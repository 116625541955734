import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allApis } from "../../../request/config";
import apiRequest from "../../../request/apiRequest/apiRequest";
import { ObjectValues } from "../../../pages/login";

// Define the initial state
interface ContentFeedbackState {
  reportedQuestions: any[];
  staffList: any[];
  reportData: any[];
  questionEditData: any;
  questionEditLoading: boolean;
  loading: boolean;
  reportLoading: boolean;
  error: string | null;
  assignStaffLoading: boolean;
  assignStaffError: string | null;
  assignStaffSuccess: boolean;
}

const initialState: ContentFeedbackState = {
  reportedQuestions: [],
  staffList: [],
  reportData: [],
  questionEditData: {},
  loading: false,
  reportLoading: false,
  questionEditLoading: false,
  error: null,
  assignStaffLoading: false,
  assignStaffError: null,
  assignStaffSuccess: false,
};

// Async thunk to fetch the reported questions based on review_status
export const fetchReportedQuestions = createAsyncThunk(
  "contentFeedback/fetchReportedQuestions",
  async (reviewStatus: string) => {
    try {
      const response: any = await apiRequest({api:allApis.getReportedQuestion(reviewStatus),method:"GET"});
      return response ?? [] // Assuming the API returns the data directly
    } catch (error: any) {
      return null;
    }
  }
);

// create get staff list slice
export const fetchStaffList = createAsyncThunk(
  "contentFeedback/fetchStaffList",
  async () => {
    try {
      const response: any = await
      apiRequest({api:allApis.getStaffList(),method:"GET"});
      return response ?? []
    } catch (error: any) {
      return null;
    }
  }
);

// Async thunk to fetch report details
export const fetchReportData = createAsyncThunk(
    'report/fetchReportData',
    async (reportId: string) => {
      try {
        const response: any = await apiRequest({ api: allApis.getReportDetails(reportId), method: 'GET' });
        return response ?? [];
      } catch (error: any) {
        throw new Error(error.message);
      }
    }
  );

  export const fetchSinlgQustion = createAsyncThunk(
    "contentFeedback/fetchSinlgQustion",
    async (props: any) => {
      try {
        const response: any = await apiRequest(props);
        console.log("API Response:", response); // Add this line to see the API response
        return response ?? null;
      } catch (error: any) {
        console.error("API Error:", error); // Add this line to catch and log any errors
        return null;
      }
    }
  );

  // Async thunk for patch API to assign staff
export const assignStaff = createAsyncThunk(
    "contentFeedback/assignStaff",
    async (row: any) => {
      try {
        const response: any = await apiRequest({
          api: allApis.assignStaff(row?.id),
          method: "PATCH",
          payload: {reviewed_by: row?.selectedStaffId, review_status: "under_review"}, // Add data payload
        });
        return response ?? null;
      } catch (error: any) {
        throw new Error(error.message);
      }
    }
  );

    // Async thunk for patch API to mark as no change
export const markAsNoChange = createAsyncThunk(
    "contentFeedback/markAsNoChange",
    async (row: any) => {
      try {
        const response: any = await apiRequest({
          api: allApis.markAsNoChange(row?.id, row?.notify_users),
          method: "PATCH",
          payload: {validator_remark: row?.validator_remark, review_status: "reviewed", action_taken: "no_change"}, // Add data payload
        });
        return response ?? null;
      } catch (error: any) {
        throw new Error(error.message);
      }
    }
  );

  // Async thunk for patch API to mark as corrected
  export const markAsCorrected = createAsyncThunk(
    "contentFeedback/markAsCorrected",
    async (row: any) => {
      try {
        const response: any = await apiRequest({
          api: allApis.markAsCorrected(row?.id, row?.notify_users),
          method: "PATCH",
          payload: {validator_remark: row?.validator_remark, review_status: "reviewed", action_taken: "corrected"}, // Add data payload
        });
        return response ?? null;
      } catch (error: any) {
        throw new Error(error.message);
      }
    }
  );

const contentFeedbackSlice = createSlice({
  name: "contentFeedback",
  initialState,
  reducers: {clearReportData(state) {
    state.reportData = []; // Reset reportData to an empty array
  },
  clearListData(state) {
    state.reportedQuestions = []; // Reset reportData to an empty array
  },
},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportedQuestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReportedQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.reportedQuestions = action.payload;
      })
      .addCase(fetchReportedQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Handle fetchStaffList
      .addCase(fetchStaffList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStaffList.fulfilled, (state, action) => {
        state.loading = false;
        state.staffList = action.payload;
      })
      .addCase(fetchStaffList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch staff list";
      })
      // Handle fetchReportData
      .addCase(fetchReportData.pending, (state) => {
        state.reportLoading = true;
        state.error = null;
      })
      .addCase(fetchReportData.fulfilled, (state, action) => {
        state.reportLoading = false;
        state.reportData = action.payload;
      })
      .addCase(fetchReportData.rejected, (state, action) => {
        state.reportLoading = false;
        state.error = action.error.message ?? 'Failed to fetch report data';
      })
      // Handle fetchSinlgQustion
      .addCase(fetchSinlgQustion.pending, (state) => {
        state.questionEditLoading = true;
        state.error = null;
      })
      .addCase(fetchSinlgQustion.fulfilled, (state, action) => {
        state.questionEditLoading = false;
        state.questionEditData = action.payload;
      })
      .addCase(fetchSinlgQustion.rejected, (state, action) => {
        state.questionEditLoading = false;
        state.error = action.error.message ?? 'Failed to edit data';
      })
// Handle assignStaff
.addCase(assignStaff.pending, (state) => {
    state.assignStaffLoading = true;
    state.assignStaffError = null;
    state.assignStaffSuccess = false;
  })
  .addCase(assignStaff.fulfilled, (state, action) => {
    state.assignStaffLoading = false;
    state.assignStaffSuccess = true;
    // Optionally update your state with the response if needed
  })
  .addCase(assignStaff.rejected, (state, action) => {
    state.assignStaffLoading = false;
    state.assignStaffError = action.error.message || "Failed to assign staff";
    state.assignStaffSuccess = false;
  });
  },
});

export const { clearReportData ,clearListData} = contentFeedbackSlice.actions;
export default contentFeedbackSlice.reducer;
