import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, {  requestObject } from "../../../../../request/apiRequest/apiRequest";
export interface userResponse{
}
export const fetchListData = createAsyncThunk(
  "exams/fetchListData",
  async (props: requestObject) => {
    try {
      const newPayload=props
      // if(newPayload.extra){
      //  delete newPayload.extra
      // }
      const response: userResponse = await apiRequest(newPayload ?? {});
      return {list:response??null,structure:props.extra};
    } catch (error: any) {
      return null
    }
  }
);


export const fetchMockListData = createAsyncThunk(
  "exams/fetchMockListData",
  async (props: requestObject) => {
    try {
      const newPayload=props
      // if(newPayload.extra){
      //  delete newPayload.extra
      // }
      const response: any = await apiRequest(newPayload ?? {});
      return {list: newPayload?.token == "mockquestion" ? response?.results : response??null,structure:props.extra};
    } catch (error: any) {
      return null
    }
  }
);

