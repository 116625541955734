export const edit=
<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_263_3119)">
<path d="M13.355 0.749093C13.06 0.454078 12.59 0.454078 12.295 0.749093L6.59996 6.44407C6.50997 6.52909 6.44997 6.63908 6.40996 6.75907L5.47997 9.86408C5.39995 10.1291 5.47496 10.4141 5.66997 10.6091C5.80995 10.7541 6.00496 10.8291 6.19997 10.8291C6.26994 10.8291 6.34496 10.8191 6.41496 10.7991L9.51994 9.86908C9.63997 9.82907 9.74995 9.76908 9.83494 9.67908L15.53 3.98408C15.67 3.84409 15.75 3.65409 15.75 3.45408C15.75 3.2541 15.67 3.0641 15.53 2.92408L13.355 0.749093Z" fill="currentColor"/>
<path d="M15 7.52905C14.5857 7.52905 14.25 7.86499 14.25 8.27905V13.2791C14.25 13.9685 13.6892 14.5291 13 14.5291H3C2.31079 14.5291 1.75 13.9685 1.75 13.2791V3.27905C1.75 2.5896 2.31079 2.02905 3 2.02905H8C8.4143 2.02905 8.75 1.69311 8.75 1.27905C8.75 0.864993 8.4143 0.529053 8 0.529053H3C1.48364 0.529053 0.25 1.76294 0.25 3.27905V13.2791C0.25 14.7952 1.48364 16.0291 3 16.0291H13C14.5164 16.0291 15.75 14.7952 15.75 13.2791V8.27905C15.75 7.86499 15.4143 7.52905 15 7.52905Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_263_3119">
<rect width="16" height="16" fill="white" transform="translate(0 0.279053)"/>
</clipPath>
</defs>
</svg>
