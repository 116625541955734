import { Stack, Typography } from "@mui/material";
import React from "react";
import globalColors from "../../globalColors";
import { useNavigate } from "react-router";

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack
      height={"100vh"}
      width={"calc(100% - 30px)"}
      justifyContent={"center"}
      m="0 15px"
    >
      <Stack alignItems={"center"}>
      </Stack>
      <Stack spacing={"8px"} px={"18px"}>
        <Typography
          variant="Heading3"
          textAlign={"center"}
          color={globalColors.primary.black}
        >
          Page not Found
        </Typography>
        <Typography
          variant="body1"
          textAlign={"center"}
          color={globalColors.primary.medGrey}
        >
          Error 404. The page you are looking for does not exist or expired. Go
          back and try again later.
        </Typography>
      </Stack>

      <Stack direction={"row"} mt={"134px"}>
        <Stack
          direction={"row"}
          py={"12px"}
          px={"24px"}
          component={"button"}
          border={"none"}
          bgcolor={"#0F022A"}
          spacing={"8px"}
          borderRadius={"10px"}
          flex={1}
          justifyContent={"center"}
          onClick={() => navigate(-1)}
        >
          <Typography
            color={globalColors.primary.pureWhite}
            variant="buttonText1"
          >
            Go Back
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PageNotFound;
