
export const moreRound=
<svg width="35" height="100%"  viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white"/>
<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white"/>
<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#121212"/>
<g clipPath="url(#clip0_323_2098)">
<path d="M14.6665 9.33333C14.6665 10.0697 15.2634 10.6667 15.9998 10.6667C16.7362 10.6667 17.3331 10.0697 17.3331 9.33333C17.3331 8.59695 16.7362 8 15.9998 8C15.2634 8 14.6665 8.59695 14.6665 9.33333Z" fill="#121212"/>
<path d="M14.6665 16C14.6665 16.7364 15.2634 17.3333 15.9998 17.3333C16.7362 17.3333 17.3331 16.7364 17.3331 16C17.3331 15.2636 16.7362 14.6667 15.9998 14.6667C15.2634 14.6667 14.6665 15.2636 14.6665 16Z" fill="#121212"/>
<path d="M14.6665 22.6667C14.6665 23.403 15.2634 24 15.9998 24C16.7362 24 17.3331 23.403 17.3331 22.6667C17.3331 21.9303 16.7362 21.3333 15.9998 21.3333C15.2634 21.3333 14.6665 21.9303 14.6665 22.6667Z" fill="#121212"/>
</g>
<defs>
<clipPath id="clip0_323_2098">
<rect width="16" height="16" fill="white" transform="translate(24 8) rotate(90)"/>
</clipPath>
</defs>
</svg>
