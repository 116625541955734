
import { Stack } from "@mui/material";
import { Internet } from "../../assets/svgs";
import EmptyScreen from "../EmptyScreen/EmptyScreen";
import { rowVerticalCenter } from "../../GlobalStyles";

function OfflineScreen() {
  return (
    <Stack height="100%"width="100%" sx={rowVerticalCenter} spacing="10px" direction={"column"}>
      <EmptyScreen
        headingText={`No Internet connection`}
        icon={Internet}
        buttonProps={{
          loading: false,
          content: `Try connecting again`,
         otherProps:{
            variant:"outlined",
            onClick: () => {
              window.location.reload();
            },
         }
        }}
        message={"Please check the network cables, modem and router or try Reconnecting to Wi-Fi."}
      />
    </Stack>
  );
}

export default OfflineScreen;
