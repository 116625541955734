import React, { useEffect } from 'react';
import {
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    Box
} from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { inputProp } from '../../pages/login';

const useStyles = makeStyles(() => ({
    select: {
        borderRadius: '10px',
        fontSize: 'small',
        height: '40px',
        '& .MuiInputBase-root': {
            padding: 0,
        },
        '& .MuiInputBase-input': {
            color: '#000', // Replace with globalColors.primary.black
            borderRadius: '10px',
            background: 'transparent',
            padding: '10px 12px',
            '&:focus': {
                background: 'transparent',
            },
            '&:-webkit-autofill': {
                background: 'transparent',
            },
            '&:-internal-autofill-selected': {
                background: 'transparent',
            },
        },
        '& .MuiInputLabel-root': {
            color: '#000', // Replace with globalColors.primary.black
        },
        '& .MuiFormHelperText-root.Mui-disabled': {
            color: '#fff', // Replace with globalColors.primary.white
            background: 'transparent',
        },
        '& .MuiButtonBase-root': {
            color: '#374957',
        },
        '& .MuiFormHelperText-root': {
            color: '#fff', // Replace with globalColors.primary.white
            background: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ccc', // Replace with globalColors.primary.grey
                borderRadius: '10px',
                borderWidth: '1px',
                background: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: '#ccc', // Replace with globalColors.primary.grey
                borderRadius: '10px',
                borderWidth: '1px',
                background: 'transparent',
            },
            '& .Mui-disabled:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px',
                background: 'transparent',
                borderColor: '#ccc', // Replace with globalColors.primary.grey
            },
            '&:-webkit-autofill': {
                borderWidth: '1px',
                background: 'transparent',
            },
            '&:-internal-autofill-selected': {
                borderWidth: '1px',
                background: 'transparent',
            },
        },
        '& .MuiInputBase-input::placeholder': {
            color: '#ccc', // Replace with globalColors.primary.grey
        },
        '& .MuiPopover-paper': {
            borderRadius: '10px',
        },
    },
    menuItem: {
        fontSize: 'small',
    },
    checkbox: {
        color: '#374957',
    },
}));

interface Option {
    label: string;
    value: string;
}

const CustomMultiSelect: React.FC<inputProp> = ({
    label,
    placeholder,
    value,
    error,
    helperText,
    onChange,
    endAdornment,
    listLabel,
    type,
    list,
    disabled,
}) => {
    const classes = useStyles();
    const [selectedOptions, setSelectedOptions] = React.useState<string[]>(value || []);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value;
        setSelectedOptions(
            typeof value === 'string' ? value.split(',') : value
        );
        onChange && onChange(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <FormControl fullWidth variant="outlined" className={classes.select}>
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedOptions}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => (selected as string[]).join(', ')}
                MenuProps={{
                    PaperProps: {
                        style: {
                            margin: "1px 0 0 2px",
                            borderRadius: "10px", // Customize the border-radius for the dropdown paper
                        },
                    },
                }}
            >
                {list?.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        className={classes.menuItem}
                    >
                        <Checkbox
                            checked={selectedOptions.indexOf(option.value) > -1}
                            size="small"
                            className={classes.checkbox}
                        />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CustomMultiSelect;
