import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { textfieldsx } from "../CustomTextfield";
import { inputProp } from "../../pages/login";
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
// import Calander from "../../assets/svgs/Calander";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export default function CustomDateTimePicker({value,onChange,disabled}:inputProp) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
      disabled={disabled??false}
      minDate={dayjs(new Date())}
      //  defaultValue={dayjs('2022-04-17')}
       value={value?dayjs(value):null}
       onChange={(newValue:any) => 
        {
          if(onChange)onChange(newValue)}}
       slots={{
        openPickerIcon: EditCalendarRoundedIcon,
        // openPickerButton: StyledButton,
        // day: StyledDay,
      }}
    //   value={value??""}
    //   onChange={onChange}
        slotProps={{
          openPickerIcon: { fontSize: "medium" },
          openPickerButton: { color: "secondary" },
          textField: {
            sx: textfieldsx,
          },
        }}
      />
    </LocalizationProvider>
  );
}
