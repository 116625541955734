import React from "react";
import { ObjectValues } from "../../pages/login";
import CustomTextField from "../CustomTextfield";
import { search } from "../../assets/svgs";
interface CustomSearchProps {
  readonly placeholder?: string;
  submit?(data: string | undefined): void;
  readonly disabled?: boolean;
  readonly focusSearch?: boolean;
  readonly onlyNum?: boolean;
  readonly padding?: string;
  readonly copyData?: ObjectValues[];
  readonly fieldName?: string[];
  readonly value?: string;
  onClear?(): void;
  filteredData?(array: ObjectValues[]): void;
  onChange?(data: string | number): void;
  panelChange?(): void;
}
const fuzzySearch = (query: string, text: string): boolean => {
 
  query = query?.toLowerCase()??"";
  text = text?.toString().toLowerCase()??"";
  let i = 0;
  for (let j = 0; j < text.length; j++) {
    if (query[i] === text[j]) {
      i++;
    }
    if (i === query.length) {
      return true;
    }
  }
  return false;
};

function CustomSearch(props: CustomSearchProps) {
  const { disabled, copyData, fieldName, onClear, filteredData } = props;

  const [searchBarValue, setSearchBarValue] = React.useState<
    string | undefined
  >("");
  return (
    <CustomTextField
      {...{
        variant: "outlined",
        autoFocus: false,
        type: "text",
        onKeyPress: (ev: any) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
          }
        },
        placeholder: "Search",
        InputProps: {
          startAdornment: search,
        },
        value: searchBarValue ? searchBarValue : "",
        disabled: disabled ?? copyData?.length === 0 ? true : false,
        sx: {
          minHeight: "0",
          width: "100%",
          maxWidth: "100%",
          "& .MuiInputBase-root": { padding: "7px !important" },
          "& .MuiInputBase-input": { padding: "0 0 0 5px !important" },
        },
        onChange: (e) => {
          setSearchBarValue(e.target.value);
          if (e.target.value) {
            const filterData: ObjectValues[] = [];
            if (copyData && fieldName) {
              copyData.map((data) => {
                return fieldName.map((singleField) => {
                  if (
                    (data[singleField] &&
                      data[singleField]
                        .toString()
                        .toLowerCase()
                        .includes(e.target.value.toString().toLowerCase())) ||
                    (fuzzySearch &&
                      fuzzySearch(e.target.value, data[singleField]))
                  ) {
                    if (!filterData.includes(data)) {
                      return filterData.push(data);
                    } else {
                      return null;
                    }
                  } else {
                    return null;
                  }
                });
              });
              if (filteredData) {
                filteredData(filterData);
              }
            }
          } else {
            if (onClear) {
              onClear();
            }
          }
        },
      }}
    />
  );
}

export default CustomSearch;
